import { useAtom } from 'jotai'

import { AppModeAtom, AppScope, Modes } from 'contexts'

/**
 * Hook returns the current products based on the application mode.
 * It allows an optional overwrite of the mode to get products for a different mode.
 *
 * @param {string} [overwriteMode] - Optional parameter to specify a mode which will override the current application mode.
 * @returns {Array} An array of product references for the current (or overwritten) mode, excluding any fallback products.
 */
export const useCurrentProducts = (overwriteMode) => {
  const [appMode] = useAtom(AppModeAtom, AppScope)

  const { JacketsAtom, ShirtsAtom, TrousersAtom, ShoesAtom, CoatsAtom } =
    Modes[overwriteMode || appMode]

  const [jacketRef] = useAtom(JacketsAtom)
  const [shirtRef] = useAtom(ShirtsAtom)
  const [trousersRef] = useAtom(TrousersAtom)
  const [shoesRef] = useAtom(ShoesAtom)
  const [coatsRef] = useAtom(CoatsAtom)

  const outfit = [jacketRef, shirtRef, trousersRef, shoesRef, coatsRef].filter(
    (e) => !e.isFallback
  )

  return outfit
}
