export const ENDPOINT = `https://${process.env.LOOKBUILDER_URL}/`
export const MANAGEMENT_API = `https://${process.env.MANAGEMENT_URL}/api`

export const STORE_PRODUCTS_URL = (locale) =>
  `${ENDPOINT}api/groupStoreProducts?locale=${locale}`

export const MAIN_GROUP_URL = (locale, countryCode, siteID, currencyCode) =>
  `${ENDPOINT}api/groupProducts/?locale=${locale}-${countryCode}&siteID=${siteID}&currencyCode=${currencyCode}`

export const MATRIX_URL = `${ENDPOINT}api/getProductMatrix`

export const DEFAULT_PRODUCTS_URL = (
  initialProducts,
  locale,
  countryCode,
  siteID
) =>
  `${ENDPOINT}api/initialProducts/?initialProducts=${initialProducts}&locale=${locale}-${countryCode}&siteID=${siteID}`

export const CDN_URL =
  'https://cdn.suitsupply.com/image/upload/dpr_1,f_auto,q_auto,g_center,fl_progressive'

export const TEST_CDN_URL =
  'https://res.cloudinary.com/testsusu/image/upload/c_lpad,dpr_1,f_auto,q_auto,g_center,fl_progressive'

export const MANAGEMENT_API_SUBMIT = `${MANAGEMENT_API}/looks`
