import { useState, useEffect } from 'preact/hooks'
import { breakpoints } from 'styles/mediaQueries'

const useMatchMedia = (mediaQueryString) => {
  const [matches, setMatches] = useState()

  useEffect(() => {
    const onResize = () => {
      const isTouchDevice =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0

      const isTablet = window.innerWidth < breakpoints.m && isTouchDevice

      const { matches } = window.matchMedia(mediaQueryString)
      setMatches(isTablet || matches)
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [mediaQueryString])

  return matches
}

export default useMatchMedia
