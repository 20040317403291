import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useEffect, useRef } from 'preact/hooks'

import { CDN_URL } from 'utils'

import { IMAGE_SIZES, mediaQueries, globalRadius } from 'styles'

import { SKIN_COLORS, LS_MODEL_SELECTION } from 'data'

const Inner = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  gap: 1px;

  border-radius: ${globalRadius};
  overflow: hidden;

  ${mediaQueries.m} {
    grid-template-columns: repeat(2, calc(50%));
    grid-template-rows: repeat(2, calc(50%));
    gap: 1.7px;
  }
`

const createModelSrcUrl = (size, id, media) =>
  `${CDN_URL},c_lfill,g_north,w_${size}/products/ctc_models/${id}.jpg${
    media ? ` ${media}w,` : ''
  }`

const lastImage = IMAGE_SIZES[IMAGE_SIZES.length - 1]

const Image = styled.img`
  pointer-events: none;
  width: 100%;
  height: 100%;

  object-fit: contain;
`

const ModelSelection = ({ controls }) => {
  const currentModel = useRef()

  useEffect(() => {
    currentModel.current = window.localStorage
      .getItem(LS_MODEL_SELECTION)
      ?.split('_')
      .pop()
  }, [])

  const modelSourceSets = SKIN_COLORS.map(({ tone }) =>
    createModelSrcUrl(lastImage, `model_${tone}_thumb`)
  )

  return (
    <Inner animate={controls}>
      {modelSourceSets.map((src, i) => {
        return (
          <Image
            src={src}
            key={i}
            alt={`Model ${i + 1}`}
          />
        )
      })}
    </Inner>
  )
}

export default ModelSelection
