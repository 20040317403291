import { useAtom } from 'jotai'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { QueryParams, useQueryParam } from 'use-query-params'

import {
  useLayoutEffect,
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'preact/hooks'

import Scroll from 'components/Scroll'
import Thumbnail from 'components/Thumbnail'
import ResetButton from 'components/ResetButton/ResetButton'
import ProductDescription from 'components/ProductDescription'

import {
  AppModeAtom,
  PreviousViewAtom,
  DataAtom,
  HorizontalScrollContext,
  GlobalProductsContext,
  ProductMatrixContext,
  AppScope,
  Modes,
  InterfaceViewAtom,
} from 'contexts'

import {
  CDN_URL,
  translateStringKey,
  getCategoryContext,
  formatState,
  getProductDescription,
  extractLocaleString,
  gtmPush,
  analytics_productChange,
  getAvoUrl,
} from 'utils'

import {
  mediaQueries,
  IMAGE_SIZES,
  DEVICE_SIZES,
  colors,
  containerAnimation,
  translationAnimation,
  globalRadius,
  elementFocus,
  interfaceSize,
  thumbSize,
  modelThumbSize,
} from 'styles'

import {
  MID_LAYER,
  MODEL_LAYER,
  OPTIONAL_LAYERS,
  SUITS_MODE,
  SEPERATES_MODE,
  TOP_LAYER,
  BOTTOM_LAYER,
  MODEL_SKIN,
  OUTOFSTOCK_STRING,
  MODEL_NAMES,
  SUIT_LAYER,
  LS_MODEL_SELECTION,
  MODEL_SET,
} from 'data'

const ProductsWrapper = styled(motion.div)`
  position: relative;

  display: flex;
  flex-direction: ${({ $isModelLayer }) => ($isModelLayer ? 'column' : 'row')};

  gap: unset;

  width: 100%;
  height: max-content;
  padding: 0 0 0 ${({ $canReset }) => ($canReset ? '10px' : '0')};

  ${mediaQueries.s} {
    height: ${interfaceSize.tablet}px;
  }

  ${mediaQueries.m} {
    flex-direction: column;
    align-items: center;

    gap: 5px;
    top: ${({ $canReset }) => ($canReset ? '10px' : 0)};

    left: 50%;
    transform: translateX(-50%);

    min-height: 100%;
    height: 100%;
    padding: 0;
  }
`

const TranslateContainer = styled(motion.div)`
  display: flex;
  align-self: center;
  gap: 8px;

  padding: ${({ $isModelLayer, $canReset }) => {
    if ($isModelLayer) return '15px 15px'
    if ($canReset) return '10px 10px 10px 0px'
    return '10px 10px'
  }};

  ${mediaQueries.m} {
    padding: ${({ $isModelLayer }) => {
      if ($isModelLayer) return '20px 20px'
      return '10px 10px'
    }};
  }

  min-width: 100%;

  ${mediaQueries.s} {
    min-height: 100%;
  }

  ${mediaQueries.m} {
    /* min-width: unset; */
    min-height: 100%;

    flex-direction: column;
    justify-content: unset;
    align-items: center;
    gap: 10px;

    ${({ $isModelLayer }) =>
      $isModelLayer &&
      `
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      `}

    padding-bottom: 62px;
    padding-top: ${({ $canReset, $isModelLayer }) =>
      $canReset || $isModelLayer ? '0' : '10px'};
  }
`

const ThumbnailContainer = styled(motion.button)`
  /* reset default button styling */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 100%;

  position: relative;

  transition: border-color 0.25s ease;
  border-radius: ${globalRadius};

  border: 1px solid white;

  border-color: ${({ $isActive }) => $isActive && colors.secondary};

  &:hover {
    border-color: ${({ $isActive }) => !$isActive && colors.grey[200]};
  }

  ${elementFocus}

  ${mediaQueries.s} {
    height: ${thumbSize.tablet}px;
    height: ${({ $isModelLayer }) => {
      if ($isModelLayer) return `${modelThumbSize.tablet}px`
      return `${thumbSize.tablet}px`
    }};
  }

  ${mediaQueries.m} {
    height: ${({ $isModelLayer }) => {
      if ($isModelLayer) return `${modelThumbSize.desktop}px`
      return `${thumbSize.desktop}px`
    }};

    display: flex;
    align-items: center;

    gap: 20px;
    width: ${({ $isModelLayer }) => ($isModelLayer ? 'unset' : '100%')};
    cursor: pointer;
  }
`

const Header = styled(motion.span)`
  font-size: 14px;

  color: ${colors.text.primary};
  font-weight: 500;
  padding-left: 15px;
  margin-top: 20px;
  padding-top: 2px;
  padding-bottom: 12px;
  width: max-content;

  ${mediaQueries.m} {
    font-size: 18px;
    padding: unset;
    margin-top: 25px;
    margin-bottom: 15px;

    text-align: center;
    max-width: 60%;
  }
`

const createModelSrcUrl = (size, id, media) =>
  `${CDN_URL},c_lfill,g_north,w_${size}/products/ctc_models/${id}.jpg${
    media ? ` ${media}w,` : ''
  }`

const createSrcSet = (id) => {
  let string = ''
  DEVICE_SIZES.forEach((size, i) => {
    string += createModelSrcUrl(IMAGE_SIZES[i], id, size)
  })
  return string
}

const lastImage = IMAGE_SIZES[IMAGE_SIZES.length - 1]

const Products = ({
  layerName,
  categoryIndex,
  currentProduct,
  locales,
  modelSkin,
  setModelSkin,
  initialProducts,
  priceFormat,
  trackingMetadata,
}) => {
  const [appMode] = useAtom(AppModeAtom, AppScope)
  const isSuitsMode = appMode === SUITS_MODE
  const locale = extractLocaleString(locales)
  const [, setPreviousView] = useAtom(PreviousViewAtom)

  const { JacketsAtom, TrousersAtom } = Modes[appMode]
  const categoryContext = useMemo(
    () => getCategoryContext(layerName, appMode),
    [appMode, layerName]
  )
  const isHorizontalScroll = useContext(HorizontalScrollContext)
  const { globalProductSet } = useContext(GlobalProductsContext)

  const [product, setProduct] = useAtom(categoryContext)
  const [jacket, setJacket] = useAtom(JacketsAtom)
  const [, setTrousers] = useAtom(TrousersAtom)
  const [, setCurrentView] = useAtom(InterfaceViewAtom)
  const [data] = useAtom(DataAtom)
  const {
    productMatrix: { LAYER_VERSIONS },
  } = useContext(ProductMatrixContext)

  const productsData = data[categoryIndex]
  const modelSet = MODEL_SET

  const isEven = productsData.length % 2 === 0 ? 80 : 25

  const scrollRef = useRef()
  const timeout = useRef()
  const itemRefs = useRef([])
  const activeProduct = useRef()

  const [layerVersion, setLayerVersion] = useState(jacket.version)
  const [scrollBlock, setScrollBlock] = useState(true)

  const [, setModelQuery] = useQueryParam(MODEL_SKIN, QueryParams)
  const [suitParams, setSuitParams] = useQueryParam(SUITS_MODE, QueryParams)
  const [seperatesParams, setSeperatesParams] = useQueryParam(
    SEPERATES_MODE,
    QueryParams
  )

  const translationString = translateStringKey('skin-selection', locale)

  const canReset =
    OPTIONAL_LAYERS.includes(layerName) &&
    !(isSuitsMode && layerName === TOP_LAYER)
  const isModelLayer = layerName === MODEL_LAYER

  const isOutOfStock =
    Boolean(productsData.find((e) => Boolean(e?.isPlaceholder))) &&
    !isModelLayer

  const suitSet = productsData.filter(
    (product, index, self) =>
      product?.parentId &&
      self.findIndex((p) => p.parentId === product.parentId) === index
  )

  const seperatesSet = productsData
    .filter((e, i) => productsData.findIndex((a) => a.id === e.id) === i)
    .filter((e) => !e?.parentId && e?.layerName !== SUIT_LAYER)

  const renderSet = isModelLayer
    ? modelSet
    : isSuitsMode && layerName === TOP_LAYER
      ? suitSet
      : seperatesSet

  const sortedRenderset = useMemo(
    () =>
      [...renderSet].sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)),
    [renderSet]
  )

  const activeIndex = product?.id
    ? sortedRenderset.findIndex((e) => e?.id === product?.id)
    : sortedRenderset.length / 2 - 1

  const animationRange = useMemo(() => {
    return [activeIndex - 5, activeIndex + 5]
  }, [activeIndex])

  useEffect(() => {
    setPreviousView({ view: layerName, appMode })
  }, [])

  // set a product if the product layer is reset previously
  useEffect(() => {
    if (!currentProduct && !isModelLayer && !isSuitsMode) {
      const revivalProduct = data[categoryIndex].find(
        (e) => e.id === initialProducts[categoryIndex].id || e.isFallback
      )
      setProduct(revivalProduct)
    }

    if (currentProduct?.isFallback) {
      setProduct({})
    }
  }, [])

  useEffect(() => {
    if (isOutOfStock) return setCurrentView(OUTOFSTOCK_STRING)
    if (isModelLayer) {
      setProduct(productsData[modelSkin - 1])
    }
  }, [])

  useEffect(() => {
    timeout.current = setTimeout(() => setScrollBlock(false), 500)
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  useLayoutEffect(() => {
    const activeEntry = scrollRef.current.children[0].children[activeIndex - 1]
    const isSuitProduct = currentProduct?.parentId

    if (window.innerWidth < 1024) {
      // active element falls in the last range of the available window width
      const isAgainstBoundries = activeIndex >= sortedRenderset.length - 4

      // in the case of not enough products are available in the scroller, aim for window.innerWidth / thumbnailSize * thumbnailAmount + paddings
      if (isAgainstBoundries) {
        // set the scrollLeft to the end of the slider if the thumbnail would fall between the last and 4th last.
        scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        return
      }

      const scrollSize =
        activeEntry?.offsetLeft -
        window.innerWidth / 2 +
        (canReset ? 180 : isSuitProduct ? 50 : 110)

      scrollRef.current.scrollLeft = scrollSize
    } else {
      const scrollSize =
        activeEntry?.offsetTop - window.innerHeight / 2 + (canReset ? 325 : 300)

      scrollRef.current.scrollTop = scrollSize
    }
  }, [activeProduct])

  const setItemRefs = useCallback((node, index) => {
    itemRefs.current[index] = node
  }, [])

  const handleOnClick = useCallback(
    ({ event, el, isLast }) => {
      setProduct({
        ...el,
        category: el.subCategory ? el.subCategory : el.category,
        alt: el.image?.alt,
        subCategory: el.subCategory,
        layerName,
        version: layerVersion,
        isFallback: false,
      })

      if (layerName === MID_LAYER) {
        setLayerVersion(LAYER_VERSIONS[el.onlineProductType])
        setJacket({ ...jacket, version: LAYER_VERSIONS[el.onlineProductType] })
      }

      // set trousers if suits mode is active
      if (layerName === TOP_LAYER && isSuitsMode) {
        const parent = globalProductSet.find((e) => el.parentId === e.id)
        const counterpart = parent?.setProducts.find(
          (e) => e.layerName === BOTTOM_LAYER
        )
        setTrousers(counterpart)
      }

      if (isLast) {
        const lastElement = itemRefs.current[itemRefs.current.length - 1]
        const scrollTimeout = 300

        const optionalScroll = () =>
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight + 150,
            behavior: 'smooth',
          })

        const regularScroll = () =>
          lastElement.scrollIntoView({
            behavior: 'smooth',
          })

        timeout.current = setTimeout(() => {
          canReset ? optionalScroll() : regularScroll()
          clearTimeout(timeout.current)
        }, scrollTimeout)
      }
    },
    [layerName, scrollRef, itemRefs]
  )

  const handleModelClick = (id) => {
    //    * it is not possible to fire another onload event by changing the src
    //    * so we need to reset the image load state
    //    */
    if (id !== parseInt(modelSkin)) {
      localStorage.setItem(LS_MODEL_SELECTION, `model_${id}`)
      setModelSkin(`model_${id}`)
      setModelQuery(`model_${id}`)
      setProduct(productsData[id - 1])

      const modelName = MODEL_NAMES[id - 1]

      gtmPush({
        event: 'GAevent',
        eventCategory: 'Crackthecode_Interactions',
        eventAction: 'Model_Choice',
        eventLabel: `${modelName}`,
      })
    }
  }

  const handleResetClick = () => {
    if (canReset) {
      if (appMode === SUITS_MODE) {
        setSuitParams((pre) => {
          return formatState(pre, suitParams, currentProduct, data, true)
        }, 'push')
      } else {
        setSeperatesParams((pre) => {
          return formatState(pre, seperatesParams, currentProduct, data, true)
        })
      }

      gtmPush({
        event: 'GAevent',
        eventCategory: 'Crackthecode_Interactions',
        eventAction: 'Category_Removed',
        eventLabel: `${currentProduct.category}`,
      })
    }

    const { url, locationId } = getAvoUrl(window.location.href)

    analytics_productChange(
      {
        ...trackingMetadata,
        lookbuilderOption: currentProduct?.id,
        url,
        locationId,
      },
      true
    )
    setProduct({ ...currentProduct, isFallback: true })
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <ProductsWrapper
        $isModelLayer={isModelLayer}
        variants={containerAnimation}
        $canReset={canReset}
        initial="hidden"
        animate="show"
        exit="leave"
      >
        <AnimatePresence>
          {isModelLayer && (
            <Header
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              {translationString}
            </Header>
          )}
        </AnimatePresence>
        {canReset && (
          <ResetButton
            onClick={handleResetClick}
            isActive={!currentProduct}
            layerName={layerName}
            locales={locales}
          />
        )}
        <Scroll
          ref={scrollRef}
          canReset={canReset}
        >
          <TranslateContainer
            variants={translationAnimation}
            custom={isHorizontalScroll}
            initial="hidden"
            animate="show"
            exit="leave"
            $canReset={canReset}
            $isModelLayer={isModelLayer}
          >
            {sortedRenderset.map((el, index) => {
              const isSuitProduct = el?.parentId
              const productDescription = getProductDescription(
                el,
                appMode,
                globalProductSet
              )

              // filter out non-suit products in suits mode
              if (isSuitsMode && layerName === TOP_LAYER && !isSuitProduct)
                return null

              const activeCondition =
                isSuitsMode && layerName === TOP_LAYER
                  ? currentProduct?.parentId === el?.parentId
                  : currentProduct?.id === el?.id

              const isActive = isModelLayer
                ? parseInt(modelSkin.split('_')[1], 10) - 1 === index
                : !scrollBlock && activeCondition

              return (
                <ThumbnailContainer
                  key={index}
                  id={el?.setReference || el?.parentId || el.id}
                  $isModelLayer={isModelLayer}
                  $isActive={isActive}
                  onMouseDown={(e) => e.preventDefault()}
                  onMouseUp={(e) => e.preventDefault()}
                  onKeyPress={(e) => {
                    if ([' ', 'Enter'].includes(e.key)) {
                      handleOnClick({ el })
                    }
                  }}
                  aria-pressed={currentProduct?.id === el?.id}
                  {...{
                    onClick: (event) =>
                      isModelLayer
                        ? handleModelClick(el.id)
                        : handleOnClick({
                            event,
                            el,
                            isLast: index === productsData.length - 1,
                          }),
                  }}
                >
                  <Thumbnail
                    isActive={isActive}
                    parent={el?.parent}
                    preventAnimation={
                      index < animationRange[0] || index > animationRange[1]
                    }
                    id={el.id}
                    parentId={el.parentId}
                    onlineProductType={el.onlineProductType}
                    category={el.subCategory ? el.subCategory : el.category}
                    scrollRef={scrollRef}
                    alt={el.alt}
                    index={index}
                    dataLength={productsData.length}
                    imageAlt={el.alt}
                    layerName={layerName}
                    subCategory={el.subCategory}
                    scrollBlock={scrollBlock}
                    onClick={() => handleOnClick({ el })}
                    isModelLayer={isModelLayer}
                    {...(isModelLayer && {
                      customSrc: createSrcSet(`model_${el.id}_thumb`),
                      customSet: createModelSrcUrl(
                        lastImage,
                        `model_${el.id}_thumb`
                      ),
                      objectFit: 'cover',
                    })}
                  />
                  {!isHorizontalScroll && !isModelLayer && (
                    <ProductDescription
                      index={index}
                      animationTrigger
                      setItemRefs={setItemRefs}
                      productData={productDescription}
                      dataLength={productsData.length}
                      priceFormat={priceFormat}
                      currencySign={el?.currencySign}
                    />
                  )}
                </ThumbnailContainer>
              )
            })}
          </TranslateContainer>
        </Scroll>
      </ProductsWrapper>
    </AnimatePresence>
  )
}

export default Products
