import { useEffect, useRef } from 'preact/hooks'
import { LS_LOCALE_KEY } from 'data'

const useLocaleChange = ({ locales }) => {
  // store the initial locale in a ref for lifecycle safeguarding
  const shouldRevertToDefaults = useRef(false)

  useEffect(() => {
    const hasEntry = window.localStorage.getItem(LS_LOCALE_KEY)

    // Check if the locale has changed and if so, revert to default values
    if (hasEntry !== locales?.locale) {
      shouldRevertToDefaults.current = true
      window.history.replaceState({}, '', window.location.pathname)
      window.localStorage.setItem(LS_LOCALE_KEY, locales.locale)
    }
  }, [])

  return { shouldRevertToDefaults: shouldRevertToDefaults.current }
}

export default useLocaleChange
