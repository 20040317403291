// Generated by Avo VERSION 153.26.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
const avoInspectorApiKey = "alCiaXxYOj2bhtP8gN01";
var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

    const WebDebuggerAssertions = {
      hasKey(obj, key) {
        return obj && obj[key] != null;
      },
      assertKey(obj, key, assert) {
        if (this.hasKey(obj, key) && assert(obj[key])) {
          return true;
        } else {
          throw new Error('WebDebugger: Object key: "' + key + '" failed assertion.')
        }
      },
      assertKeys(obj, arrayOfAssertions) {
        return arrayOfAssertions.every(assertionForKey => {
          if (this.hasKey(assertionForKey, "key") && this.hasKey(assertionForKey, "assert")) {
            return this.assertKey(obj, assertionForKey.key, assertionForKey.assert);
          } else {
            throw new Error('WebDebugger: Object form for assertion invalid.');
          }
        });
      },
      assertInt(int) {
        return Number.isInteger(int);
      },
    };

    var WebDebuggerPosition = {
      position: {
        BOTTOM_RIGHT: 'BottomRight',
        BOTTOM_LEFT: 'BottomLeft',
        TOP_RIGHT: 'TopRight',
        TOP_LEFT: 'TopLeft',
      },
      BottomRight(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "bottom", assert: WebDebuggerAssertions.assertInt },
            { key: "right", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.BOTTOM_RIGHT,
          bottom: parameters.bottom,
          right: parameters.right,
        };
      },
      BottomLeft(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "bottom", assert: WebDebuggerAssertions.assertInt },
            { key: "left", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.BOTTOM_LEFT,
          bottom: parameters.bottom,
          left: parameters.left,
        };
      },
      TopRight(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "top", assert: WebDebuggerAssertions.assertInt },
            { key: "right", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.TOP_RIGHT,
          top: parameters.top,
          right: parameters.right,
        };
      },
      TopLeft(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "top", assert: WebDebuggerAssertions.assertInt },
            { key: "left", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.TOP_LEFT,
          top: parameters.top,
          left: parameters.left,
        };
      },
    }


  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties, groupProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties) + "Group Props:" + JSON.stringify(groupProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties, "Group Props:", groupProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "PMFnMRv1ZEDPR3jaQU1R",
    "br": "C4fcwBgG4",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "JmwsgKTQe50MTmUE9FVs",
    "se": (new Date()).toISOString(),
    "so": "8IVGABTci",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "PMFnMRv1ZEDPR3jaQU1R",
    "br": "C4fcwBgG4",
    "en": env,
    "ty": type,
    "sc": "JmwsgKTQe50MTmUE9FVs",
    "se": (new Date()).toISOString(),
    "so": "8IVGABTci",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "JmwsgKTQe50MTmUE9FVs",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties, groupProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties,
    groupProperties: groupProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


var Language = {
  EN: "en",
  ES: "es",
  FR: "fr",
  DE: "de",
  IT: "it",
  SV: "sv",
  PL: "pl",
  ZH: "zh",
};

var LoggedIn = {
  TRUE: "true",
  FALSE: "false",
};

var EventCategory = {
  CHECKOUT_INTERACTIONS: "checkout_interactions",
  PLP_INTERACTIONS: "plp_interactions",
  PDP_INTERACTIONS: "pdp_interactions",
  GLOBAL_INTERACTIONS: "global_interactions",
  FOOTER_INTERACTIONS: "footer_interactions",
  JOURNAL_INTERACTIONS: "journal_interactions",
  CM_INTERACTIONS: "cm_interactions",
  LOOKBUILDER_INTERACTIONS: "lookbuilder_interactions",
  INSTORE_INTERACTIONS: "instore_interactions",
  ECOMMERCE_INTERACTIONS: "ecommerce_interactions",
};

var Country = {
  US: "us",
  CA: "ca",
  NL: "nl",
  GB: "gb",
  DE: "de",
  FR: "fr",
  BE: "be",
  IT: "it",
  SE: "se",
  CH: "ch",
  PL: "pl",
  ES: "es",
  FI: "fi",
  DK: "dk",
  AT: "at",
  NO: "no",
  CZ: "cz",
  HR: "hr",
  HU: "hu",
  EE: "ee",
  LV: "lv",
  LT: "lt",
  GR: "gr",
  IE: "ie",
  SG: "sg",
  IN: "in",
  AU: "au",
  CN: "cn",
  BN: "bn",
  JP: "jp",
  HK: "hk",
  MO: "mo",
  MV: "mv",
  MY: "my",
  IR: "ir",
  LY: "ly",
  PT: "pt",
  RO: "ro",
  CY: "cy",
  SK: "sk",
  BG: "bg",
  SI: "si",
  AE: "ae",
  GH: "gh",
  GI: "gi",
  GW: "gw",
  IS: "is",
  JE: "je",
  LI: "li",
  MC: "mc",
  MT: "mt",
  QA: "qa",
  DO: "do",
  GP: "gp",
  MQ: "mq",
  PR: "pr",
  TT: "tt",
  KR: "kr",
};

var PageType = {
  HOME: "home",
  PRODUCT: "product",
  CATEGORY: "category",
  JOURNAL: "journal",
  STORE: "store",
  LOOKBUILDER: "lookbuilder",
  LOGIN: "login",
  WISHLIST: "wishlist",
  ACCOUNT: "account",
  CHECKOUT: "checkout",
  CART: "cart",
  SUPPORT: "support",
  CUSTOMMADE: "custommade",
  GIFTCARD: "giftcard",
  ERROR: "error",
  INSTORE: "instore",
};

var Currency = {
  EUR: "eur",
  USD: "usd",
  CNY: "cny",
  AUD: "aud",
  BGN: "bgn",
  CAD: "cad",
  CZK: "czk",
  DKK: "dkk",
  HKD: "hkd",
  HUF: "huf",
  ISK: "isk",
  INR: "inr",
  JPY: "jpy",
  CHF: "chf",
  MOP: "mop",
  MYR: "myr",
  NOK: "nok",
  QAR: "qar",
  RON: "ron",
  SGD: "sgd",
  SEK: "sek",
  AED: "aed",
  GBP: "gbp",
};

function assertLanguage(language, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("8iHihZ0rO", label_ ? "language" + ": " + label_ : "language", language));
  if ("en" !== language && "es" !== language && "fr" !== language &&
        "de" !== language && "it" !== language && "sv" !== language &&
        "pl" !== language && "zh" !== language) {
    var message = (label_ ? "language" + ": " + label_ : "language") + " should match one of the following values [ en | es | fr | de | it | sv | pl | zh ] but you provided the value " + language
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "8iHihZ0rO", message: message}]);
  }
  return messages;
}

function assertLoggedIn(loggedIn, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("Kvhizl7kAi", label_ ? "logged_in" + ": " + label_ : "logged_in", loggedIn));
  if ("true" !== loggedIn && "false" !== loggedIn) {
    var message = (label_ ? "logged_in" + ": " + label_ : "logged_in") + " should match one of the following values [ true | false ] but you provided the value " + loggedIn
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "Kvhizl7kAi", message: message}]);
  }
  return messages;
}

function assertGaSessionNumber(gaSessionNumber, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("QQnci_8-j", label_ ? "ga_session_number" + ": " + label_ : "ga_session_number", gaSessionNumber));
  return messages;
}

function assertEventCategory(eventCategory, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("VdCb01TKRz", label_ ? "event_category" + ": " + label_ : "event_category", eventCategory));
  if ("checkout_interactions" !== eventCategory &&
        "plp_interactions" !== eventCategory &&
        "pdp_interactions" !== eventCategory &&
        "global_interactions" !== eventCategory &&
        "footer_interactions" !== eventCategory &&
        "journal_interactions" !== eventCategory &&
        "cm_interactions" !== eventCategory &&
        "lookbuilder_interactions" !== eventCategory &&
        "instore_interactions" !== eventCategory &&
        "ecommerce_interactions" !== eventCategory) {
    var message = (label_ ? "event_category" + ": " + label_ : "event_category") + " should match one of the following values [ checkout_interactions | plp_interactions | pdp_interactions | global_interactions | footer_interactions | journal_interactions | cm_interactions | lookbuilder_interactions | instore_interactions | ecommerce_interactions ] but you provided the value " + eventCategory
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "VdCb01TKRz", message: message}]);
  }
  return messages;
}

function assertCountry(country, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("bCDyX8-bZ", label_ ? "country" + ": " + label_ : "country", country));
  if ("us" !== country && "ca" !== country && "nl" !== country &&
        "gb" !== country && "de" !== country && "fr" !== country &&
        "be" !== country && "it" !== country && "se" !== country &&
        "ch" !== country && "pl" !== country && "es" !== country &&
        "fi" !== country && "dk" !== country && "at" !== country &&
        "no" !== country && "cz" !== country && "hr" !== country &&
        "hu" !== country && "ee" !== country && "lv" !== country &&
        "lt" !== country && "gr" !== country && "ie" !== country &&
        "sg" !== country && "in" !== country && "au" !== country &&
        "cn" !== country && "bn" !== country && "jp" !== country &&
        "hk" !== country && "mo" !== country && "mv" !== country &&
        "my" !== country && "ir" !== country && "ly" !== country &&
        "pt" !== country && "ro" !== country && "cy" !== country &&
        "sk" !== country && "bg" !== country && "si" !== country &&
        "ae" !== country && "gh" !== country && "gi" !== country &&
        "gw" !== country && "is" !== country && "je" !== country &&
        "li" !== country && "mc" !== country && "mt" !== country &&
        "qa" !== country && "do" !== country && "gp" !== country &&
        "mq" !== country && "pr" !== country && "tt" !== country &&
        "kr" !== country) {
    var message = (label_ ? "country" + ": " + label_ : "country") + " should match one of the following values [ us | ca | nl | gb | de | fr | be | it | se | ch | pl | es | fi | dk | at | no | cz | hr | hu | ee | lv | lt | gr | ie | sg | in | au | cn | bn | jp | hk | mo | mv | my | ir | ly | pt | ro | cy | sk | bg | si | ae | gh | gi | gw | is | je | li | mc | mt | qa | do | gp | mq | pr | tt | kr ] but you provided the value " + country
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "bCDyX8-bZ", message: message}]);
  }
  return messages;
}

function assertGaClientId(gaClientId, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("ba9__C5yS", label_ ? "ga_client_id" + ": " + label_ : "ga_client_id", gaClientId));
  return messages;
}

function assertPageType(pageType, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("gppkY78PuY", label_ ? "page_type" + ": " + label_ : "page_type", pageType));
  if ("home" !== pageType && "product" !== pageType &&
        "category" !== pageType && "journal" !== pageType &&
        "store" !== pageType && "lookbuilder" !== pageType &&
        "login" !== pageType && "wishlist" !== pageType &&
        "account" !== pageType && "checkout" !== pageType &&
        "cart" !== pageType && "support" !== pageType &&
        "custommade" !== pageType && "giftcard" !== pageType &&
        "error" !== pageType && "instore" !== pageType) {
    var message = (label_ ? "page_type" + ": " + label_ : "page_type") + " should match one of the following values [ home | product | category | journal | store | lookbuilder | login | wishlist | account | checkout | cart | support | custommade | giftcard | error | instore ] but you provided the value " + pageType
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "gppkY78PuY", message: message}]);
  }
  return messages;
}

function assertCurrency(currency, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("jhDpoElQU", label_ ? "currency" + ": " + label_ : "currency", currency));
  if ("eur" !== currency && "usd" !== currency && "cny" !== currency &&
        "aud" !== currency && "bgn" !== currency && "cad" !== currency &&
        "czk" !== currency && "dkk" !== currency && "hkd" !== currency &&
        "huf" !== currency && "isk" !== currency && "inr" !== currency &&
        "jpy" !== currency && "chf" !== currency && "mop" !== currency &&
        "myr" !== currency && "nok" !== currency && "qar" !== currency &&
        "ron" !== currency && "sgd" !== currency && "sek" !== currency &&
        "aed" !== currency && "gbp" !== currency) {
    var message = (label_ ? "currency" + ": " + label_ : "currency") + " should match one of the following values [ eur | usd | cny | aud | bgn | cad | czk | dkk | hkd | huf | isk | inr | jpy | chf | mop | myr | nok | qar | ron | sgd | sek | aed | gbp ] but you provided the value " + currency
    messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "jhDpoElQU", message: message}]);
  }
  return messages;
}

function assertLookbuilderOption(lookbuilderOption, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("l7cUJZWvK", label_ ? "lookbuilder_option" + ": " + label_ : "lookbuilder_option", lookbuilderOption));
  return messages;
}

function assertOptionalLookbuilderOption(lookbuilderOption, label_) {
  var messages = [];
  if (lookbuilderOption !== undefined && lookbuilderOption !== null) {
    messages = messages.concat(AvoAssert.assertString("l7cUJZWvK", label_ ? "lookbuilder_option" + ": " + label_ : "lookbuilder_option", lookbuilderOption));
  }
  return messages;
}

function assertLocationId(locationId, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("memdKZdQJ1", label_ ? "location_id" + ": " + label_ : "location_id", locationId));
  return messages;
}

function assertUrl(url, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("olJP9CQ0tb", label_ ? "url" + ": " + label_ : "url", url));
  return messages;
}

function assertUserId(userId, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("piJkZJx8sqS", label_ ? "user_id" + ": " + label_ : "user_id", userId));
  return messages;
}

function assertGaSessionId(gaSessionId, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("sFU-LulvR", label_ ? "ga_session_id" + ": " + label_ : "ga_session_id", gaSessionId));
  return messages;
}

function setAvoLogger(avoLogger) {
  __AVO_LOGGER__ = avoLogger;
}


var Segment;
function setup_(options, systemProperties, SegmentDestination,
  destinationOptions) {
  __WEB_DEBUGGER__ = false;
  if (options.validateProperties === true) {
    __AVO_DEV__ = true;
  }

  destinationOptions = destinationOptions || {};

  if (!__AVO_NOOP__) {
    if (options.useProductionKey) {
    } else {
    }

    Segment = SegmentDestination;
    Segment.make(options.useProductionKey);
    if (__AVO_DEV__) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
    }
  }
}

function initAvo(options, systemProperties, destinationOptions,
  SegmentDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env.toLowerCase();
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
    InternalAvoLogger.warn("****************************************************");
    InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("****************************************************");
    InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("- No events will be sent");
    InternalAvoLogger.log("- No network requests are made");
    InternalAvoLogger.log("****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
  }
  if (__AVO_ENV__ !== 'prod') {
    __AVO_DEV__ = true;
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions && options.webDebuggerOptions.position) {
      const {
        BOTTOM_RIGHT,
        BOTTOM_LEFT,
        TOP_RIGHT,
        TOP_LEFT,
        } =
        WebDebuggerPosition.position;

      try {
        WebDebuggerAssertions.assertKey(
          options.webDebuggerOptions.position,
          "position",
          position => {
            return position === BOTTOM_RIGHT ||
            position === BOTTOM_LEFT ||
            position === TOP_RIGHT ||
            position === TOP_LEFT;
          }
        )
      } catch (e) {
        throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
      }

      const top = options.webDebuggerOptions.position.top || 0;
      const right = options.webDebuggerOptions.position.right || 0;
      const bottom = options.webDebuggerOptions.position.bottom || 0;
      const left = options.webDebuggerOptions.position.left || 0;

      let positionParams;

      switch (options.webDebuggerOptions.position.position) {
        case BOTTOM_RIGHT:
          positionParams = {
            position: BOTTOM_RIGHT,
            bottom,
            right,
          };
          break;
        case BOTTOM_LEFT:
          positionParams = {
            position: BOTTOM_LEFT,
            bottom,
            left,
          };
          break;
        case TOP_RIGHT:
          positionParams = {
            position: TOP_RIGHT,
            top,
            right,
          };
          break;
        case TOP_LEFT:
          positionParams = {
            position: TOP_LEFT,
            top,
            left,
          };
          break;
        default: throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
      }

      _avo_debugger_send_position(positionParams);
    }

(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === 'prod') {
    }
    if (__AVO_ENV__ === 'dev') {
    }
    if (__AVO_ENV__ === 'staging') {
    }

    Segment = SegmentDestination;
    if (__AVO_ENV__ === 'prod') {
      Segment.make && Segment.make(__AVO_ENV__, "4OhwIsrqFzQ0aeuYev8muGSWiimGtGIG");
    } else if (__AVO_ENV__ === 'dev') {
      Segment.make && Segment.make(__AVO_ENV__, null);
    } else {
      Segment.make && Segment.make(__AVO_ENV__, null);
    }
    if (__AVO_DEV__) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

/**
 * lookbuilder_option_select: When clicking on an option:
lookbuilder_option: lookbuilder_jacket

 *
 * When to trigger this event:
 * 1. When clicking on an option
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/xDQ89dCxxI/trigger/AOkwoK1rb
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/xDQ89dCxxI}
 */
export function lookbuilderOptionSelect(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_option_select", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xDQ89dCxxI", "de65611aaf58f55514e685f38a453813c87ddbbb91abeee2dd8529daaccff544", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_option_select", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xDQ89dCxxI", "lookbuilder_option_select", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_option_select': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_option_select", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "xDQ89dCxxI", "de65611aaf58f55514e685f38a453813c87ddbbb91abeee2dd8529daaccff544");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_option_select", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_new-request_seperate: Old event: start_separate

When landing on the lookbuilder application on 'seperate' mode


 *
 * When to trigger this event:
 * 1. When landing on the lookbuilder application
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/dJjdAr_DZ/trigger/kEmR65ngq
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/dJjdAr_DZ}
 */
export function lookbuilderNewRequestSeperate(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_new-request_seperate", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dJjdAr_DZ", "f275437c97eef55fe8ec9cba6bd947f4a869a634f70924ec68c76375b1792697", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_new-request_seperate", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dJjdAr_DZ", "lookbuilder_new-request_seperate", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_new-request_seperate': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_new-request_seperate", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "dJjdAr_DZ", "f275437c97eef55fe8ec9cba6bd947f4a869a634f70924ec68c76375b1792697");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_new-request_seperate", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_switch_to_separate: Old event: switch_to_separate



 *
 * When to trigger this event:
 * 1. When switching to separate
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/Kd7tcDljv/trigger/gVZK-SjJD
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} [properties.lookbuilderOption] - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/Kd7tcDljv}
 */
export function lookbuilderSwitchToSeparate(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertOptionalLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_switch_to_separate", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Kd7tcDljv", "4134f9d567c3d2dd40cc19a57a9c7d0c59349489baf0940e5b3c6f9173656835", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_switch_to_separate", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Kd7tcDljv", "lookbuilder_switch_to_separate", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_switch_to_separate': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_switch_to_separate", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "Kd7tcDljv", "4134f9d567c3d2dd40cc19a57a9c7d0c59349489baf0940e5b3c6f9173656835");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_switch_to_separate", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_new-request_suit: Old event: start_suit

When landing on the lookbuilder application on 'suit' mode


 *
 * When to trigger this event:
 * 1. When landing on the lookbuilder application on 'suits' mode
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/ZNS6ijLyQ/trigger/vhwEL_jAU
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/ZNS6ijLyQ}
 */
export function lookbuilderNewRequestSuit(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_new-request_suit", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ZNS6ijLyQ", "4bf59f97ab5b51ed17e9cb227e2ea151ae7c3ef4b4197f18a099d3e762cb203d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_new-request_suit", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ZNS6ijLyQ", "lookbuilder_new-request_suit", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_new-request_suit': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_new-request_suit", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "ZNS6ijLyQ", "4bf59f97ab5b51ed17e9cb227e2ea151ae7c3ef4b4197f18a099d3e762cb203d");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_new-request_suit", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_product_confirmed: Old event: start_separate
lookbuilder_option: {{SKU}}

When landing on the lookbuilder application


 *
 * When to trigger this event:
 * 1. When selecting a product
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/mZ5MnCIxj/trigger/Z59LrhhzxH
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/mZ5MnCIxj}
 */
export function lookbuilderProductConfirmed(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_product_confirmed", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mZ5MnCIxj", "daa2ddc6b5716330524ae65fe02ee7882dc91d0c52248c673842b7403121bbb2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_product_confirmed", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mZ5MnCIxj", "lookbuilder_product_confirmed", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_product_confirmed': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_product_confirmed", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "mZ5MnCIxj", "daa2ddc6b5716330524ae65fe02ee7882dc91d0c52248c673842b7403121bbb2");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_product_confirmed", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_switch_to_suit: Old event: switch_to_suit



 *
 * When to trigger this event:
 * 1. When switching to suit
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/X_bDBJmHs/trigger/Mh0tOVcURM
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} [properties.lookbuilderOption] - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/X_bDBJmHs}
 */
export function lookbuilderSwitchToSuit(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertOptionalLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_switch_to_suit", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "X_bDBJmHs", "73000c4c9d4f773fdb5a8f584570f2078a15df58a69117be55131c01a3f8b9fb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_switch_to_suit", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("X_bDBJmHs", "lookbuilder_switch_to_suit", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_switch_to_suit': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_switch_to_suit", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "X_bDBJmHs", "73000c4c9d4f773fdb5a8f584570f2078a15df58a69117be55131c01a3f8b9fb");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_switch_to_suit", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_add_category: Old event: category_selected
lookbuilder_option: {{selected category}}



 *
 * When to trigger this event:
 * 1. When clicking on 'add {{category}}'
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/anQ934SOW/trigger/uQkzc7xW7M
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/anQ934SOW}
 */
export function lookbuilderAddCategory(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_add_category", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "anQ934SOW", "793237c197d59bfb72e7230f987828c159b5086b55d64e6750a9c52c3eb1c6be", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_add_category", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("anQ934SOW", "lookbuilder_add_category", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_add_category': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_add_category", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "anQ934SOW", "793237c197d59bfb72e7230f987828c159b5086b55d64e6750a9c52c3eb1c6be");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_add_category", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_category_removed: Old event: category_removed
lookbuilder_option: {{selected category}}



 *
 * When to trigger this event:
 * 1. When clicking on confirm button after selecting 'remove {{category}}'
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/aoEgY7mdK/trigger/pTY3OrcR2s
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/aoEgY7mdK}
 */
export function lookbuilderCategoryRemoved(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_category_removed", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "aoEgY7mdK", "a635794ce863acd16e24b339c333c22a4d8cfcead5f51a81d588f972aa6385f9", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_category_removed", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("aoEgY7mdK", "lookbuilder_category_removed", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_category_removed': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_category_removed", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "aoEgY7mdK", "a635794ce863acd16e24b339c333c22a4d8cfcead5f51a81d588f972aa6385f9");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_category_removed", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

/**
 * lookbuilder_shop_the_look: Old event: shop_the_look_button
lookbuilder_option: {SKU}|{SKU}


 *
 * When to trigger this event:
 * 1. When clicking on 'shop look' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/QEBgr3WBb/trigger/-cp2kWyMhc
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
 * @param {string} properties.country - Local country of website, ISO standard, lowercase
 * @param {string} properties.language - language used on page, ISO standard, lowercase
 * @param {string} properties.url - URL without locale
 * @param {string} properties.locationId - Url with locale
 * @param {string} properties.userId - Unique customer ID
 * @param {string} properties.loggedIn - 'true' or 'false'
 * @param {string} properties.pageType - describes the page_type
 * @param {string} properties.gaClientId - gets the client id from the local storage
 * @param {string} properties.gaSessionId - Gets the Google analytics session id from the local storage
 * @param {string} properties.gaSessionNumber - Gets the session number from the local storage
 * @param {string} properties.lookbuilderOption - SKU or category of product
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/C4fcwBgG4/events/QEBgr3WBb}
 */
export function lookbuilderShopTheLook(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCurrency(properties.currency));
    messages = messages.concat(assertCountry(properties.country));
    messages = messages.concat(assertLanguage(properties.language));
    messages = messages.concat(assertUrl(properties.url));
    messages = messages.concat(assertLocationId(properties.locationId));
    messages = messages.concat(assertUserId(properties.userId));
    messages = messages.concat(assertLoggedIn(properties.loggedIn));
    messages = messages.concat(assertPageType(properties.pageType));
    messages = messages.concat(assertGaClientId(properties.gaClientId));
    messages = messages.concat(assertGaSessionId(properties.gaSessionId));
    messages = messages.concat(assertGaSessionNumber(properties.gaSessionNumber));
    messages = messages.concat(assertLookbuilderOption(properties.lookbuilderOption));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("lookbuilder_shop_the_look", Object.keys(properties), [
      "currency",
      "country",
      "language",
      "url",
      "locationId",
      "userId",
      "loggedIn",
      "pageType",
      "gaClientId",
      "gaSessionId",
      "gaSessionNumber",
      "lookbuilderOption"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QEBgr3WBb", "5153d57bc03848ef9102c3662c3c35db8d33123c114cbfac0915362b61933b58", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("lookbuilder_shop_the_look", {
      "event_category": "lookbuilder_interactions",
      "currency": properties.currency,
      "country": properties.country,
      "language": properties.language,
      "url": properties.url,
      "location_id": properties.locationId,
      "user_id": properties.userId,
      "logged_in": properties.loggedIn,
      "page_type": properties.pageType,
      "ga_client_id": properties.gaClientId,
      "ga_session_id": properties.gaSessionId,
      "ga_session_number": properties.gaSessionNumber,
      "lookbuilder_option": properties.lookbuilderOption,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QEBgr3WBb", "lookbuilder_shop_the_look", messages, [
      {id: "VdCb01TKRz", name: "event_category", value: "lookbuilder_interactions"},
      {id: "8iHihZ0rO", name: "language", value: properties.language},
      {id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn},
      {id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber},
      {id: "bCDyX8-bZ", name: "country", value: properties.country},
      {id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId},
      {id: "gppkY78PuY", name: "page_type", value: properties.pageType},
      {id: "jhDpoElQU", name: "currency", value: properties.currency},
      {id: "l7cUJZWvK", name: "lookbuilder_option", value: properties.lookbuilderOption},
      {id: "memdKZdQJ1", name: "location_id", value: properties.locationId},
      {id: "olJP9CQ0tb", name: "url", value: properties.url},
      {id: "piJkZJx8sqS", name: "user_id", value: properties.userId},
      {id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId},
      ], [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'lookbuilder_shop_the_look': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("lookbuilder_shop_the_look", {
        "event_category": "lookbuilder_interactions",
        "currency": properties.currency,
        "country": properties.country,
        "language": properties.language,
        "url": properties.url,
        "location_id": properties.locationId,
        "user_id": properties.userId,
        "logged_in": properties.loggedIn,
        "page_type": properties.pageType,
        "ga_client_id": properties.gaClientId,
        "ga_session_id": properties.gaSessionId,
        "ga_session_number": properties.gaSessionNumber,
        "lookbuilder_option": properties.lookbuilderOption,
        }, "QEBgr3WBb", "5153d57bc03848ef9102c3662c3c35db8d33123c114cbfac0915362b61933b58");
    }
    // destination Segment
    Segment.logEvent("lookbuilder_shop_the_look", {
    "event_category": "lookbuilder_interactions",
    "currency": properties.currency,
    "country": properties.country,
    "language": properties.language,
    "url": properties.url,
    "location_id": properties.locationId,
    "user_id": properties.userId,
    "logged_in": properties.loggedIn,
    "page_type": properties.pageType,
    "ga_client_id": properties.gaClientId,
    "ga_session_id": properties.gaSessionId,
    "ga_session_number": properties.gaSessionNumber,
    "lookbuilder_option": properties.lookbuilderOption,
    });
  } else {
    // do nothing
  }
}

export default {
  setAvoLogger: setAvoLogger,
  Language: Language,
  LoggedIn: LoggedIn,
  EventCategory: EventCategory,
  Country: Country,
  PageType: PageType,
  Currency: Currency,
  lookbuilderOptionSelect: lookbuilderOptionSelect,
  lookbuilderNewRequestSeperate: lookbuilderNewRequestSeperate,
  lookbuilderSwitchToSeparate: lookbuilderSwitchToSeparate,
  lookbuilderNewRequestSuit: lookbuilderNewRequestSuit,
  lookbuilderProductConfirmed: lookbuilderProductConfirmed,
  lookbuilderSwitchToSuit: lookbuilderSwitchToSuit,
  lookbuilderAddCategory: lookbuilderAddCategory,
  lookbuilderCategoryRemoved: lookbuilderCategoryRemoved,
  lookbuilderShopTheLook: lookbuilderShopTheLook,
  initAvo: initAvo,
  WebDebuggerPosition: WebDebuggerPosition,
  avoInspectorApiKey: avoInspectorApiKey,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["lookbuilderOptionSelect","lookbuilderNewRequestSeperate","lookbuilderSwitchToSeparate","lookbuilderNewRequestSuit","lookbuilderProductConfirmed","lookbuilderSwitchToSuit","lookbuilderAddCategory","lookbuilderCategoryRemoved","lookbuilderShopTheLook"]
