import {
  lookbuilderNewRequestSeperate,
  lookbuilderNewRequestSuit,
  lookbuilderCategoryRemoved,
  lookbuilderAddCategory,
  lookbuilderOptionSelect,
  lookbuilderSwitchToSeparate,
  lookbuilderProductConfirmed,
  lookbuilderSwitchToSuit,
  lookbuilderShopTheLook,
} from './Avo'
import { SUITS_MODE } from 'data'

export const analytics_segmentTrackingFirstLoad = (config, appMode) => {
  try {
    return appMode === SUITS_MODE
      ? lookbuilderNewRequestSuit(config)
      : lookbuilderNewRequestSeperate(config)
  } catch (error) {
    console.error('Error in analytics_segmentTrackingFirstLoad:', error)
  }
}

export const analytics_productChange = (product, isRemoved) => {
  try {
    return isRemoved
      ? lookbuilderCategoryRemoved(product)
      : lookbuilderAddCategory(product)
  } catch (error) {
    console.error('Error in analytics_productChange:', error)
  }
}

export const analytics_categorySelect = (category) => {
  try {
    return lookbuilderOptionSelect(category)
  } catch (error) {
    console.error('Error in analytics_categorySelect:', error)
  }
}

export const analytics_shopTheLook = (config) => {
  try {
    return lookbuilderShopTheLook(config)
  } catch (error) {
    console.error('Error in analytics_shopTheLook:', error)
  }
}

export const analytics_modeSwitch = (config, appMode) => {
  try {
    return appMode === SUITS_MODE
      ? lookbuilderSwitchToSuit(config)
      : lookbuilderSwitchToSeparate(config)
  } catch (error) {
    console.error('Error in analytics_modeSwitch:', error)
  }
}

export const analytics_productConfirmed = (config) => {
  try {
    return lookbuilderProductConfirmed(config)
  } catch (error) {
    console.error('Error in analytics_productConfirmed:', error)
  }
}

export const getAvoMetadata = ({
  currencyCode,
  countryCode,
  locale,
  siteID,
  isLoggedIn,
  lookbuilderOption,
  ...rest
}) => {
  return {
    currency: currencyCode?.toLowerCase(),
    country: countryCode?.toLowerCase(),
    language: locale,
    locationId: siteID,
    userId: '',
    loggedIn: isLoggedIn || false,
    pageType: 'lookbuilder',
    lookbuilderOption: lookbuilderOption || '',
    url: '',
    gaClientId: '',
    gaSessionId: '',
    gaSessionNumber: '',
    ...rest,
  }
}
