import styled from 'styled-components'
import { createUrl, setCategoryId } from 'utils'
import { DEVICE_SIZES, IMAGE_SIZES } from 'styles'
import { forwardRef } from 'preact/compat'
import { motion } from 'framer-motion'
import { useRef, useState } from 'preact/hooks'
import { SUITS_MODE, CATEGORIES } from 'data'

const LAST_IMAGE = IMAGE_SIZES[DEVICE_SIZES.length - 1]

const createSrcSet = (
  category,
  id,
  catId,
  isSubCategory,
  isCounterPart,
  isNestedProduct,
  layerName
) => {
  let string = ''
  DEVICE_SIZES.forEach((size, i) => {
    string += createUrl(
      IMAGE_SIZES[i],
      category,
      id,
      size,
      catId,
      isSubCategory,
      isCounterPart,
      isNestedProduct,
      layerName
    )
  })
  return string
}

const PictureWrapper = styled(motion.picture)`
  display: flex;
  align-items: ${(props) =>
    props.variant === 'select' ? 'flex-end' : 'center'};
  justify-content: center;
  overflow: ${(props) => props.variant === 'select' && 'hidden'};
  border-radius: ${(props) => props.variant === 'select' && '4px'};
  width: ${(props) => props.variant === 'select' && '100%'};
  pointer-events: none;

  img {
    width: 100%;
  }
`

const Image = forwardRef(
  (
    {
      category,
      id,
      alt,
      onLoad,
      customSrc,
      customSet,
      variant,
      className,
      subCategory,
      version,
      isOutOfStock,
      layerName,
      appMode,
      isCounterPart = false,
      isNestedProduct = false,
    },
    ref
  ) => {
    const isSuitsMode = appMode === SUITS_MODE
    const isSuitsBottom = isSuitsMode && category === CATEGORIES.Trousers[0]

    const catId = setCategoryId(category)

    const imageVersion =
      typeof version === 'object' ? version?.Thumbnail : catId
    const imageWrapRef = useRef()
    const isSubCategory = subCategory ? subCategory : category

    const overwriteCategory = isSuitsBottom
      ? `Suits_${isSubCategory}`
      : isSubCategory

    const fallbackImage = `https://testing.lookbuilder.suitsupply.com/assets/placeholders/${
      isCounterPart ? 'Suits_' : ''
    }${layerName}.png`

    const [currentSource, setCurrentSource] = useState(
      customSrc
        ? customSrc
        : createUrl(
            LAST_IMAGE,
            overwriteCategory,
            id,
            false,
            imageVersion,
            isSubCategory,
            isCounterPart,
            isNestedProduct,
            layerName
          )
    )

    const [currentSourceSet, setCurrentSourceSet] = useState(
      customSet
        ? customSet
        : createSrcSet(
            overwriteCategory,
            id,
            imageVersion,
            isSubCategory,
            isCounterPart,
            isNestedProduct,
            layerName
          )
    )

    return (
      <PictureWrapper
        variant={variant}
        ref={imageWrapRef}
        className={className}
      >
        <img
          ref={ref}
          alt={alt}
          src={isOutOfStock ? fallbackImage : currentSource}
          srcSet={!isOutOfStock && currentSourceSet}
          onLoad={onLoad}
          onError={() => {
            setCurrentSource(fallbackImage)
            setCurrentSourceSet('')
          }}
        />
      </PictureWrapper>
    )
  }
)

export default Image
