import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQueries, colors, interfaceSize, elementFocus } from 'styles'

export const Container = styled(motion.div)`
  position: absolute;
  bottom: calc(${interfaceSize.mobile}px + 14px);
  left: 12px;

  /* bump to bypass platform z-index */
  z-index: 121;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 34px;
  background-color: ${colors.grey['200']};
  border-radius: 35px;

  transition: transform 0.35s ease;

  outline: 4px solid transparent;

  ${elementFocus};

  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:focus-within {
    margin-top: unset;
  }

  ${mediaQueries.s} {
    left: 24px;
    bottom: calc(${interfaceSize.tablet}px + 24px);
  }

  ${mediaQueries.m} {
    bottom: unset;
    left: unset;

    width: 64px;
    height: 42px;

    top: 20px;
    right: calc(${interfaceSize.desktop}px + 20px);
  }
`

export const Thumb = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${colors.white};
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 101;
  top: 50%;
  left: 0;

  transition: transform 0.2s ease;
  transform-origin: center center;

  filter: ${({ $disabled }) => ($disabled ? 'opacity(0.7)' : 'none')};

  transform: ${({ $transformTrigger }) => {
    if ($transformTrigger) return `translateX(17px) translateY(-50%)`
    return `translateX(1px) translateY(-50%)`
  }};

  ${mediaQueries.m} {
    transform: ${({ $transformTrigger }) => {
      if ($transformTrigger) return `translateX(23px) translateY(-50%)`
      return `translateX(1px) translateY(-50%)`
    }};
    width: 40px;
    height: 40px;
  }
`

export const MotionContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  transform-origin: center;
`

export const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
`

export const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background-color: ${colors.black};
  opacity: 0.2;
`

export const TooltipWrapper = styled(motion.div)`
  position: absolute;
  bottom: 50px;
  left: 0px;
  z-index: 100;

  /* width: 135px; */
  width: max-content;
  background-color: white;
  height: 44px;
  border-radius: 8px;

  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.15));

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: calc(10% + 0.5em);
    transform: translateX(calc(-10% - 1em));

    width: 0;
    height: 0;

    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 16px solid white;
  }

  ${mediaQueries.s} {
    left: 0;
  }

  ${mediaQueries.m} {
    left: unset;

    bottom: calc(-100% + -25px);
    right: calc(-100% + 20px);

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);

      width: 0;
      height: 0;

      border-top: unset;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 16px solid white;
    }
  }
`

export const TooltipLabel = styled.span`
  line-height: 130%;
  font-weight: 500;
`
