import { motion } from 'framer-motion'
import styled from 'styled-components'
import { animated } from '@react-spring/web'

import Icon from 'components/Icon'
import ModelImage from 'components/ModelImage'

import { vh } from 'utils'
import { colors, mediaQueries, interfaceSize, hideScrollbars } from 'styles'

const PAGE_HEIGHT = 810
const PAGE_HEIGHT_MOBILE = 518
const PAGE_HEIGHT_MOBILE_LANDSCAPE = 320

export const Component = styled(motion.div)`
  touch-action: none;
  position: relative;
  overflow: scroll;
  background: ${colors.grey[100]};
  height: calc(100% - ${interfaceSize.mobile}px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${hideScrollbars}

  ${mediaQueries.s} {
    height: 100%;
  }
  ${mediaQueries.m} {
    height: 100%;
    width: calc(100% - ${interfaceSize.desktop}px);
  }
`

export const Height = styled(animated.div)`
  touch-action: none;
  width: calc(
    var(--vh, 1vh) * ${vh(150, PAGE_HEIGHT_MOBILE)} - var(--header-mobile)
  );
  position: relative;
  height: 100%;

  ${mediaQueries.landscape} {
    width: calc(
      var(--vh, 1vh) * ${vh(85, PAGE_HEIGHT_MOBILE_LANDSCAPE)} -
        var(--header-mobile)
    );
  }

  ${mediaQueries.s} {
    cursor: ${({ $hideCursor }) => $hideCursor && 'none'};
  }

  ${mediaQueries.m} {
    height: 100%;
    width: calc(var(--vh, 1vh) * ${vh(300, PAGE_HEIGHT)} - var(--header));
  }
`

export const Cloth = styled.div`
  touch-action: none;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
`

export const ModelGroup = styled(Cloth)`
  pointer-events: none;
  top: 49%;
  transform: translate(-50%, -50%);

  *::selection {
    background: transparent;
  }
`

export const TopLayer = styled(Cloth)`
  top: 11.8%;
  z-index: 6;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
`

export const MiddleLayer = styled(Cloth)`
  top: 11.8%;
  z-index: ${(props) => (props.isTucked ? 2 : 4)};
`

export const WaistCoatLayer = styled(Cloth)`
  top: 11.8%;
  z-index: 5;
`

export const CoatLayer = styled(Cloth)`
  top: 11.8%;
  z-index: 6;
`

export const BottomLayer = styled(Cloth)`
  top: 31%;
  z-index: 3;
`

export const ShoeLayer = styled(Cloth)`
  top: 79%;
`

export const HiddenImage = styled(ModelImage)`
  display: none;
`

export const StyledIcon = styled(Icon)`
  display: ${({ $mousePos }) => ($mousePos ? 'block' : 'none')};
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 100;
  pointer-events: none;
`
