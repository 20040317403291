export const createWorker = (computation, ...message) => {
  const delegate = () => {
    onmessage = ({ data: { computation, message } }) => {
      const wrapper = (fn) => Function(`"use strict"; return (${fn.toString()})`)()
      wrapper(computation)(...message)
      postMessage(message)
    }
  }
  const functionBody = delegate
    .toString()
    .replace(/^[^{]*{\s*/, '')
    .replace(/\s*}[^}]*$/, '')

  return new Promise((resolve, reject) => {
    const worker = new Worker(URL.createObjectURL(new Blob([functionBody], { type: 'text/javascript' })))
    worker.postMessage({ computation: computation.toString(), message })
    worker.onmessage = ({ data }) => {
      resolve(data)
    }
    worker.onerror = worker.onmessageerror = reject
    return worker
  })
}
