import styled from 'styled-components'
import { useContext, useRef } from 'preact/hooks'
import { AnimatePresence } from 'framer-motion'

import { HorizontalScrollContext } from 'contexts'

import Notice from './Notice'
import Icon from 'components/Icon'

import {
  mediaQueries,
  colors,
  globalRadius,
  resetButtonSize,
  transitions,
  elementFocus,
} from 'styles'

import {
  translateStringKey,
  getSingleCategory,
  extractLocaleString,
} from 'utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.s} {
    margin-top: 10px;
    padding-right: 15px;
  }

  ${mediaQueries.m} {
    margin-top: unset;
    padding: 5px 10px 10px 10px;
    width: 100%;
  }
`

const Inner = styled.button`
  background-color: ${colors.white};

  ${elementFocus};

  &:hover {
    border-color: ${({ $isActive }) => !$isActive && colors.grey[200]};
  }

  &:focus {
    margin-top: unset;
  }

  &:active {
    outline: 1px solid ${colors.secondary};
  }

  height: ${resetButtonSize.mobile.height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ $isActive }) => ($isActive ? colors.secondary : colors.grey[200])};

  transition: border-color 0.3s ${transitions.general.css};

  border-radius: ${globalRadius};
  padding: 20px 10px;

  margin: 10px 15px 0 0;

  ${mediaQueries.s} {
    margin: unset;
    padding: 10px 0 10px 0;
    height: ${resetButtonSize.tablet.height}px;
    width: ${resetButtonSize.tablet.width}px;
  }

  ${mediaQueries.m} {
    margin: unset;
    padding: 10px 0 10px 0;
    height: ${resetButtonSize.desktop.height}px;
    width: 100%;

    flex-direction: row;
    align-items: center;
    gap: 6px;

    &:hover {
      cursor: pointer;
    }
  }
`

const StyledIcon = styled(Icon)`
  color: ${colors.grey['900']};
  transform: scale(0.7);

  ${mediaQueries.s} {
    transform: scale(0.9);
  }
  ${mediaQueries.m} {
    transform: unset;
  }
`

const CategoryLabel = styled.span`
  display: none;

  ${mediaQueries.m} {
    display: block;
    font-weight: 300;

    span:nth-child(2) {
      text-transform: lowercase;
    }
  }
`

const ResetButton = ({ onClick, layerName, isActive, locales }) => {
  const buttonRef = useRef()

  const actionLabel = isActive ? 'removed' : 'remove'
  const locale = extractLocaleString(locales)
  const translationString =
    translateStringKey(`${actionLabel}-${layerName}`, locale) ||
    translateStringKey(`${actionLabel}`, locale)

  const handleOnClick = () => {
    buttonRef.current.blur()
    onClick()
  }

  return (
    <Container>
      <Inner
        key="reset-button"
        $isActive={isActive}
        onClick={handleOnClick}
        ref={buttonRef}
      >
        <StyledIcon icon="prohibited" />
        <CategoryLabel>
          <span>{`${translationString}`}</span>
        </CategoryLabel>
      </Inner>
    </Container>
  )
}

export default ResetButton
