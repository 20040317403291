let WINDOW_RATIO

if (typeof window !== 'undefined') {
  WINDOW_RATIO = window?.devicePixelRatio > 2 ? Math.round(window?.devicePixelRatio) : 2
}

export const DEVICE_SIZES = [640, 750, 828, 1080, 1200, 1920, 2048, 3840]
const THUMB_IMAGES = [70, 70, 70, 70, 100, 100, 100, 100]
export const IMAGE_SIZES = THUMB_IMAGES.map((el) => el * WINDOW_RATIO)
const MODEL_IMAGES = [256, 256, 320, 228, 241, 345, 375, 428]
export const MODEL_IMAGES_SIZES = MODEL_IMAGES.map((el) => el * WINDOW_RATIO)
