import Thumbnail from 'components/Thumbnail'
import ModelSelection from 'components/ModelSelection'

import { itemAnimation } from 'styles'
import { translateStringKey } from 'utils'

import {
  CategoryContainer,
  CategoryTitle,
  CategoryTitleContainer,
} from 'components/Categories/Category.styles.js'

const ADAPTER_MAP = {
  ModelSelection,
}

const AdditionalCategory = ({
  isHorizontalScroll,
  setHasHover,
  controls,
  locale,
  category,
  setCategory,
  index,
  products,
}) => {
  const { componentKey, labelKey } = category
  const Container = ADAPTER_MAP[componentKey]

  const handleCategoryClick = () => {
    setCategory({
      ...category,
      ...{ index: products.length + index },
    })
  }

  return (
    <CategoryContainer
      onMouseMove={() => setHasHover(true)}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      onMouseDown={(e) => e.preventDefault()}
      onKeyPress={handleCategoryClick}
      onClick={handleCategoryClick}
    >
      <Thumbnail
        isCategory
        inheritStyles={false}
      >
        <Container controls={controls} />
      </Thumbnail>
      <CategoryTitleContainer
        variants={itemAnimation}
        custom={isHorizontalScroll}
      >
        <CategoryTitle>{translateStringKey(labelKey, locale)}</CategoryTitle>
      </CategoryTitleContainer>
    </CategoryContainer>
  )
}

export default AdditionalCategory
