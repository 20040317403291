import {
  SUMMARY_SORT_OBJECT,
  TOP_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
  LAYER_REVERSE_MAP,
} from '../data'
import { CDN_URL } from './endpoints'
import { REGEX_END_LETTER } from './regex'
import { capitalize } from './string'

const getTransforms = (category, size) => {
  switch (category) {
    case 'Suits_Trousers':
      return `,c_fill,g_north,w_${Math.round(size)},h_${Math.round(size * 2.9)}`
    case 'Trousers':
      return `,c_fill,g_north,w_${size},h_${Math.round(size * 2.1)}`
    case 'Shorts':
      return `,g_center,c_lpad,w_${size},h_${size}`
    default:
      return `,g_center,c_lpad,w_${size},h_${size}`
  }
}

const UNDERSCORE_MAPPING = {
  [TOP_LAYER]: '25',
  [BOTTOM_LAYER]: '28',
}

const getTransformedCDNurl = (layerName) => {
  const isShoeLayer = layerName === SHOE_LAYER

  if (isShoeLayer) {
    const [baseURL, params] = CDN_URL.split('upload/')
    // Reconstruct the URL with '/e_trim/' added- this adjust the product within the containing canvas. At the time, only the shoelayer products require this adjustment.
    return `${baseURL}upload/e_trim/${params}`
  }

  return CDN_URL
}

/**
 * Function to create url for the image
 * @param {Number} size - size of the image (based on the previous)
 * @param {String} category - category of the image
 * @param {String} id - id of the image
 * @param {String} media - media query
 * @param {String} catId - category id
 * @param {String} isSubCategory - is subcategory, this is the original product category
 * @returns {String} - url of the image
 */
export const createUrl = (
  size,
  category,
  id,
  media,
  catId,
  isSubCategory,
  isCounterPart,
  isNestedProduct = false,
  layerName
) => {
  const transforms = getTransforms(
    isNestedProduct ? `Suits_${LAYER_REVERSE_MAP[layerName]}` : category,
    size
  )

  const background = isCounterPart ? '' : ',b_rgb:efefef'

  const underscore = isNestedProduct
    ? UNDERSCORE_MAPPING[`${layerName}`]
    : catId

  return `${getTransformedCDNurl(
    layerName
  )}${transforms}${background}/products/${capitalize(isSubCategory)}/default/${
    id?.split(REGEX_END_LETTER)[0]
  }_${underscore}.jpg${media ? ` ${media}w,` : ''}`
}

export const transforms = {
  Shoes: `g_south,y_-20`,
  Trousers: `g_south,y_130`,
  Shirts: `g_north,y_475`,
  Jackets: `g_north,y_475`,
  Ties: `g_north,y_475`,
}

export const moveArray = (arr, fromIndex, toIndex, condition = false) => {
  if (condition) {
    let element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
  }
  return arr
}

/**
 * Function to extract locale string from locales object
 * @param {Object} locales - Object with locale string
 * @returns {String} - String with locale, example: 'en'
 */
export const extractLocaleString = (locales) =>
  locales?.locale.split('-')[0] || 'en'

/**
 * Function to sort wishlist products
 * @param {Array} productSet - array of products
 * @returns {String} - string with sorted products ids
 */
export const sortWishList = (productSet) => {
  // some entry is empty
  if (productSet.some((e) => !e?.id)) return null

  return productSet
    .map((e) => {
      if (e?.id !== '0') {
        return { id: e?.id, layerName: e.layerName }
      }
    })
    .filter((x) => x)
    .sort(
      (a, b) =>
        SUMMARY_SORT_OBJECT.indexOf(a.layerName) -
        SUMMARY_SORT_OBJECT.indexOf(b.layerName)
    )
    .map((e) => e.id)
    .join(',')
}

export const getAvoUrl = (urlString) => {
  const url = urlString.split('?')[0].replace(/\/[a-z]{2}-[A-Z]{2}\//, '/')
  const locationId = urlString.split('?')[0]

  return {
    url,
    locationId,
  }
}
