import { Provider } from 'jotai'
import { sortLayers } from 'utils'
import { Modes } from 'contexts'
import { SEPERATES_MODE, SUITS_MODE } from 'data'

const ProductsProvider = ({
  children,
  initialProducts,
  initialSuitProducts,
}) => {
  const sortedProducts = sortLayers({ data: initialProducts })
  const sortedSuitsProducts = sortLayers({ data: initialSuitProducts })

  return (
    <Provider
      initialValues={[
        [Modes[SEPERATES_MODE].JacketsAtom, sortedProducts[0]],
        [Modes[SEPERATES_MODE].ShirtsAtom, sortedProducts[1]],
        [Modes[SEPERATES_MODE].TrousersAtom, sortedProducts[2]],
        [Modes[SEPERATES_MODE].ShoesAtom, sortedProducts[3]],
        [Modes[SEPERATES_MODE].CoatsAtom, sortedProducts[4]],
        [Modes[SUITS_MODE].JacketsAtom, sortedSuitsProducts[0]],
        [Modes[SUITS_MODE].ShirtsAtom, sortedSuitsProducts[1]],
        [Modes[SUITS_MODE].TrousersAtom, sortedSuitsProducts[2]],
        [Modes[SUITS_MODE].ShoesAtom, sortedSuitsProducts[3]],
        [Modes[SUITS_MODE].CoatsAtom, sortedSuitsProducts[4]],
      ]}
    >
      {children}
    </Provider>
  )
}

export default ProductsProvider
