import { useCallback } from 'preact/hooks'

const useRefs = (...refs) =>
  useCallback((el) => {
    refs.forEach((ref) => {
      if (typeof ref === `function`) {
        ref(el)
      } else if (typeof ref === 'object' && ref !== null) {
        ref.current = el
      }
    })
  }, refs) // eslint-disable-line react-hooks/exhaustive-deps

export default useRefs
