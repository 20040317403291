export const breakpoints = {
  s: 768,
  m: 1024,
  l: 1200,
}
const widths = Object.entries(breakpoints).reduce(
  (queries, [key, value]) => ({
    ...queries,
    [key]: `@media (min-width: ${value}px)`,
  }),
  {}
)

export const mediaQueries = {
  ...widths,
  landscape: `@media (max-width: ${breakpoints.m}px) and (orientation: landscape)`,
}
