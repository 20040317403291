import { useLayoutEffect, useRef } from 'preact/hooks'
import { createWorker, createUrl, REGEX_END_LETTER } from 'utils'

const useWorkers = (thumbImageSize, clothImageSize, data, timeoutTime) => {
  const urls = []
  const thumbUrls = []
  const timeoutId = useRef()

  useLayoutEffect(() => {
    if (!(thumbImageSize && clothImageSize && timeoutTime)) return

    timeoutId.current = setTimeout(() => {
      for (let i = 0; i < data?.length; i++) {
        for (let y = 0; y < data[i].length; y++) {
          const realCategory = data[i][y].category
          const category = data[i][y].subCategory ? data[i][y].subCategory : realCategory
          const catId = realCategory === 'Shoes' ? 1 : realCategory === 'Trousers' ? 28 : 25

          urls.push(
            `https://cdn.suitsupply.com/image/upload/dpr_1,f_auto,q_auto,g_center,fl_progressive,${clothImageSize}/products/${category}/default/${
              data[i][y].id.split(REGEX_END_LETTER)[0]
            }_40.png`
          )

          thumbUrls.push(createUrl(thumbImageSize?.split('w_')[1], category, data[i][y].id, undefined, catId))
        }
      }

      createWorker(async (urls) => {
        urls.map(async (url) => {
          try {
            const response = await fetch(url)
            const fileBlob = await response.blob()
            return URL.createObjectURL(fileBlob)
          } catch (e) {
            return null
          }
        })
      }, urls)

      createWorker(async (urls) => {
        urls.map(async (url) => {
          try {
            const response = await fetch(url)
            const fileBlob = await response.blob()
            return URL.createObjectURL(fileBlob)
          } catch (e) {
            return null
          }
        })
      }, thumbUrls)
    }, timeoutTime + 2000)

    return () => clearTimeout(timeoutId.current)
  }, [thumbImageSize, clothImageSize, data, timeoutTime])
}

export default useWorkers
