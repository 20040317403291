import { createContext } from 'preact'
import { atom } from 'jotai'
import { SEPERATES_MODE } from '../data'

export const ScrollContext = createContext({
  element: undefined,
  scrollEvent: { onChange: () => () => {} },
})
export const HorizontalScrollContext = createContext()
export const ModelAtom = atom()

export const SeperatesMode = {
  JacketsAtom: atom('Seperates mode'),
  ShirtsAtom: atom(),
  TrousersAtom: atom(),
  ShoesAtom: atom(),
  CoatsAtom: atom(),
}

export const SuitsMode = {
  JacketsAtom: atom('Suits mode'),
  ShirtsAtom: atom(),
  TrousersAtom: atom(),
  ShoesAtom: atom(),
  CoatsAtom: atom(),
}

export const Modes = {
  SeperatesMode,
  SuitsMode,
}

export const AppModeAtom = atom(SEPERATES_MODE)

export const ProductsAtom = atom(
  (get) => {
    const mode = get(AppModeAtom)
    const modeAtoms = Modes[mode]
    return [
      get(modeAtoms.JacketsAtom),
      get(modeAtoms.ShirtsAtom),
      get(modeAtoms.TrousersAtom),
      get(modeAtoms.ShoesAtom),
      get(modeAtoms.CoatsAtom),
    ]
  },
  (get, set, prevState) => {
    const mode = get(AppModeAtom)
    const modeAtoms = Modes[mode]
    set(modeAtoms.JacketsAtom, prevState[0])
    set(modeAtoms.ShirtsAtom, prevState[1])
    set(modeAtoms.TrousersAtom, prevState[2])
    set(modeAtoms.ShoesAtom, prevState[3])
    set(modeAtoms.CoatsAtom, prevState[4])
  }
)
export const PreviousViewAtom = atom()
export const InterfaceViewAtom = atom()

export const DataAtom = atom()
export const ThumbSizeAtom = atom()
export const ClothSizeAtom = atom()
export const AcceptSelection = atom()
export const LoadingImages = atom(0)
export const TimeOutTime = atom()
export const SetLayerVersion = atom()
export const isAppLoaded = atom(false)
export const AppScope = Symbol()
export const TranslationsAtom = atom(0)
export const GlobalProductsContext = createContext()
export const ProductMatrixContext = createContext()

export const AuthContext = createContext()
export const KOLuser = atom(true)
export const KOLsessionActive = atom(false)

export const PortalAtom = atom()
export const ScannedProductAtom = atom()
