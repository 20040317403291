import { colors } from 'styles'

import { transitions } from './transitions'

const BASE_TRANSITION = {
  type: 'tween',
  ease: transitions.general.js,
}

const BASE_Y_SLIDE = 15
const BASE_X_SLIDE = 25
const BASE_TIME_ALT = 0.25
const BASE_TIME = 0.5
const BASE_STAGGER_TIME = BASE_TIME / 10
const BASE_DURATION = 1
const BASE_DISPLAY_DURATION = 1.5

export const itemAnimation = {
  hidden: (isHorizontalScroll) => ({
    opacity: 0,
    y: isHorizontalScroll ? BASE_Y_SLIDE : 0,
    x: isHorizontalScroll ? 0 : BASE_X_SLIDE,
  }),
  show: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: { BASE_TRANSITION, ...{ duration: BASE_TIME } },
  },
  leave: (isHorizontalScroll) => ({
    opacity: 0,
    y: isHorizontalScroll ? BASE_Y_SLIDE : 0,
    x: isHorizontalScroll ? 0 : BASE_X_SLIDE,
    transition: { BASE_TRANSITION, ...{ duration: BASE_TIME } },
  }),
}

export const containerAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: BASE_STAGGER_TIME,
    },
  },
  leave: {
    opacity: 0,
    transition: {
      staggerChildren: BASE_STAGGER_TIME,
    },
  },
}

export const translationAnimation = {
  hidden: (isHorizontalScroll) => ({
    x: isHorizontalScroll ? 50 : 0,
    y: isHorizontalScroll ? 0 : 100,
  }),
  show: {
    x: 0,
    y: 0,
    transition: {
      duration: BASE_DISPLAY_DURATION,
      staggerChildren: BASE_STAGGER_TIME,
      ease: transitions.general.js,
    },
  },
  leave: false,
}

export const fadeInAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.3 } },
  exit: { opacity: 0 },
  transitions: { duration: BASE_DURATION },
}

export const borderAnimation = {
  initial: { borderColor: colors.transparent },
  animate: (isHovered) => ({
    opacity: 1,
    borderColor: !isHovered ? colors.secondary : colors.grey[300],
    transition: {
      BASE_TRANSITION,
      ...{ duration: 0.1 },
    },
  }),
  exit: {
    opacity: 0,
    borderColor: colors.transparent,
    transition: {
      BASE_TRANSITION,
      ...{ duration: 0.1 },
    },
  },
}

export const growInAnimation = {
  initial: {
    height: 0,
    opacity: 0,
    transition: BASE_TRANSITION,
  },
  animate: (hasHeight) => ({
    height: hasHeight ? hasHeight : 'auto',
    opacity: 1,
    transition: BASE_TRANSITION,
  }),
  exit: {
    height: 0,
    opacity: 0,
    transition: BASE_TRANSITION,
  },
}

export const slideInAnimation = {
  initial: {
    y: BASE_Y_SLIDE,
    opacity: 0,
    transition: BASE_TRANSITION,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: BASE_TRANSITION,
  },
  exit: {
    y: BASE_Y_SLIDE,
    opacity: 0,
    transition: BASE_TRANSITION,
  },
}

export const delayedFadeInAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: BASE_DISPLAY_DURATION } },
  exit: { opacity: 0 },
}

/**
 * Controls the trousers category thumbnail
 * @param {Boolean} isHorizontalScroll - interface scroll direction
 * @returns {Object} - animation object
 */
export const hideTrousersCategory = (isHorizontalScroll) => {
  return {
    toSuitsMode: {
      ...(!isHorizontalScroll ? { height: '0px' } : { width: '0px' }),
      opacity: 0,
      // opacity: [1, !isHorizontalScroll ? 0.5 : 0.9, 0],
      ...(isHorizontalScroll
        ? { marginLeft: '-10px' }
        : { marginTop: '-10px' }),
      transitionEnd: {
        pointerEvents: 'none',
      },
      transition: { BASE_TRANSITION, ...{ duration: BASE_TIME_ALT } },
    },
    toSeperatesMode: {
      ...(!isHorizontalScroll ? { height: '100%' } : { width: '100%' }),
      opacity: 1,
      // opacity: [0, !isHorizontalScroll ? 0.5 : 0.9, 1],
      marginLeft: '0px',
      marginTop: '0px',
      transitionEnd: {
        pointerEvents: 'auto',
      },
      transition: { BASE_TRANSITION, ...{ duration: BASE_TIME_ALT } },
    },
  }
}

/**
 * Controls the trousers image in the category thumbnail
 * @param {Boolean} isHorizontalScroll - interface scroll direction
 * @todo - handle the width of the animated image container, it should shrink the size to 0px to allow centering the jacket image
 * @returns {Object} - animation object
 */
export const counterpartAnimation = (isHorizontalScroll) => {
  return {
    toSuitsMode: {
      x: '0%',
      y: '0%',
      // display: 'block',
      // width: '70px',
      scale: 1,
      opacity: 1,
      transition: {
        BASE_TRANSITION,
        ...{ duration: BASE_TIME_ALT, delay: 0.15 },
      },
      // transitionEnd: {
      //   width: 'auto',
      // },
    },
    toSeperatesMode: {
      x: isHorizontalScroll ? '120%' : '-60%',
      y: isHorizontalScroll ? '10%' : '80%',
      // display: 'content',
      // width: isHorizontalScroll ? '100%' : '0px',
      scale: 1.5,
      opacity: 0,
      transition: { BASE_TRANSITION, ...{ duration: BASE_TIME_ALT } },

      transitionEnd: {
        // width: 'auto',
        // display: 'none',
      },
    },
  }
}

/**
 * Controls the jacket image in the category thumbnail
 * @param {Boolean} isHorizontalScroll - interface scroll direction
 * @returns {Object} - animation object
 */
export const shrinkJacketsCategory = (isHorizontalScroll) => {
  return {
    toSuitsMode: {
      x: '0px',
      // transition: BASE_TRANSITION,
      transition: {
        duration: 2,
      },
      opacity: 1,
      scale: 1,
    },
    toSeperatesMode: {
      x: '0',
      // transition: BASE_TRANSITION,
      transition: {
        duration: 2,
      },
      opacity: 0.5,
      scale: 1,
    },
  }
}

/**
 * Generates the animations object for KOLManel component.
 *
 * @param {Object} options - The options for generating the animations object.
 * @param {boolean} options.isHorizontalScroll - Specifies whether the animation is horizontal scroll or not. Default is false.
 * @param {boolean} options.isOpen - Specifies whether the panel is open or not. Default is false.
 * @returns {Object} - The animations object.
 */
export const KOLPanelAnimations = ({
  isHorizontalScroll = false,
  isOpen = false,
}) => {
  return {
    initial: isHorizontalScroll
      ? {
          bottom: isOpen ? '0%' : '-100%',
        }
      : {
          right: isOpen ? '0%' : '-100%',
        },
    animate: isHorizontalScroll
      ? {
          bottom: isOpen ? '0%' : '-100%',
        }
      : {
          right: isOpen ? '0%' : '-100%',
        },
    exit: isHorizontalScroll
      ? {
          bottom: '-100%',
        }
      : {
          right: '-100%',
        },
    transitions: {
      duration: BASE_DURATION,
      type: 'tween',
      ease: transitions.general.js,
    },
  }
}
