import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import {
  colors,
  elementFocus,
  mediaQueries,
  interfaceSize,
  fadeInAnimation,
} from 'styles'

import { useAtom } from 'jotai'

import Button from 'components/Button'
import Loader from 'components/Loader'

import { translateStringKey } from 'utils'
import { useCurrentProducts } from 'hooks/useCurrentProducts'
import { MANAGEMENT_API_SUBMIT } from 'utils/endpoints'
import { useContext, useEffect, useState } from 'preact/hooks'
import {
  AuthContext,
  AppModeAtom,
  AppScope,
  Modes,
  GlobalProductsContext,
} from 'contexts'
import {
  SEPERATES_MODE,
  SUITS_MODE,
  AUTHORIZATION_PARAM,
  LS_MODEL_SELECTION,
  FALLBACK_MODEL,
  DATA_SOURCE_PARAM,
  LOCALE_PARAM_STRING,
  ENVIRONMENT_OPTIONS,
  STANDALONE_ENVIRONMENTS,
} from 'data'

import Icon from '../Icon/Icon'

const Container = styled(motion.div)`
  position: absolute;
  top: 16px;
  right: 12px;

  transform: ${({ $isModelLayer }) => $isModelLayer && `translateY(-15px)`};
  transition: transform 0.35s ease;

  width: max-content;

  ${mediaQueries.s} {
    top: 20px;
    right: unset;
    bottom: unset;
    left: 20px;
  }

  ${mediaQueries.m} {
    top: 22px;
    right: unset;
    bottom: unset;
    left: 22px;
  }
`

const StyledButton = styled(Button)`
  height: 34px;
  padding: 6px 15px 6px 15px;
  border-radius: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 3px;

  overflow: hidden;
  position: relative;

  max-width: 6rem;
  min-width: 6rem;

  background-color: ${colors.white};
  color: ${colors.black};

  svg[data-error='true'] {
    margin-top: unset;
  }

  &:hover {
    svg path {
      fill: ${colors.white};
    }
  }

  ${elementFocus}

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    margin-top: unset;
    outline: none;

    span {
      color: white;
    }
  }

  span {
    color: black;
  }

  [disabled] {
    pointer-events: none;
  }

  ${mediaQueries.s} {
    height: 40px;
    padding: 11px 20px 11px 20px;
  }
`

const StyledLoader = styled(Loader)`
  width: 5em;
`

const StyledSpan = styled(motion.span)`
  font-size: 14px;
`

const StyledIcon = styled(Icon)`
  width: 1.6em;
  height: 1.6em;

  ${({ $hasError }) => {
    return `margin-top: ${$hasError ? 'unset' : '-2px'}`
  }}
`

const SUBMIT_STATE = {
  POSTING: 'POSTING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

const ICONS = {
  SUCCESS: 'Check',
  FAILURE: 'Cross',
  POSTING: null,
}

const STATE_LABELS = {
  POSTING: '',
  SUCCESS: 'Sent',
  FAILURE: 'Error',
}

const MotionDiv = styled(motion.div)`
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.5ch;
`

/**
 * Extracts atoms and uses them
 * @param {String} mode - app mode
 * @param {Array} globalProductSet - global product set
 * @returns {Object} outfit and url
 */
const extractAtomsAndUse = (mode, globalProductSet) => {
  const atoms = [
    'JacketsAtom',
    'ShirtsAtom',
    'TrousersAtom',
    'ShoesAtom',
    'CoatsAtom',
  ]
    .map((atom) => Modes[mode][atom])
    .map((atom) => {
      const [value] = useAtom(atom)
      return value
    })

  let products = []

  if (mode === SUITS_MODE) {
    const suitJackets = atoms[0]
    const product = globalProductSet.find((e) => suitJackets.parentId === e.id)
    if (product) products.push(product)

    // Add shirt, shoes, and coat to products
    products.push(atoms[1], atoms[3])

    // add coat to products if it is not a fallback
    if (!atoms[4]?.isFallback) products.push(atoms[4])
  } else if (mode === SEPERATES_MODE) {
    // Add shirt, trousers, shoes, and coat to products
    products.push(atoms[1], atoms[2], atoms[3])

    // Add jacket and coat to products if they are not fallbacks
    if (!atoms[0]?.isFallback) products.push(atoms[0])
    if (!atoms[4]?.isFallback) products.push(atoms[4])
  }

  const outfit = products.map((product) => product.id)
  const outfitAsString = outfit.join(',')
  const searchParams = new URLSearchParams(window.location.search)
  const datasourceParamValue = searchParams.get(DATA_SOURCE_PARAM)
  const datasourceLocaleValue = searchParams.get(LOCALE_PARAM_STRING)
  const origin =
    STANDALONE_ENVIRONMENTS[process.env.ENVIRONMENT] ||
    STANDALONE_ENVIRONMENTS[ENVIRONMENT_OPTIONS.PRODUCTION]

  const url = `${origin}?ViewMode=${mode}&${mode}=${outfitAsString}&${AUTHORIZATION_PARAM}=true${
    datasourceParamValue && datasourceLocaleValue
      ? `&${DATA_SOURCE_PARAM}=${datasourceParamValue}&${LOCALE_PARAM_STRING}=${datasourceLocaleValue}`
      : ''
  }`

  return { outfit, url }
}

const KOLbutton = ({ ...props }) => {
  const { account, accessToken, refreshToken } = useContext(AuthContext)
  const { globalProductSet } = useContext(GlobalProductsContext)

  if (!account?.idTokenClaims || !accessToken) return null

  const nextStepLabel = translateStringKey('Submit', 'en')
  const [modalState, setModalState] = useState()
  const [submittedProducts, setSubmittedProducts] = useState()

  const [appMode] = useAtom(AppModeAtom, AppScope)
  const { url } = extractAtomsAndUse(appMode, globalProductSet)

  const currentProducts = useCurrentProducts()

  const isPosting = modalState === SUBMIT_STATE.POSTING

  const postOutfit = async (postValues, currentToken) => {
    setModalState(SUBMIT_STATE.POSTING)

    const response = await fetch(MANAGEMENT_API_SUBMIT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentToken || accessToken}`,
      },
      body: JSON.stringify(postValues),
    })

    if (response.status === 201) {
      setModalState(SUBMIT_STATE.SUCCESS)
      setSubmittedProducts(currentProducts)
    } else {
      setModalState(SUBMIT_STATE.FAILURE)
    }
  }

  useEffect(() => {
    if (modalState !== SUBMIT_STATE.SUCCESS) return

    if (JSON.stringify(submittedProducts) !== JSON.stringify(currentProducts)) {
      setModalState(null)
    }
  }, [currentProducts, submittedProducts])

  const handleKOL = async () => {
    const currentModel =
      window.localStorage.getItem(LS_MODEL_SELECTION) || FALLBACK_MODEL
    const currentUserName = account?.name

    const expirationDate = new Date(account?.idTokenClaims?.exp * 1000)
    const currentTime = new Date()
    let currentToken = accessToken

    if (currentTime > expirationDate) {
      currentToken = await refreshToken()
    }

    /**
     * @source https://lookbuilder-management-tst-af.azurewebsites.net/api/swagger/ui#/
     */
    const postValues = {
      createdBy: currentUserName,
      model: currentModel,
      products: [...new Set(currentProducts.map((e) => e.id))],
      viewMode: appMode,
      url,
    }

    postOutfit(postValues, currentToken)
  }

  return (
    <Container
      {...fadeInAnimation}
      {...props}
    >
      <StyledButton
        onClick={handleKOL}
        tabIndex={0}
        disabled={Boolean(modalState)}
      >
        {!isPosting && (
          <MotionDiv {...fadeInAnimation}>
            {modalState && (
              <StyledIcon
                icon={ICONS[SUBMIT_STATE[modalState]]}
                width={29}
                height={28}
                $hasError={modalState === SUBMIT_STATE.FAILURE}
              />
            )}
            <StyledSpan key="shop-look">
              {STATE_LABELS[SUBMIT_STATE[modalState]] || nextStepLabel}
            </StyledSpan>
          </MotionDiv>
        )}

        {isPosting && (
          <MotionDiv {...fadeInAnimation}>
            <StyledLoader />
          </MotionDiv>
        )}
      </StyledButton>
    </Container>
  )
}

export default KOLbutton
