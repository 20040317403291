import { useAtom } from 'jotai'
import { useEffect } from 'preact/hooks'
import {
  ProductsAtom,
  ThumbSizeAtom,
  ClothSizeAtom,
  DataAtom,
  AcceptSelection,
  AppScope,
  TimeOutTime,
} from 'contexts'
import useWorkers from 'hooks/useWorkers'

import { MODEL_SET } from 'data'

const DataProvider = ({ children, globalData }) => {
  const data = globalData
  const [products] = useAtom(ProductsAtom)
  const [, setData] = useAtom(DataAtom)
  const [thumbImageSize] = useAtom(ThumbSizeAtom)
  const [clothImageSize] = useAtom(ClothSizeAtom)
  const [acceptSelection] = useAtom(AcceptSelection)
  const [timeoutTime] = useAtom(TimeOutTime, AppScope)

  useEffect(() => {
    if (!data) return
    products.map((el, index) => {
      const currItem = data[index].filter((product) => product.id === el.id)
      const currIndex = data[index].findIndex((product) => product.id === el.id)
      data[index].splice(currIndex, 1)
      data[index].splice(data[index].length / 2, 0, ...currItem)
    })
  }, [data, acceptSelection])

  useEffect(() => setData([...data, MODEL_SET]), [])

  useWorkers(thumbImageSize, clothImageSize, data, timeoutTime)

  return <>{children}</>
}

export default DataProvider
