import { getJourney, formatProductData } from 'utils'
import {
  TOP_LAYER,
  BOTTOM_LAYER,
  TEMP_ARRAY_LENGTH,
  PLACEHOLDER_PRODUCT,
  FUNCTIONAL_SORT_OBJECT,
} from 'data'
import {
  getDefaultProducts,
  getDefaultSuitProducts,
  getFormattedOCAPIData,
  getInitialProducts,
  getInitialSuitProducts,
  getFormattedStoreProductData,
} from './fetchFunctions'

export const fetcherProductToMiddle = async (url, ref, category, pid) => {
  const res = await fetch(url, { method: 'GET' })
  const data = await res.json()
  const currItem = data?.filter((el) => el.product_id === pid)
  const currIndex = data?.findIndex((el) => el.product_id === pid)
  data?.splice(currIndex, 1)
  data?.splice(data.length / 2, 0, ...currItem)
  ref.current.set(category, data)
}

const HEADERS = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
}

export const fetcherGroupProducts = async (
  groupProductsURL,
  productMatrixURL,
  firstProducts,
  firstSuitProducts
) => {
  const firstProductsMap = firstProducts?.map((e) => e.id) || []

  const dataPromise = fetch(groupProductsURL, HEADERS).then((res) => res.json())
  const productMatrixPromise = fetch(productMatrixURL, HEADERS).then((res) =>
    res.json()
  )

  const [productMatrix, data] = await Promise.all([
    productMatrixPromise,
    dataPromise,
  ])

  const { LAYER_VERSIONS, PRODUCT_TYPE, PRODUCT_TYPE_IDS } = productMatrix

  const tempArray = Array.from({ length: TEMP_ARRAY_LENGTH }, () => [])
  const invalidProducts = []

  // filter out invalid products
  const sanitizedData = data
    .filter((product) => {
      if (!PRODUCT_TYPE_IDS[product.c_onlineProductTypeIDCode]) {
        // maintain a list in case of future debugging
        invalidProducts.push({
          id: product.id,
          c_onlineProductTypeID: product.c_onlineProductTypeID,
        })
        return
      }
      return product
    })
    .map((product) => {
      const isNestedInMixAndMatch =
        product?.set_products &&
        product?.set_products.find((e) => e.primary_category_id === 'Suits')
          ? product
          : null

      if (isNestedInMixAndMatch) {
        const suitProduct = product?.set_products.find(
          (e) => e.primary_category_id === 'Suits'
        )

        const accessories = product?.set_products
          .map((e) => {
            const baseProduct = formatProductData({
              product: e,
              PRODUCT_TYPE_IDS,
            })

            return baseProduct
          })
          .filter((e) => e.id !== suitProduct.id)

        const ok = {
          ...suitProduct,
          setReference: isNestedInMixAndMatch.id,
          accessories,
        }

        return ok
      } else {
        return product
      }
    })

  sanitizedData.map((product, index) => {
    if (!PRODUCT_TYPE_IDS[product.c_onlineProductTypeIDCode]) return

    const layer = getJourney(
      PRODUCT_TYPE_IDS[product.c_onlineProductTypeIDCode]
    )

    if (!layer) return

    if (product?.set_products) {
      product.set_products.map((setProduct) => {
        const baseProduct = formatProductData({
          product: setProduct,
          PRODUCT_TYPE_IDS,
        })

        if (![TOP_LAYER, BOTTOM_LAYER].includes(baseProduct.layerName)) return

        const layer = getJourney(PRODUCT_TYPE[baseProduct.onlineProductType])
        const pushable = {
          position: index,
          parentId: product.id,
          ...baseProduct,
        }

        tempArray[layer.index].push(pushable)
      })
      return
    }

    const baseProduct = formatProductData({
      product,
      PRODUCT_TYPE_IDS,
    })

    const existsInBatch = tempArray[layer.index].find(
      (el) => el.id === baseProduct.id
    )

    if (!existsInBatch)
      tempArray[layer.index].push({ position: index, ...baseProduct })
  })

  tempArray[0].version = LAYER_VERSIONS[tempArray[1].onlineProductType]

  // map over the batches, find empty batch, and set a placeholder for the appto pick up in ProductCategory
  tempArray.map((batch, index) => {
    const placeholderProps = {
      layerName: FUNCTIONAL_SORT_OBJECT[index],
      isPlaceholder: true,
    }

    if (index === 0) {
      placeholderProps.parent = {
        ...PLACEHOLDER_PRODUCT,
        setProducts: [{ layerName: BOTTOM_LAYER, parent: PLACEHOLDER_PRODUCT }],
      }
    }

    if (Object.values(batch).length < 1) {
      batch.push({
        ...PLACEHOLDER_PRODUCT,
        ...placeholderProps,
      })
    }
  })

  const OCAPIData = getFormattedOCAPIData(sanitizedData, PRODUCT_TYPE_IDS)

  // flatten nested sets, inject in tempArray
  OCAPIData.map(
    (e) =>
      e?.isNestedSuit &&
      e.setProducts.map((setProduct, index) => {
        const layer = getJourney(PRODUCT_TYPE[setProduct.onlineProductType])

        tempArray[layer.index].push(setProduct)
      })
  )

  // temp fix? handle prodycts with incorrect layername; map over product matrix
  firstProducts =
    firstProducts?.map((product) => ({
      id: product.id,
      layer:
        PRODUCT_TYPE[
          sanitizedData.find((el) => el.id === product.id)
            ?.c_onlineProductTypeID || product.layer
        ],
    })) || []

  // products that are set as default, defaults to 0nd of category batch.
  const defaultProducts = getDefaultProducts(tempArray, firstProducts)

  // products that are set as default, defaults to 0nd of category batch.
  const defaultSuitProducts = getDefaultSuitProducts(
    tempArray,
    firstSuitProducts,
    OCAPIData
  )

  // products that are queried through url, defaults to product from defaultProducts for that specific layer.
  const initialProducts = getInitialProducts(
    tempArray,
    firstProducts,
    firstProductsMap,
    defaultProducts
  )?.flat()

  const initialSuitProducts = getInitialSuitProducts(
    tempArray,
    firstSuitProducts,
    defaultSuitProducts,
    OCAPIData
  )?.flat()

  const dataset = {
    initialProducts,
    initialSuitProducts,
    defaultProducts,
    defaultSuitProducts,
    data: tempArray,
    OCAPIData,
    productMatrix,
    separatesInJSON: firstProductsMap.length > 0,
    suitsInJSON: firstSuitProducts.length > 0,
  }

  return dataset
}

export const fetchStoreProducts = async (url, productMatrixURL) => {
  const tempArray = Array.from({ length: TEMP_ARRAY_LENGTH }, () => [])

  const dataPromise = fetch(url).then((res) => res.json())
  const productMatrixPromise = fetch(productMatrixURL, HEADERS).then((res) =>
    res.json()
  )

  const [productMatrix, data] = await Promise.all([
    productMatrixPromise,
    dataPromise,
  ])

  const { PRODUCT_TYPE_IDS } = productMatrix

  const normalizedData = getFormattedStoreProductData(data, PRODUCT_TYPE_IDS)

  normalizedData.map((product, index) => {
    if (!PRODUCT_TYPE_IDS[product.onlineProductTypeCode]) return

    const layer = getJourney(PRODUCT_TYPE_IDS[product.onlineProductTypeCode])
    layer && tempArray[layer.index].push({ position: index, ...product })
  })

  // map over the batches, find empty batch, and set a placeholder for the appto pick up in ProductCategory
  tempArray.map((batch, index) => {
    const placeholderProps = {
      layerName: FUNCTIONAL_SORT_OBJECT[index],
      isPlaceholder: true,
    }

    if (index === 0) {
      placeholderProps.parent = {
        ...PLACEHOLDER_PRODUCT,
        setProducts: [{ layerName: BOTTOM_LAYER, parent: PLACEHOLDER_PRODUCT }],
      }
    }

    if (Object.values(batch).length < 1) {
      batch.push({
        ...PLACEHOLDER_PRODUCT,
        ...placeholderProps,
      })
    }
  })

  const defaultProducts = getDefaultProducts(tempArray, normalizedData, true)

  const defaultSuitProducts = getDefaultSuitProducts(
    tempArray,
    [],
    normalizedData
  )

  const dataset = {
    initialProducts: defaultProducts,
    initialSuitProducts: defaultProducts,
    defaultProducts,
    defaultSuitProducts,
    data: tempArray,
    OCAPIData: normalizedData,
    productMatrix,
  }

  return dataset
}
