import styled from 'styled-components'
import { motion } from 'framer-motion'
import { forwardRef, useEffect, useRef, useState } from 'preact/compat'

import { CDN_URL, capitalize, REGEX_END_LETTER } from 'utils'
import { DEVICE_SIZES, IMAGE_SIZES, MODEL_IMAGES_SIZES } from 'styles'
import { FALLBACK_UNDERSCORE } from 'data'

const LAST_IMAGE = IMAGE_SIZES[DEVICE_SIZES.length - 1]

const createModelClothUrl = (size, category, id, version, media) => {
  const imageSize = Math.floor(size * 1.3)

  if (category === 'ctc_models') {
    return `${CDN_URL},w_${imageSize}/products/${category}/${
      id.split(REGEX_END_LETTER)[0]
    }.png${media ? ` ${media}w,` : ''}`
  }
  return `${CDN_URL},w_${imageSize}/products/${capitalize(category)}/default/${
    id.split(REGEX_END_LETTER)[0]
  }_${version}.png${media ? ` ${media}w,` : ''}`
}

const createSrcModelClothUrl = (category, id, version) => {
  let string = ''

  DEVICE_SIZES.forEach((size, i) => {
    string += createModelClothUrl(
      MODEL_IMAGES_SIZES[i],
      category,
      id,
      version,
      size
    )
  })

  return string
}

// Create a srcSet for the greyed out cloth image fallback
const createSrcFallbackModelClothUrl = (layerName) => {
  const fallbackPrefix =
    'https://testing.lookbuilder.suitsupply.com/assets/placeholders/'
  let string = ''

  DEVICE_SIZES.forEach((size) => {
    string += `${fallbackPrefix}Model_${layerName}.png ${size}w,`
  })

  // Remove the trailing comma
  return string.slice(0, -1)
}

const PictureWrapper = styled(motion.picture)`
  display: flex;
  align-items: ${(props) =>
    props.variant === 'select' ? 'flex-end' : 'center'};
  justify-content: center;
  overflow: ${(props) => props.variant === 'select' && 'hidden'};
  border-radius: ${(props) => props.variant === 'select' && '4px'};

  position: relative;

  filter: ${({ $isFallback }) => $isFallback && 'brightness(.8)'};
`

const ModelHands = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
`

const Image = forwardRef(
  (
    {
      category,
      id,
      alt,
      onLoad,
      onError,
      layerName,
      variant,
      version = FALLBACK_UNDERSCORE,
      className,
    },
    ref
  ) => {
    const [currentId, setCurrentId] = useState(id)

    const [modelHandsUrl, setModelHandsUrl] = useState()
    const [srcModelHandsUrl, setSrcModelHandsUrl] = useState()

    const [modelClothUrl, setModelClothUrl] = useState()
    const [srcModelClothUrl, setSrcModelClothUrl] = useState()
    const [isFallback, setIsFallback] = useState()

    const isModelImage = useRef(category === 'ctc_models')

    const validateImageSource = async (url) => {
      const res = await fetch(url, { method: 'HEAD' })
        .then((res) => res.status === 200)
        .catch(() => false)
      return res
    }

    const validateImage = async ({ category, currentId, version }) => {
      /**
       * Validate the initial image, if it is valid return the image url
       */
      const initialModelCloth = createModelClothUrl(
        LAST_IMAGE,
        category,
        currentId,
        version
      )

      const clothUrlIsValid = await validateImageSource(initialModelCloth)

      const initialModelClothSrcSet = createSrcModelClothUrl(
        category,
        currentId,
        version
      )

      if (clothUrlIsValid) {
        return {
          src: initialModelCloth,
          srcSet: initialModelClothSrcSet,
          isFallback: false,
        }
      }

      /**
       * If the initial image is not valid, we need to check if the fallback image is valid.
       */
      const modelClothFallback = createModelClothUrl(
        LAST_IMAGE,
        category,
        currentId,
        FALLBACK_UNDERSCORE
      )

      const clothUrlIsValidFallback = await validateImageSource(
        modelClothFallback
      )

      const modelClothFallbackSrcSet = createSrcModelClothUrl(
        category,
        currentId,
        FALLBACK_UNDERSCORE
      )

      if (clothUrlIsValidFallback) {
        return {
          src: modelClothFallback,
          srcSet: modelClothFallbackSrcSet,
          isFallback: false,
        }
      }

      /**
       * Assume the image is invalid if it fails both checks, return the greyed out image.
       */
      const fallbackPrefix =
        'https://testing.lookbuilder.suitsupply.com/assets/placeholders/'

      const fallbackUrl = `${fallbackPrefix}Model_${layerName}.png`

      const fallbackUrlSrcSet = createSrcFallbackModelClothUrl(layerName)

      return { src: fallbackUrl, srcSet: fallbackUrlSrcSet, isFallback: true }
    }

    // Define the async function outside the useEffect
    const fetchModelCloth = async () => {
      if (['ctc_models'].includes(category)) return

      const {
        src: modelCloth,
        srcSet: modelClothSrcSet,
        isFallback,
      } = await validateImage({
        category,
        currentId,
        version: version,
      })

      setModelClothUrl(modelCloth)
      setSrcModelClothUrl(modelClothSrcSet)
      setIsFallback(isFallback)
    }

    // handle product images
    useEffect(() => {
      fetchModelCloth()
    }, [currentId, version])

    // handle model images
    useEffect(() => {
      if (!['ctc_models'].includes(category)) return

      const modelCloth = createModelClothUrl(
        LAST_IMAGE,
        category,
        currentId,
        version
      )

      const modelClothSrcSet = createSrcModelClothUrl(
        category,
        currentId,
        version
      )

      setModelClothUrl(modelCloth)
      setSrcModelClothUrl(modelClothSrcSet)

      setModelHandsUrl(
        createModelClothUrl(
          LAST_IMAGE,
          'ctc_models',
          currentId.replace('full', 'hands'),
          version
        )
      )

      setSrcModelHandsUrl(
        createSrcModelClothUrl(
          'ctc_models',
          currentId.replace('full', 'hands'),
          version
        )
      )
    }, [currentId])

    useEffect(() => {
      setCurrentId(id)
    }, [id])

    return (
      <PictureWrapper
        variant={variant}
        className={className}
        $isModelImage={isModelImage.current}
        $isFallback={isFallback}
      >
        <img
          ref={ref}
          alt={alt}
          src={modelClothUrl}
          srcSet={srcModelClothUrl}
          onLoad={onLoad}
          onError={onError}
        />

        {isModelImage.current && (
          <ModelHands
            src={modelHandsUrl}
            srcSet={srcModelHandsUrl}
          />
        )}
      </PictureWrapper>
    )
  }
)

export default Image
