import { useContext } from 'preact/hooks'
import { forwardRef } from 'preact/compat'

import { itemAnimation } from 'styles'
import { AuthContext, HorizontalScrollContext } from 'contexts'

import {
  Inner,
  Container,
  Description,
  Name,
  Material,
  Price,
  Divider,
  MetaDataContainer,
  MetaData,
} from './ProductDescription.styles'

const ProductDescription = ({
  index,
  dataLength,
  productData,
  priceFormat,
  animationTrigger,
  setItemRefs = () => {},
  currencySign,
}) => {
  const authContext = useContext(AuthContext)
  const isKOL = Boolean(authContext?.account)

  const { price, name, material, productId } = productData
  const priceLabel = currencySign
    ? `${currencySign}${price}`
    : priceFormat.replace('[price]', price)
  const isHorizontalScroll = useContext(HorizontalScrollContext)

  const getVariantsByIndex = (index) => {
    if (typeof index === 'undefined' || typeof dataLength === 'undefined')
      return itemAnimation

    const halfData = Math.round(dataLength / 2)
    const margin = 10
    if (dataLength <= 20) return itemAnimation
    if (index > halfData - margin && index < halfData + margin)
      return itemAnimation
  }

  return (
    <Inner
      variants={getVariantsByIndex(index)}
      custom={isHorizontalScroll}
    >
      <Container
        ref={(ref) => setItemRefs(ref, index)}
        exit={{ height: 0, opacity: 0 }}
        initial={{ height: 0, opacity: 0 }}
        animate={
          animationTrigger
            ? { height: 'auto', opacity: 1 }
            : { height: 0, opacity: 0 }
        }
        transition={{
          type: 'tween',
        }}
      >
        <Description>
          <Name colSpan={3}>{name}</Name>

          <MetaDataContainer>
            <MetaData $shortened={priceLabel.length >= 6}>
              <Material $shortened={priceLabel.length >= 6}>
                {material}
              </Material>
              <Divider>{`• `}</Divider>
            </MetaData>
            <Price $length={priceLabel.length}>{`${
              isKOL ? productId : priceLabel
            }`}</Price>
          </MetaDataContainer>
        </Description>
      </Container>
    </Inner>
  )
}

const ProductDescriptionWithRef = (props, ref) => (
  <ProductDescription
    {...props}
    forwardedRef={ref}
  />
)

export default forwardRef(ProductDescriptionWithRef)
