import { useEffect, useRef } from 'preact/hooks'
import { delay } from 'utils'

const useHeightSize = () => {
  useEffect(() => {
    const onResize = async () => {
      await delay(100)
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    }

    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [])
}

export default useHeightSize
