import { useAtom } from 'jotai'
import { InterfaceViewAtom } from 'contexts'

import {
  Container,
  StyledIcon,
  StyledHeader,
  StyledDescription,
} from './EmptyCategory.styles'

import { fadeInAnimation } from 'styles'
import { AnimatePresence } from 'framer-motion'
import { translateStringKey, extractLocaleString } from 'utils'

const EmptyCategory = ({ locales }) => {
  const [, setCurrentView] = useAtom(InterfaceViewAtom)

  const locale = extractLocaleString(locales)
  const headerString = translateStringKey('empty-category', locale)
  const descriptionString = translateStringKey('back-soon', locale)

  const onClose = () => {
    setCurrentView(undefined)
  }

  return (
    <AnimatePresence>
      <Container {...fadeInAnimation}>
        <StyledIcon
          aira-label="Close"
          icon="Cross"
          onClick={onClose}
        />

        <StyledHeader>{headerString}</StyledHeader>
        <StyledDescription>{descriptionString}</StyledDescription>
      </Container>
    </AnimatePresence>
  )
}

export default EmptyCategory
