import useRefs from 'hooks/useRefs'
import styled from 'styled-components'
import { forwardRef } from 'preact/compat'
import { useElementScroll } from 'framer-motion'
import { useRef, useContext } from 'preact/hooks'

import { ScrollContext, HorizontalScrollContext } from 'contexts'
import { mediaQueries, hideScrollbars, interfaceSize } from 'styles'

const ScrollContainer = styled.div`
  width: 100%;

  overflow-x: auto;

  ${mediaQueries.m} {
    overflow-y: unset;
  }

  ${mediaQueries.m} {
    margin: 0 auto;

    position: relative;
    top: ${({ $canReset }) => ($canReset ? '10px' : '0')};
    right: unset;

    height: max-content;

    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
  }

  ${hideScrollbars}
`

const Scroll = ({ canReset = false, forwardedRef, children, ...props }) => {
  const element = useRef()
  const outerRef = useRefs(forwardedRef, element)
  const { scrollX, scrollY } = useElementScroll(element)
  const isHorizontalScroll = useContext(HorizontalScrollContext)
  const scrollEvent = isHorizontalScroll ? scrollX : scrollY
  return (
    <ScrollContext.Provider value={{ element, scrollEvent }}>
      <ScrollContainer
        $canReset={canReset}
        ref={outerRef}
        {...props}
      >
        {children}
      </ScrollContainer>
    </ScrollContext.Provider>
  )
}

const ScrollWithRef = (props, ref) => (
  <Scroll
    forwardedRef={ref}
    {...props}
  />
)

export default forwardRef(ScrollWithRef)
