import Back from 'assets/svg/back.svg'
import Cancel from 'assets/svg/cancel.svg'
import Accept from 'assets/svg/accept.svg'
import Prohibited from 'assets/svg/prohibited.svg'
import ZoomIn from 'assets/svg/zoomIn.svg'
import ZoomOut from 'assets/svg/zoomOut.svg'
import Plus from 'assets/svg/plus.svg'
import ToggleMode from 'assets/svg/toggleMode.svg'
import Cross from 'assets/svg/cross.svg'
import Check from 'assets/svg/check.svg'

const ICON_COMPONENTS = {
  Back,
  Cancel,
  Accept,
  Prohibited,
  ZoomIn,
  ZoomOut,
  Plus,
  ToggleMode,
  Cross,
  Check,
}

const Icon = ({ icon, ...props }) => {
  if (!icon) return null

  const parsedIcon = icon.charAt(0).toUpperCase() + icon.slice(1)
  const Component = ICON_COMPONENTS[parsedIcon]
  return <Component {...props} />
}

export default Icon
