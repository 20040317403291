import styled from 'styled-components'
import { motion } from 'framer-motion'
import Icon from '../Icon/Icon'

import {
  colors,
  elementFocus,
  mediaQueries,
  hideScrollbars,
  categoryThumbSize,
  interfaceSize,
} from 'styles'

export const Container = styled(motion.div)`
  padding: 10px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: ${interfaceSize.mobile}px;

  ${mediaQueries.s} {
    height: ${interfaceSize.tablet}px;
  }
  ${mediaQueries.m} {
    height: 100%;
    width: ${interfaceSize.desktop}px;
  }
`
export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }
`

export const StyledHeader = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${mediaQueries.m} {
    font-size: 20px;
    line-height: 28px;
  }
`

export const StyledDescription = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  ${mediaQueries.m} {
    font-size: 16px;
    line-height: 24px;
  }
`
