import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { useSpring } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'

import { customEase } from 'styles'
import { breakpoints } from 'styles/mediaQueries'

import { HorizontalScrollContext } from 'contexts'

const DEFAULT_SCALE = 1.8
const MODEL_SCALE = DEFAULT_SCALE * 0.85

const SCALE_CONSTRAINT_HARD = 2.25
const SCALE_CONSTRAINT_SOFT = SCALE_CONSTRAINT_HARD * 1.11

const useZoom = ({ resetOn, isModelLayer }) => {
  const isHorizontalScroll = useContext(HorizontalScrollContext)
  const modelRef = useRef()

  const [isZoomed, setZoomed] = useState()
  const modelBounds = useRef()
  const containerBounds = useRef()
  const scaleBounds = useRef()

  const isMobile = useRef()
  const scrollY = useRef(0)

  const BASE = isHorizontalScroll ? 3.75 : 3.5

  useEffect(() => {
    const handleResize = () =>
      (isMobile.current = window.innerWidth <= breakpoints.s)

    if (isMobile.current === undefined) handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const { height } = modelRef.current.getBoundingClientRect()

    const scaleBound = Math.abs(height / BASE)

    modelBounds.current = {
      scaleTop: -scaleBound,
      scaleBottom: scaleBound,
      top: -Math.abs(height / SCALE_CONSTRAINT_HARD),
      bottom: Math.abs(height / SCALE_CONSTRAINT_HARD),
      left: 0,
      right: 0,
    }
  }, [modelRef.current])

  const [style, api] = useSpring(() => {
    setZoomed(false)
    return {
      x: 0,
      y: isModelLayer ? modelBounds.current.scaleBottom : 0,
      scale: isModelLayer ? MODEL_SCALE : 1,
      transformOrigin: [0, 0],
    }
  }, [resetOn])

  useGesture(
    {
      onWheel: ({ delta: [, y] }) => {
        if (isMobile.current) return
        if (isModelLayer) return

        scrollY.current += -y
        if (isZoomed) {
          api.start({
            y: scrollY.current,
          })
        }
      },
      onClick: ({ event }) => {
        if (isMobile.current) return
        if (isModelLayer) return

        let targetY = 0
        const y = event.layerY

        const { height } = event.target.getBoundingClientRect()
        const middlePoint = height / 2
        const topRange = {
          start: 0,
          end: middlePoint,
          target: Math.abs(height / SCALE_CONSTRAINT_SOFT),
        }
        const bottomRange = {
          start: middlePoint,
          end: height,
          target: -Math.abs(height / SCALE_CONSTRAINT_SOFT),
        }

        containerBounds.current = {
          top: bottomRange.target,
          bottom: topRange.target,
          left: 0,
          right: 0,
        }

        const isTopSection = y >= topRange.start && y < topRange.end
        const isBottomSection = y >= bottomRange.start && y < bottomRange.end

        if (isTopSection) {
          targetY = topRange.target
        } else if (isBottomSection) {
          targetY = bottomRange.target
        }

        if (isZoomed) {
          api.start({
            scale: 1,
            x: 0,
            y: 0,
          })
        } else {
          api.start({
            scale: DEFAULT_SCALE,
            x: 0,
            y: targetY,
          })
        }
        setZoomed(!isZoomed)
        return
      },
      onDrag: ({ offset: [, y], pinching, cancel, tap }) => {
        if (tap) return cancel()
        if (pinching) return cancel()
        if (!isZoomed) return cancel()
        if (!isMobile.current) return cancel()

        // ref contains previous values to calculate constraint bounds
        scaleBounds.current = modelRef.current.getBoundingClientRect()

        return api.start({
          y,
        })
      },
      onPinch: ({
        origin: [ox, oy],
        da: [d],
        initial: [id],
        offset,
        lastOffset,
        memo,
      }) => {
        if (isModelLayer) return

        const {
          width,
          height,
          x: modelX,
          y: modelY,
        } = modelRef.current.getBoundingClientRect()

        const initialScale = style.scale.get()

        const tx = ox - (modelX + width / 2)
        const ty = oy - (modelY + height / 2)

        memo = [style.x.get(), style.y.get(), tx, ty, initialScale]

        // Calculate the new scale and position
        const ms = d / id
        const y = memo[1] - (ms - 1) * memo[3]

        const scaleTarget = memo[4] * ms

        if (offset[0] < lastOffset[0] && offset[1] < lastOffset[1]) {
          api.start({
            scale: 1,
            x: 0,
            y: 0,
          })
          setZoomed(false)
          return memo
        }

        // Prevent zooming out too far
        if (scaleTarget > DEFAULT_SCALE || scaleTarget <= 1) return memo

        api.start({
          scale: scaleTarget,
          transformOrigin: [0, oy],
          y,
        })

        setZoomed(true)
        scaleBounds.current = modelRef.current.getBoundingClientRect()
        return memo
      },
    },
    {
      target: modelRef.current,
      eventOptions: { passive: false },
      drag: {
        ease: customEase,
        filterTaps: true,
        from: () => [style.x.get(), style.y.get()],
        bounds: () => {
          const { height } =
            scaleBounds.current || modelRef.current.getBoundingClientRect()

          return {
            top: -Math.abs(height / (SCALE_CONSTRAINT_SOFT * 2)),
            bottom: Math.abs(height / (SCALE_CONSTRAINT_SOFT * 2)),
            left: 0,
            right: 0,
          }
        },
      },
      wheel: {
        ease: customEase,
        bounds: () => containerBounds.current,
      },
    }
  )

  return {
    modelRef,
    style,
    isZoomed,
    isModelLayer,
  }
}

export default useZoom
