import * as SDCCore from 'scandit-web-datacapture-core'
import * as SDCBarcode from 'scandit-web-datacapture-barcode'
import { useState, useEffect, useCallback, useRef } from 'react'

const LICENSE_KEY = process.env.SCANDIT_KEY
const LIBRARY_LOCATION =
  'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/'

const ENCODING_SUPPORT = [
  SDCBarcode.Symbology.EAN8,
  SDCBarcode.Symbology.EAN13UPCA,
]

const SDCCORE_CONFIG = {
  licenseKey: LICENSE_KEY,
  libraryLocation: LIBRARY_LOCATION,
  moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
}

const useScanner = () => {
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)
  const [isScanning, setIsScanning] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const scannerRef = useRef(null)

  const startScanner = useCallback(async () => {
    if (scannerRef.current) return

    try {
      await SDCCore.configure(SDCCORE_CONFIG)

      const context = await SDCCore.DataCaptureContext.create(LICENSE_KEY)
      const camera = SDCCore.Camera.default
      await context.setFrameSource(camera)

      const settings = new SDCBarcode.BarcodeCaptureSettings()
      settings.enableSymbologies(ENCODING_SUPPORT)
      settings.codeDuplicateFilter = 1000 // 1-second duplicate filter

      const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
        context,
        settings
      )

      // it's supposed to disable the sound feedback
      // @see https://docs.scandit.com/data-capture-sdk/web/barcode-capture/api/barcode-capture.html?highlight=sound#property-scandit.datacapture.barcode.BarcodeCapture.Feedback

      const feedback = SDCBarcode.BarcodeCaptureFeedback.default
      feedback.success = new SDCCore.Feedback(
        SDCCore.Vibration.defaultVibration,
        null
      )
      await barcodeCapture.setFeedback(feedback)

      barcodeCapture.addListener({
        didScan: async (_, session) => {
          if (isScanning) return
          setIsScanning(true)

          const barcode = session.newlyRecognizedBarcodes[0]
          if (barcode) {
            const ean = barcode.data
            setResult(ean)
            await barcodeCapture.setEnabled(false)
          }

          setIsScanning(false)
        },
      })

      const view = await SDCCore.DataCaptureView.forContext(context)
      const dataCaptureView = document.getElementById('data-capture-view')
      if (dataCaptureView) {
        view.connectToElement(dataCaptureView)
        const barcodeCaptureOverlay =
          await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
            barcodeCapture,
            view,
            SDCBarcode.BarcodeCaptureOverlayStyle.Frame
          )

        const viewfinder = new SDCCore.RectangularViewfinder(
          SDCCore.RectangularViewfinderStyle.Square,
          SDCCore.RectangularViewfinderLineStyle.Light
        )

        await barcodeCaptureOverlay.setViewfinder(viewfinder)
        setIsLoading(false)
      } else {
        throw new Error('Element with id "data-capture-view" not found')
      }

      await camera.switchToDesiredState(SDCCore.FrameSourceState.On)
      await barcodeCapture.setEnabled(true)

      scannerRef.current = {
        context,
        view,
        barcodeCapture,
        camera,
      }

      setIsScanning(true)
    } catch (err) {
      setError(err)
    }
  }, [isScanning])

  const stopScanner = useCallback(async () => {
    if (!scannerRef.current) return

    const { context, view, barcodeCapture, camera } = scannerRef.current

    try {
      await barcodeCapture.setEnabled(false)
      await camera.switchToDesiredState(SDCCore.FrameSourceState.Off)
      context.dispose()
      view.dispose()

      scannerRef.current = null
      setIsScanning(false)
      setResult(null)
      setError(null)
    } catch (err) {
      setError(err)
    }
  }, [])

  useEffect(() => {
    startScanner()
    return () => {
      stopScanner()
    }
  }, [stopScanner])

  return { result, error, isScanning, isLoading, startScanner, stopScanner }
}

export default useScanner
