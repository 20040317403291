import habitat from 'preact-habitat'
import AppProvider from './components/AppProvider'

let _habitat = habitat(AppProvider)

if (typeof window !== 'undefined') {
  _habitat.render({
    selector: '[js-susu-ctc-wrapper]',
    clean: true,
  })
}
