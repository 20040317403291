import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    --header-mobile: 40px;
    --header-tablet: 85px;
    --header-tablet-highres: 130px;
    --header: 55px;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button, [type=button] {
    color: inherit;
  }

  * {
    box-sizing: border-box;
  }

`

export const hideScrollbars = `
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const elementFocus = `
  &:focus, &:focus-within {
    margin-top: 4px;
    border: none;
    outline: 4px solid rgba(45, 46, 44, 0.3);
  }
`
