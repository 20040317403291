import { motion } from 'framer-motion'
import { useAtom } from 'jotai'
import styled from 'styled-components'
import { ScannedProductAtom, AppScope } from 'contexts'
import { colors } from 'styles'
import Image from 'components/Image'
import Icon from 'components/Icon'

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
`

const InnerContainer = styled(motion.div)`
  width: max-content;

  border-radius: 99px;
  overflow: hidden;

  padding: 4px;
  padding-right: 16px;
  background-color: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

const ImageContainer = styled.div`
  width: 4ch;
  height: 4ch;
  position: relative;

  border-radius: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.grey[100]};

  picture {
    padding: 0.2ch;
    height: 85%;
    width: 85%;
  }
`

const ProductName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`

const Toast = () => {
  const [scannedProduct] = useAtom(ScannedProductAtom, AppScope)
  if (!scannedProduct) return null

  const { isError, formattedProduct } = scannedProduct

  return (
    <Container
      initial={{ x: '-50%', y: '-300px', opacity: 0 }}
      animate={{ x: '-50%', y: '20px', opacity: 1 }}
      exit={{ x: '-50%', y: '-300px', opacity: 0 }}
      transition={{ type: 'tween' }}
    >
      <InnerContainer>
        <ImageContainer>
          {!isError && (
            <Image
              id={formattedProduct?.id}
              category={formattedProduct?.category}
              subCategory={formattedProduct?.subCategory}
              layerName={formattedProduct?.layerName}
            />
          )}
          {isError && <StyledIcon icon="Cross" />}
        </ImageContainer>

        <ProductName>
          {isError ? 'Product not available' : formattedProduct?.name}
        </ProductName>

        {!isError && <StyledIcon icon="Check" />}
      </InnerContainer>
    </Container>
  )
}

export default Toast
