import { useAtom } from 'jotai'
import { AnimatePresence } from 'framer-motion'
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'preact/hooks'

import Submit from 'components/Submit'
import ModelImage from 'components/ModelImage'

import useZoom from 'hooks/useZoom'

import {
  Modes,
  AppScope,
  AuthContext,
  isAppLoaded,
  AppModeAtom,
  ClothSizeAtom,
  LoadingImages,
  InterfaceViewAtom,
  ProductMatrixContext,
  GlobalProductsContext,
} from 'contexts'

import {
  SKIN_COLORS,
  COAT_LAYER,
  UNDERSCORES_STRINGS,
  WAISTCOAT_LAYER,
  EXCEPTION_REGEX,
} from 'data'

import {
  Component,
  Height,
  ModelGroup,
  TopLayer,
  MiddleLayer,
  CoatLayer,
  BottomLayer,
  ShoeLayer,
  HiddenImage,
  StyledIcon,
  WaistCoatLayer,
} from './Model.styles'
import KOLbutton from '../KOLbutton/KOLbutton'

const Model = ({
  submitUrl,
  controls,
  modelSkin,
  initialProducts,
  locales,
  trackingMetadata,
}) => {
  const { globalProductSet } = useContext(GlobalProductsContext)
  const {
    productMatrix: {
      TUCKED_LAYERS,
      UNDERSCORES_DATA,
      LAYER_VERSIONS,
      EXCLUDED_TYPES = [],
    },
  } = useContext(ProductMatrixContext)

  // app states
  const [appMode] = useAtom(AppModeAtom, AppScope)
  const [showsCategory] = useAtom(InterfaceViewAtom)
  const [isLoaded] = useAtom(isAppLoaded, AppScope)

  const authContext = useContext(AuthContext)
  const isKOL = Boolean(authContext?.account)

  // interaction states
  const [mousePos, setMousePos] = useState()
  const [isTucked, setIsTucked] = useState(false)

  // update app states
  const [, setImageLoad] = useAtom(LoadingImages, AppScope)
  const [, setClothSize] = useAtom(ClothSizeAtom)

  const isModelLayer = showsCategory === 'ModelSelection'

  // switch mode product sources
  const { JacketsAtom, ShirtsAtom, TrousersAtom, ShoesAtom, CoatsAtom } =
    Modes[appMode]

  // current products for the given app mode
  const [jacketRef, setJacketRef] = useAtom(JacketsAtom)
  const [shirtRef, setShirtRef] = useAtom(ShirtsAtom)
  const [trousersRef, setTrouserRef] = useAtom(TrousersAtom)
  const [shoesRef, setShoeRef] = useAtom(ShoesAtom)
  const [coatsRef, setCoatsRef] = useAtom(CoatsAtom)
  const [waistcoatRef, setWaistcoatRef] = useState()

  const imageRef = useRef()
  const containerRef = useRef()
  const isTouchDevice = useRef()
  const jacketVersion = useRef(LAYER_VERSIONS[shirtRef.onlineProductType])

  useEffect(() => {
    jacketVersion.current = LAYER_VERSIONS[shirtRef.onlineProductType]

    // find the parent product of the jacket
    const parent = globalProductSet.find(
      (product) =>
        product.id === jacketRef?.parentId ||
        product.id === jacketRef?.setReference
    )

    // find the waistcoat product if it exists
    const waistcoat =
      parent?.setProducts?.find((e) => e.layerName === WAISTCOAT_LAYER) ||
      parent?.accessories?.find((e) => e.layerName === WAISTCOAT_LAYER)

    // set and render waistcoat if it exists
    setWaistcoatRef(waistcoat)
  }, [LAYER_VERSIONS, jacketRef, shirtRef, waistcoatRef])

  useEffect(() => {
    isTouchDevice.current = navigator.maxTouchPoints > 0
  }, [])

  // handle image load count for supported model images
  const handleOnLoad = useCallback(() => {
    setClothSize(imageRef.current.currentSrc.split(/(w_\d+)/)[1])
    setImageLoad((prev) => (prev += 1))
  }, [setClothSize, setImageLoad])

  // update the image load count
  const handleOnLoadNoRef = useCallback(() => {
    setImageLoad((prev) => (prev += 1))
  }, [setImageLoad])

  // handle zoom interactions
  const { modelRef, isZoomed, style } = useZoom({
    resetOn: showsCategory,
    isModelLayer,
  })

  const shouldHideJacket = Boolean(!coatsRef || !coatsRef?.isFallback)

  useEffect(() => {
    const parent = globalProductSet.find(
      (product) =>
        product.id === jacketRef?.parentId ||
        product.id === jacketRef?.setReference
    )

    /**
     * @since R23.86
     * @description exclude Three-Piece products from logic, return true early.
     * @example Example: Three-Piece Suit; return tucked in.
     */
    const hasExclusion = EXCEPTION_REGEX.test(parent?.onlineProductType)

    if (hasExclusion) {
      setIsTucked(true)
      return
    }

    const coatExists = !Boolean(coatsRef?.isFallback)
    const jacketExists = !Boolean(jacketRef?.isFallback)

    /**
     * @since R23.63
     * @description When a jacket or coat is added, tuck in the base layer.
     * @example Example: Buttonless Polo Shirt with jacket or coat
     */
    const topLayerExists = coatExists || jacketExists

    /**
     * @since R23.66
     * @description When a pair of trousers is configured as ‘Base Layer Tucked In’ in the product matrix, base layer is always tucked in, regardless of any jacket added.
     * @example Example: Duca trousers with Buttonless Polo Shirt, without jacket
     */
    const bottomLayerDictatesTuck = TUCKED_LAYERS.includes(
      trousersRef.onlineProductType
    )

    /**
     * @since R23.66
     * @description When the Base layer in product matrix is set as 'Base Layer Tucked In', when removing the jacket layer, the base layer is tucked in.
     * @example Example: Charles Jeans with Slim Fit Shirt, without jacket
     */
    const midLayerDictatesTuck = TUCKED_LAYERS.includes(
      shirtRef.onlineProductType
    )

    /**
     * @since R23.66
     * @description When the Base layer is set as ‘Exclude from rule tuck-in-with-jacket', the Base is not tucked in, even if the trousers are set as 'Base Layer Tucked In’.
     * @example Example: Duca trousers with Zipped cardigan, without jacket
     */
    const midLayerIsExcluded = EXCLUDED_TYPES.includes(
      shirtRef.onlineProductType
    )

    /**
     * @since R23.77
     * @description When 'Exclude from rule tuck-in-with-jacket' is set to true, the base layer is never tucked in, not even when a jacket or coat is added.
     */

    const isBaseLayerTuckedIn = TUCKED_LAYERS.includes(
      trousersRef.onlineProductType
    )
    const isExcludedFromTuckIn = EXCLUDED_TYPES.includes(
      shirtRef.onlineProductType
    )
    const prohibitedTuck = isBaseLayerTuckedIn && isExcludedFromTuckIn

    const shouldTuck = !prohibitedTuck
      ? midLayerIsExcluded
        ? false
        : bottomLayerDictatesTuck || midLayerDictatesTuck
      : false

    if (!prohibitedTuck && topLayerExists) {
      setIsTucked(!midLayerIsExcluded)
    } else {
      setIsTucked(shouldTuck)
    }
  }, [jacketRef, coatsRef, shirtRef, trousersRef])

  return (
    <Component ref={containerRef}>
      {!isModelLayer && !isTouchDevice.current && (
        <StyledIcon
          $mousePos={mousePos}
          style={{
            top: mousePos?.y,
            left: mousePos?.x,
          }}
          icon={isZoomed ? 'zoomOut' : 'zoomIn'}
        />
      )}

      <Height
        style={style}
        ref={modelRef}
        $isZoomed={isZoomed}
        $hideCursor={!!mousePos && !isModelLayer && !isTouchDevice.current}
        onMouseMove={(e) => setMousePos({ x: e.clientX, y: e.clientY })}
        onMouseLeave={() => setMousePos(null)}
      >
        <ModelGroup
          initial={{ opacity: 0 }}
          animate={controls}
        >
          <ModelImage
            ref={imageRef}
            key={modelSkin}
            category="ctc_models"
            id={`${modelSkin}_full`}
            onLoad={() => handleOnLoad()}
            collage={true}
          />

          {SKIN_COLORS.map(
            (skinColors, index) =>
              index !== 0 && (
                <HiddenImage
                  key={`preload-${skinColors.tone}`}
                  category="ctc_models"
                  id={`model_${skinColors.tone}_full`}
                  onLoad={handleOnLoad}
                  collage={true}
                />
              )
          )}
          {jacketRef?.id && !jacketRef.isFallback && (
            <TopLayer $isHidden={shouldHideJacket}>
              <ModelImage
                category={jacketRef.category}
                layerName={jacketRef.layerName}
                id={jacketRef.id}
                version={jacketVersion.current}
                collage={true}
                onLoad={handleOnLoadNoRef}
                onError={() => setJacketRef(initialProducts[0])}
              />
            </TopLayer>
          )}
          {waistcoatRef?.id && (
            <WaistCoatLayer isTucked={isTucked}>
              <ModelImage
                category={waistcoatRef.category}
                layerName={waistcoatRef.layerName}
                id={waistcoatRef.id}
                version={
                  UNDERSCORES_DATA[waistcoatRef.layerName][
                    `${UNDERSCORES_STRINGS.default}`
                  ]
                }
                collage={true}
                isAppLoaded={isLoaded}
                onError={() => {
                  // if image fails to load, trigger re-render to remove the ModelImage from the dom
                  setWaistcoatRef(null)
                }}
              />
            </WaistCoatLayer>
          )}
          {shirtRef?.id && (
            <MiddleLayer isTucked={isTucked}>
              <ModelImage
                category={shirtRef.category}
                layerName={shirtRef.layerName}
                id={shirtRef.id}
                version={
                  UNDERSCORES_DATA[shirtRef.layerName][
                    `${UNDERSCORES_STRINGS.default}`
                  ]
                }
                collage={true}
                isAppLoaded={isLoaded}
                onLoad={handleOnLoadNoRef}
                onError={() => setShirtRef(initialProducts[1])}
              />
            </MiddleLayer>
          )}
          {coatsRef?.id && !coatsRef?.isPlaceholder && !coatsRef.isFallback && (
            <CoatLayer>
              <ModelImage
                category={coatsRef.category}
                layerName={coatsRef.layerName}
                id={coatsRef.id}
                version={
                  UNDERSCORES_DATA[COAT_LAYER][`${UNDERSCORES_STRINGS.default}`]
                }
                collage={true}
                isAppLoaded={isLoaded}
                onLoad={handleOnLoadNoRef}
                onError={() => setCoatsRef(initialProducts[4])}
              />
            </CoatLayer>
          )}
          {trousersRef?.id && !trousersRef?.isPlaceholder && (
            <BottomLayer>
              <ModelImage
                category={trousersRef.category}
                layerName={trousersRef.layerName}
                id={trousersRef.id}
                version={
                  UNDERSCORES_DATA[trousersRef.layerName][
                    `${UNDERSCORES_STRINGS.default}`
                  ]
                }
                collage={true}
                onLoad={handleOnLoadNoRef}
                onError={() => setTrouserRef(initialProducts[2])}
              />
            </BottomLayer>
          )}
          {shoesRef?.id && !shoesRef?.isPlaceholder && (
            <ShoeLayer>
              <ModelImage
                category={shoesRef.category}
                layerName={shoesRef.layerName}
                id={shoesRef.id}
                version={
                  UNDERSCORES_DATA[shoesRef.layerName][
                    `${UNDERSCORES_STRINGS.default}`
                  ]
                }
                collage={true}
                onLoad={handleOnLoadNoRef}
                onError={() => setShoeRef(initialProducts[3])}
              />
            </ShoeLayer>
          )}
        </ModelGroup>
      </Height>

      <AnimatePresence exitBeforeEnter>
        {isKOL && <KOLbutton />}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {!showsCategory && !isKOL && (
          <Submit
            url={submitUrl}
            modelSkin={modelSkin}
            locales={locales}
            trackingMetadata={trackingMetadata}
          />
        )}
      </AnimatePresence>
    </Component>
  )
}

export default Model
