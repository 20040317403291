import { Provider } from 'jotai'
import { AppScope, TranslationsAtom } from 'contexts'
import App from 'components/App'
import translations from 'data/translations'
import { INITIAL_PRODUCT_OPTIONS_OBJECT } from 'data'
import AuthProvider from 'components/AuthProvider'

import useAuthorizationCheck from 'hooks/useAuthorizationCheck'
import useDatasource from 'hooks/useDatasource'

const AppProvider = ({
  initialProducts,
  initialModel,
  site,
  submitUrl,
  backUrl,
  initialOpenLayer,
  initialSuitProducts,
  initialViewMode,
}) => {
  const needsAuthorization = useAuthorizationCheck()
  const { dataSource, localeFromParam } = useDatasource()

  if (!dataSource) return null

  return (
    <AuthProvider needsAuthorization={needsAuthorization}>
      <Provider
        initialValues={[[TranslationsAtom, translations[site.locale]]]}
        scope={AppScope}
      >
        <App
          initialModel={initialModel}
          initialProducts={initialProducts}
          site={site}
          submitUrl={submitUrl}
          backUrl={backUrl}
          initialOpenLayer={
            INITIAL_PRODUCT_OPTIONS_OBJECT.includes(initialOpenLayer)
              ? initialOpenLayer
              : ''
          }
          initialSuitProducts={initialSuitProducts}
          initialViewMode={initialViewMode}
          dataSource={dataSource}
          localeFromParam={localeFromParam}
        />
      </Provider>
    </AuthProvider>
  )
}

export default AppProvider
