import { useEffect, useRef } from 'preact/compat'
import { useAtom } from 'jotai'

import Thumbnail from 'components/Thumbnail'

import { ThumbSizeAtom, PreviousViewAtom, InterfaceViewAtom } from 'contexts'

import {
  translateStringKey,
  getCategoryPosition,
  getSingleCategory,
  getJourney,
} from 'utils'

import {
  SUIT_LAYER,
  TOP_LAYER,
  COAT_LAYER,
  BOTTOM_LAYER,
  SUITS_MODE,
  OUTOFSTOCK_STRING,
} from 'data'

import { hideTrousersCategory, itemAnimation } from 'styles'

import {
  CategoryTitle,
  CategoryContainer,
  CategoryTitleContainer,
} from 'components/Categories/Category.styles.js'

const ProductCategory = ({
  isSuitsMode,
  isHorizontalScroll,
  setHasHover,
  hasHover,
  isLoaded,
  onLoad,
  controls,
  initialProducts,
  handleOnClick,
  product,
  locale,
  suitsJacketControls,
  index,
}) => {
  const isReset = !product?.id || product.isFallback
  const isOutOfStock = Boolean(product?.isPlaceholder)

  // constants
  const imageRef = useRef()
  const fallback = initialProducts[index]
  const {
    id,
    category,
    alt,
    layerName,
    subCategory = undefined,
  } = isReset ? fallback : product
  const categoryIndex = getJourney(layerName)?.index

  // animations
  const { toSeperatesMode, toSuitsMode } =
    hideTrousersCategory(isHorizontalScroll)

  // conditionals
  const isTrousers = layerName === BOTTOM_LAYER

  // read-only
  const [previousView] = useAtom(PreviousViewAtom)

  // write-only
  const [, setThumbSize] = useAtom(ThumbSizeAtom)
  const [, setCurrentView] = useAtom(InterfaceViewAtom)

  // ensure suit type products are not shown in incorrect layer
  let actualLayer = getCategoryPosition[categoryIndex]
  if (isSuitsMode && actualLayer === TOP_LAYER) {
    actualLayer = SUIT_LAYER
  }

  const isOptionalLayer = [COAT_LAYER, TOP_LAYER].includes(actualLayer)
  const singularCaregory = getSingleCategory(actualLayer)

  // get category string
  const transformedCategory = isOptionalLayer
    ? `${!isReset ? '' : 'add-'}${
        isOptionalLayer && !isReset ? singularCaregory : actualLayer
      }`
    : singularCaregory

  const categoryString = translateStringKey(transformedCategory, locale)

  const resetString =
    !isOutOfStock && isReset && !isOptionalLayer
      ? `${translateStringKey('Add', locale)} `
      : ''

  useEffect(() => {
    setThumbSize(imageRef.current?.currentSrc.split(/(w_\d+)/)[1])
  }, [setThumbSize])

  // event handlers
  const handleProductSelection = (e) => {
    e.preventDefault()
    handleOnClick({
      layerName,
      category,
      subCategory,
      index: categoryIndex,
    })
  }

  // animate trousers category thumbnail in suit mode between toSuitsMode and toSeperates, ignore in other categories
  const animationVariant = isTrousers
    ? isSuitsMode
      ? toSuitsMode
      : toSeperatesMode
    : null

  // ignore animation when switching categories between appModes
  const shouldIgnoreAnimation =
    !previousView || previousView?.appMode === SUITS_MODE

  const onSelection = (e) => {
    if (isOutOfStock) {
      setCurrentView(OUTOFSTOCK_STRING)
      return
    }
    handleProductSelection(e)
  }

  return (
    <CategoryContainer
      key={`category-${index}`}
      disabled={isSuitsMode && layerName === BOTTOM_LAYER}
      initial={!previousView}
      animate={animationVariant}
      onMouseMove={() => setHasHover(true)}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      onMouseDown={(e) => e.preventDefault()}
      onKeyPress={(e) => e.nativeEvent.key === ' ' && onSelection(e)}
      onClick={(e) => onSelection(e)}
    >
      <Thumbnail
        isCategory
        id={id}
        category={subCategory || category}
        alt={alt}
        controls={controls}
        isLoaded={isLoaded}
        onLoad={onLoad}
        imageRef={imageRef}
        layerName={layerName}
        dataLength={4}
        isReset={isReset}
        isOutOfStock={isOutOfStock}
        initialProducts={initialProducts}
        suitsJacketControls={suitsJacketControls}
        parentHasHover={hasHover}
        parentId={product?.parentId}
      />

      <CategoryTitleContainer
        variants={itemAnimation}
        custom={isHorizontalScroll}
      >
        <CategoryTitle>{`${resetString}${categoryString}`}</CategoryTitle>
      </CategoryTitleContainer>
    </CategoryContainer>
  )
}

export default ProductCategory
