import styled from 'styled-components'
import { colors, mediaQueries, interfaceSize } from 'styles'
import { AppScope, PortalAtom } from 'contexts'
import { PORTAL_SCANNER_KEY } from 'data'

import { useAtom } from 'jotai'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  width: 32px;
  height: 32px;
  background-color: ${colors.white};
  border-radius: 100%;

  position: absolute;
  z-index: 101;
  bottom: calc(${interfaceSize.mobile}px + 14px);
  right: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQueries.s} {
    bottom: calc(${interfaceSize.tablet}px + 24px);
    right: 24px;
  }

  ${mediaQueries.m} {
    right: calc(${interfaceSize.desktop}px + 20px);
    bottom: 22px;
  }
`

const Button = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${colors.white};
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ScanButton = () => {
  const [, setPostalSelection] = useAtom(PortalAtom, AppScope)

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.25,
        },
      }}
      exit={{
        opacity: 0,
      }}
    >
      <Button
        onClick={() => {
          setPostalSelection(PORTAL_SCANNER_KEY)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <path
            fill="#2D2E2C"
            d="M7.086 6.002H6.31v8h.776v-8Zm2.898 0H8.032v8h1.952v-8Zm1.726 0h-.776v8h.776v-8Zm1.724 0h-.776v8h.776v-8Zm2.578 0h-1.63v8h1.63v-8Zm-10.65 0H3.998v8h1.364v-8ZM2.004 2.998h3v-1h-3c-.5 0-1 .5-1 1v3h1v-3ZM2 17.002v-3H1v3c0 .5.5 1 1 1h3v-1H2Zm16 0h-3v1h3c.5 0 1-.5 1-1v-3h-1v3Zm0-14v3h1v-3c0-.5-.5-1-1-1h-3v1h3Z"
          />
        </svg>
      </Button>
    </Container>
  )
}

export default ScanButton
