export const colors = {
  black: '#000000',
  white: '#ffffff',
  transparent: '#00000000',
  grey: {
    100: '#EFEFEF',
    200: '#e1e1e1',
    300: '#C6C6C6',
    500: '#7F7F7F',
    800: '#898989',
    900: '#2D2E2C',
  },
  secondary: '#757575',
  text: {
    primary: '#2D2E2C',
  },
}
