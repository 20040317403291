import styled from 'styled-components'
import { forwardRef } from 'preact/compat'
import { useContext, useEffect, useState } from 'preact/hooks'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { useAtom } from 'jotai'

import Image from 'components/Image'
import Icon from 'components/Icon'

import {
  HorizontalScrollContext,
  GlobalProductsContext,
  AppModeAtom,
  AppScope,
  ProductMatrixContext,
} from 'contexts'

import { SUITS_MODE, TOP_LAYER, BOTTOM_LAYER, SUIT_LAYER } from 'data'

import {
  colors,
  mediaQueries,
  thumbSize,
  modelThumbSize,
  categoryThumbSize,
  globalRadius,
  transitions,
  itemAnimation,
  counterpartAnimation,
} from 'styles'

const MODELSELECTION_SIZE = '100%'
const SUIT_CATEGORY_SIZE = '100%'
const TROUSERS_CATEGORY_SIZE = '90%'
const SHOES_CATEGORY_SIZE = '60%'

const getDimensions = {
  xs: TROUSERS_CATEGORY_SIZE,
  s: '75%',
  m: '75%',
}

const getPadding = (category, mobile = true) => {
  switch (category) {
    case 'Trousers':
      return mobile ? `10px 0 0 0` : `5px 0 0 0`
    case 'Shorts':
      return mobile ? `15px` : `20px`
    case 'Shoes':
      return mobile ? `5px` : '0 16px'
    default:
      return mobile ? `5px` : '0'
  }
}

const ThumbnailWrapper = styled.div`
  position: relative;
  z-index: ${({ $isCategory }) => ($isCategory ? 1 : -1)};
  width: ${({ $isCategory, $isModelSelection }) => {
    if ($isCategory) return `${categoryThumbSize.mobile}px`
    if ($isModelSelection) return `80px`

    return `${thumbSize.mobile}px`
  }};
  height: ${({ $isCategory, $isModelSelection }) => {
    if ($isCategory) return `${categoryThumbSize.mobile}px`
    if ($isModelSelection) return `80px`

    return `${thumbSize.mobile}px`
  }};

  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  border: none;
  border-radius: ${({ $isActive }) => !$isActive && globalRadius};
  background-color: ${({ $inheritStyles }) => {
    if (!$inheritStyles) return colors.white
    return colors.grey[100]
  }};
  transition: opacity 0.5s ${transitions.general.css};
  position: relative;
  padding: 0;
  cursor: pointer;

  /* prevent call-out on long-press and selection */
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  &::selection {
    background: transparent;
  }

  picture {
    height: inherit;
  }

  ${mediaQueries.s} {
    margin: 0;
    min-width: ${({ $isCategory, $isModelSelection }) => {
      if ($isCategory || $isModelSelection)
        return `${categoryThumbSize.tablet}px`
      return `${thumbSize.tablet}px`
    }};
    height: ${({ $isCategory, $isModelSelection }) => {
      if ($isCategory || $isModelSelection)
        return `${categoryThumbSize.tablet}px`
      return `${thumbSize.tablet}px`
    }};
  }

  ${mediaQueries.m} {
    min-width: ${({ $isCategory, $isModelSelection }) => {
      if ($isCategory) return `${categoryThumbSize.desktop}px`
      if ($isModelSelection) return `${modelThumbSize.desktop}px`
      return `${thumbSize.desktop}px`
    }};
    height: ${({ $isCategory, $isModelSelection }) => {
      if ($isCategory) return `${categoryThumbSize.desktop}px`
      if ($isModelSelection) return `${modelThumbSize.desktop}px`
      return `${thumbSize.desktop}px`
    }};
  }
`

const MotionDiv = styled(motion.div)`
  overflow: hidden;
  position: relative;

  width: ${(props) => {
    if (props.category === 'ModelSelection') return MODELSELECTION_SIZE
    return getDimensions.xs
  }};
  height: ${(props) => {
    if (props.category === 'ModelSelection') return MODELSELECTION_SIZE
    return getDimensions.xs
  }};
  align-self: ${(props) => {
    if (props.category === 'Trousers') return 'flex-end'
    return 'initial'
  }};
  background: ${({ $isModelSelection }) => {
    if ($isModelSelection) return colors.white
    return colors.grey[100]
  }};
  display: flex;
  justify-content: center;

  padding: ${(props) => {
    if (props.category === 'ModelSelection') return '0'
    return getPadding(props.category)
  }};
  border-radius: ${globalRadius};

  /* ${({ $hasCounterPart }) => $hasCounterPart && `display: block`} */

  ${mediaQueries.s} {
    width: 80%;
    height: ${(props) => {
      if (props.category === 'Trousers') return TROUSERS_CATEGORY_SIZE
      if (props.category === 'Shoes') return SHOES_CATEGORY_SIZE
      if (props.category === 'ModelSelection') return MODELSELECTION_SIZE
      return getDimensions.s
    }};
  }

  ${mediaQueries.m} {
    width: ${(props) => {
      if (props.category === 'ModelSelection') return `100%`
      return `100%`
    }};
    height: ${(props) => {
      if (props.category === 'Trousers') return TROUSERS_CATEGORY_SIZE
      if (props.category === 'Shoes') return SHOES_CATEGORY_SIZE
      if (props.category === 'ModelSelection') return MODELSELECTION_SIZE
      if (props.category === SUIT_LAYER) return SUIT_CATEGORY_SIZE
      return getDimensions.m
    }};

    padding: ${(props) => {
      if (props.category === 'ModelSelection') return '0'
      return getPadding(props.category, false)
    }};
  }
`

const ImageWrapper = styled(motion.figure)`
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  img::selection {
    background: transparent;
  }

  ${({
    $hasCounterPart,
    $isJacket,
    $isCounterPart,
    $isTrousers,
    $isShorts,
    category,
  }) =>
    $hasCounterPart &&
    `
      transition: .3 ${transitions.general.css};
      align-self: center;
      position: absolute;
      ${$isJacket ? 'left: 6px' : 'right: 4px'};
      transform-origin: bottom bottom;

      ${
        !$isJacket &&
        `
        max-height: ${!$isTrousers ? '45%' : '70%'};
        max-width: 40%;
        `
      };

      ${
        $isJacket &&
        `
          max-height: 70%;
        `
      }

      ${category === 'Shorts' && 'max-height: 60%;'}

      display: ${$isCounterPart ? 'content' : 'block'};

      ${mediaQueries.s} {
        ${$isJacket ? 'left: 3px' : 'right: 5px'};
        ${
          !$isJacket &&
          !$isShorts &&
          `
            max-height: ${!$isTrousers ? '35%' : '90%'};
            max-width: 40%;
          `
        };
      }

      ${mediaQueries.m} {
        ${$isJacket ? 'left: 0px' : 'right: 15px'};
        ${
          !$isJacket &&
          `
          max-height: ${!$isTrousers ? '35%' : '70%'};
          max-width: 40%;
        `
        };

      }
  `}

  ${({ $inheritStyles }) =>
    $inheritStyles &&
    `
      img {
        ${(props) => props.category === 'Trousers' && 'max-height:100%'};
        object-fit: contain;
        height: 100%;
        width: ${({ category }) => {
          if (category === 'ModelSelection') return '100%'
          return '100px'
        }};
      }
  `}
`

const ReviveButton = styled(motion.div)`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${globalRadius};

  z-index: 999;
  background-color: ${colors.grey[100]}CC;
`

const StyledReviveButton = styled(Icon)`
  transform: scale(0.725);

  ${mediaQueries.s} {
    transform: unset;
  }
`

const Thumbnail = ({
  id,
  category,
  alt,
  onClick,
  className,
  isPlaceholder,
  controls,
  isLoaded,
  preventAnimation = false,
  onLoad,
  customSrc,
  customSet,
  variant,
  subCategory,
  imageRef,
  isActive,
  scrollBlock,
  isCategory = false,
  isModelLayer = false,
  inheritStyles = true,
  isReset = false,
  layerName,
  children,
  isOutOfStock,
  parentHasHover,
  parentId,
}) => {
  const [appMode] = useAtom(AppModeAtom, AppScope)

  const isHorizontalScroll = useContext(HorizontalScrollContext)
  const { globalProductSet } = useContext(GlobalProductsContext)

  const [isHovered, setIsHovered] = useState()

  const isSuitsMode = appMode === SUITS_MODE && layerName === TOP_LAYER
  const parent = globalProductSet.find((e) => parentId === e.id)

  const counterPart =
    isSuitsMode &&
    parentId &&
    parent?.setProducts.find((e) => e.layerName == BOTTOM_LAYER)

  const {
    productMatrix: { UNDERSCORES_DATA },
  } = useContext(ProductMatrixContext)

  const jacketControls = useAnimation()
  const counterpartAnimationControls = useAnimation()
  const counterPartAnimationSet = counterpartAnimation(isHorizontalScroll)

  useEffect(() => {
    if (isSuitsMode) {
      counterpartAnimationControls.start(counterPartAnimationSet.toSuitsMode)
    } else {
      counterpartAnimationControls.start(
        counterPartAnimationSet.toSeperatesMode
      )
    }
  }, [appMode])

  return (
    <ThumbnailWrapper
      category={category}
      $isCategory={isCategory}
      $isModelSelection={isModelLayer}
      $inheritStyles={inheritStyles}
      onClick={onClick}
      className={className}
      variant={variant}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $isActive={isActive}
    >
      {children ? (
        <ImageWrapper
          category={category}
          variants={itemAnimation}
          custom={isHorizontalScroll}
          $inheritStyles={inheritStyles}
        >
          <AnimatePresence>{children}</AnimatePresence>
        </ImageWrapper>
      ) : (
        <AnimatePresence exitBeforeEnter>
          <MotionDiv
            category={isSuitsMode ? SUIT_LAYER : category}
            transition={{ duration: 0.5 }}
            variant={variant}
            animate={!preventAnimation ? !preventAnimation : 'show'}
            variants={itemAnimation}
            custom={isHorizontalScroll}
            $hasCounterPart={counterPart}
            $isJacket={counterPart}
            $parentHasHover={parentHasHover}
          >
            {/* reset button */}
            {!isOutOfStock && isReset && (
              <ReviveButton
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <StyledReviveButton icon="plus" />
              </ReviveButton>
            )}

            {/* primary product image */}
            <ImageWrapper
              key={`${id}-primary`}
              category={category}
              animate={
                isSuitsMode
                  ? {
                      x: isHorizontalScroll ? '-18px' : '-26px',
                      y: isHorizontalScroll ? '0px' : '-3px',
                      scale: isHorizontalScroll ? 0.9 : 0.75,
                      transition: {
                        type: 'tween',
                        duration: 0.2,
                      },
                    }
                  : {
                      x: '0px',
                      y: '0px',
                      scale: 1,
                      transition: {
                        type: 'tween',
                        duration: 0.2,
                      },
                    }
              }
              $inheritStyles={inheritStyles}
              $hasCounterPart={counterPart}
              $isJacket={counterPart}
            >
              <Image
                customSrc={customSrc}
                customSet={customSet}
                category={category}
                id={id}
                version={UNDERSCORES_DATA[layerName]}
                alt={alt}
                onLoad={onLoad}
                variant={variant}
                subCategory={subCategory}
                ref={imageRef}
                isOutOfStock={isOutOfStock}
                appMode={appMode}
                layerName={layerName}
              />
            </ImageWrapper>

            {/* counterpart product image */}

            <ImageWrapper
              key="secondary"
              category={category}
              animate={counterpartAnimationControls}
              style={{
                display: isPlaceholder || counterPart ? 'block' : 'none',
              }}
              $inheritStyles={inheritStyles}
              $hasCounterPart={counterPart}
              $isCounterPart={counterPart}
              $isTrousers={
                counterPart?.isNestedSuit ||
                counterPart?.category === 'Trousers'
              }
              $isShorts={
                !counterPart?.isNestedSuit && counterPart?.category === 'Shorts'
              }
            >
              {counterPart && (
                <Image
                  customSrc={customSrc}
                  customSet={customSet}
                  category={isPlaceholder ? category : counterPart?.category}
                  id={isPlaceholder ? category : counterPart?.id}
                  alt={isPlaceholder ? category : counterPart?.alt}
                  onLoad={onLoad}
                  variant={variant}
                  subCategory={
                    isPlaceholder ? category : counterPart?.subCategory
                  }
                  ref={imageRef}
                  isOutOfStock={isOutOfStock}
                  appMode={appMode}
                  layerName={BOTTOM_LAYER}
                  isNestedProduct={counterPart?.isNestedSuit}
                  isCounterPart
                />
              )}
            </ImageWrapper>
          </MotionDiv>
        </AnimatePresence>
      )}
    </ThumbnailWrapper>
  )
}

const ThumbnailWithRef = (props, ref) => (
  <Thumbnail
    forwardedRef={ref}
    {...props}
  />
)

export default forwardRef(ThumbnailWithRef)
