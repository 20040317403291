import { useAtom } from 'jotai'
import { AnimatePresence, useAnimation } from 'framer-motion'

import { useCallback, useContext, useEffect, useState } from 'preact/hooks'

import {
  Modes,
  AppScope,
  AppModeAtom,
  PreviousViewAtom,
  HorizontalScrollContext,
} from 'contexts'

import {
  TOP_LAYER,
  SUITS_MODE,
  VISUAL_SORT_OBJECT,
  ADDITIONAL_CATEGORIES,
} from 'data'

import {
  gtmPush,
  getAvoUrl,
  getSingleCategory,
  translateStringKey,
  extractLocaleString,
  analytics_categorySelect,
} from 'utils'

import { containerAnimation } from 'styles'
import { CategoriesWrapper } from './Category.styles.js'

import Scroll from 'components/Scroll'
import ProductCategory from 'components/ProductCategory'
import AdditionalCategory from 'components/AdditionalCategory'

const Categories = ({
  setCategory,
  controls,
  isLoaded,
  onLoad,
  setShowGuide,
  locales,
  initialProducts,
  trackingMetadata,
}) => {
  // read-only
  const [appMode] = useAtom(AppModeAtom, AppScope)
  const isHorizontalScroll = useContext(HorizontalScrollContext)

  // constants
  const sortedCategories = []
  const isSuitsMode = appMode === SUITS_MODE
  const locale = extractLocaleString(locales)

  // write-only
  const [, setPreviousView] = useAtom(PreviousViewAtom)

  // states
  const [hasHover, setHasHover] = useState(false)

  // mode-specific atoms
  const { JacketsAtom, ShirtsAtom, TrousersAtom, ShoesAtom, CoatsAtom } =
    Modes[appMode]
  const [jackets] = useAtom(JacketsAtom)
  const [shirts] = useAtom(ShirtsAtom)
  const [trousers] = useAtom(TrousersAtom)
  const [shoes] = useAtom(ShoesAtom)
  const [coats] = useAtom(CoatsAtom)

  // mode-specific products set
  const products = [jackets, shirts, trousers, shoes, coats]

  // animation controls
  const suitsJacketControls = useAnimation()

  // handlers
  const handleOnClick = useCallback(
    ({ layerName, category, subCategory, index }) => {
      // set category view
      setCategory({
        category,
        layerName,
        index,
      })

      // analytics
      const promptLayer = getSingleCategory(layerName)
      const prompt = translateStringKey(
        isSuitsMode && layerName === TOP_LAYER ? 'Suit' : promptLayer,
        'en'
      )

      gtmPush({
        event: 'GAevent',
        eventCategory: 'Crackthecode_Interactions',
        eventAction: 'Category_Selected',
        eventLabel: `${prompt}`,
      })

      const { url, locationId } = getAvoUrl(window.location.href)

      analytics_categorySelect({
        ...trackingMetadata,
        url,
        locationId,
        lookbuilderOption: `lookbuilder_${category?.toLocaleLowerCase()}`,
      })

      // close guide
      if (!localStorage.getItem('guideClosed')) {
        localStorage.setItem('guideClosed', true)
        setShowGuide(false)
      }
    },
    [isSuitsMode, setCategory, setShowGuide]
  )

  // effects
  useEffect(() => {
    setPreviousView({ view: 'categories', appMode })
  }, [])

  // sort categories by visual order
  products.map((product, index) => {
    if (VISUAL_SORT_OBJECT.indexOf(product.layerName) !== -1) {
      sortedCategories[VISUAL_SORT_OBJECT.indexOf(product.layerName)] = product
    } else {
      sortedCategories[index] = product
    }
  })

  return (
    <Scroll>
      <AnimatePresence>
        <CategoriesWrapper
          $isSuitsMode={isSuitsMode}
          variants={containerAnimation}
          initial="hidden"
          animate="show"
          exit="leave"
        >
          {sortedCategories.map((product, index) => {
            return (
              <ProductCategory
                key={index}
                {...{
                  index,
                  isSuitsMode,
                  isHorizontalScroll,
                  setHasHover,
                  hasHover,
                  isLoaded,
                  onLoad,
                  controls,
                  initialProducts,
                  handleOnClick,
                  product,
                  locale,
                  suitsJacketControls,
                }}
              />
            )
          })}

          {Object.values(ADDITIONAL_CATEGORIES).map((category, index) => {
            return (
              <AdditionalCategory
                key={index}
                {...{
                  isHorizontalScroll,
                  setHasHover,
                  controls,
                  locale,
                  category,
                  setCategory,
                  index,
                  products,
                }}
              />
            )
          })}
        </CategoriesWrapper>
      </AnimatePresence>
    </Scroll>
  )
}

export default Categories
