import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useContext } from 'preact/hooks'

import {
  getSingleCategory,
  translateStringKey,
  extractLocaleString,
} from 'utils'

import { HorizontalScrollContext } from 'contexts'
import { mediaQueries, growInAnimation, slideInAnimation } from 'styles'

const Container = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  ${mediaQueries.m} {
    position: unset;
    width: unset;
  }
`

const NoticeSpan = styled.span`
  font-size: 12px;
  font-weight: 500;

  position: absolute;
  bottom: 22px;
  left: 0;
  pointer-events: none;

  width: 100%;

  text-align: center;
  padding-bottom: 5px;

  ${mediaQueries.m} {
    position: unset;
    width: unset;
    padding: unset;
  }
`

const Divider = styled.div`
  height: 10px;
`

const Notice = ({ layerName, locales }) => {
  const locale = extractLocaleString(locales)
  const isHorizontalScroll = useContext(HorizontalScrollContext)

  const translationString =
    translateStringKey(`removed-${layerName}`, locale) ||
    translateStringKey(`removed`, locale)

  return (
    <Container
      initial="initial"
      animate="animate"
      exit="exit"
      variants={isHorizontalScroll && slideInAnimation}
    >
      <Divider />
      {isHorizontalScroll && <NoticeSpan>{`${translationString}`}</NoticeSpan>}
    </Container>
  )
}

export default Notice
