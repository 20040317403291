let avoInspector = null

export const initAvoInspector = async () => {
  if (typeof window === 'undefined' || avoInspector) return avoInspector

  try {
    const Inspector = await import('avo-inspector')
    avoInspector = new Inspector.AvoInspector({
      apiKey: process.env.AVO_INSPECTOR_API_KEY,
      env: getEnvironment(process.env.ENVIRONMENT),
      version: '1.0.0',
    })
    avoInspector.enableLogging(true)
    return avoInspector
  } catch (error) {
    console.error('Failed to initialize Avo Inspector:', error)
    return null
  }
}

const getEnvironment = (env) => {
  switch (env) {
    case 'PRODUCTION':
      return 'prod'
    case 'ACCEPTANCE':
      return 'staging'
    default:
      return 'dev'
  }
}
