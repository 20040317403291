import { useAtom, useSetAtom } from 'jotai'
import { QueryParams, useQueryParam } from 'use-query-params'
import styled from 'styled-components'
import { useContext, useEffect, useState } from 'preact/hooks'
import { motion } from 'framer-motion'

import Icon from 'components/Icon'
import Loader from 'components/Loader'

import useScanner from 'hooks/useScanner'

import { formatScannedProductData, getJourney, delay, formatState } from 'utils'

import {
  AppScope,
  DataAtom,
  PortalAtom,
  ScannedProductAtom,
  ProductMatrixContext,
  AppModeAtom,
  Modes,
  GlobalProductsContext,
} from 'contexts'

import {
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  COAT_LAYER,
  SHOE_LAYER,
  SUITS_MODE,
  SEPERATES_MODE,
  SUIT_LAYER,
} from 'data'

import { colors } from 'styles'

const API_ENDPOINT = `https://${process.env.LOOKBUILDER_URL}/api/getItemByEAN`

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  /* bump to bypass platform z-index */
  z-index: 121;

  height: 100%;
  width: 100%;

  background-color: ${colors.grey[100]};
`

const ScannerContainer = styled.div`
  height: 100%;
  width: 100%;
`

const Button = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;

  width: 32px;
  height: 32px;

  background-color: ${colors.white};
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Scanner = () => {
  const [appMode, setAppMode] = useAtom(AppModeAtom, AppScope)
  const isSuitsMode = appMode === SUITS_MODE

  const [, setPostalSelection] = useAtom(PortalAtom, AppScope)
  const [, setScannedProduct] = useAtom(ScannedProductAtom, AppScope)

  const [tempArray] = useAtom(DataAtom)

  const { productMatrix } = useContext(ProductMatrixContext)
  const { PRODUCT_TYPE_IDS } = productMatrix

  const { globalProductSet, setGlobalProductSet } = useContext(
    GlobalProductsContext
  )
  const [suitParams, setSuitParams] = useQueryParam(SUITS_MODE, QueryParams)
  const [seperatesParams, setSeperatesParams] = useQueryParam(
    SEPERATES_MODE,
    QueryParams
  )

  const { result, isLoading, stopScanner } = useScanner()

  const closePortal = () => {
    setPostalSelection(null)
    stopScanner()
  }

  // At the top level of your component, initialize setter functions for each atom in both modes
  const setSeperatesJackets = useSetAtom(Modes[SEPERATES_MODE].JacketsAtom)
  const setSeperatesShirts = useSetAtom(Modes[SEPERATES_MODE].ShirtsAtom)
  const setSeperatesTrousers = useSetAtom(Modes[SEPERATES_MODE].TrousersAtom)
  const setSeperatesShoes = useSetAtom(Modes[SEPERATES_MODE].ShoesAtom)
  const setSeperatesCoats = useSetAtom(Modes[SEPERATES_MODE].CoatsAtom)

  const setSuitsJackets = useSetAtom(Modes[SUITS_MODE].JacketsAtom)
  const setSuitsShirts = useSetAtom(Modes[SUITS_MODE].ShirtsAtom)
  const setSuitsTrousers = useSetAtom(Modes[SUITS_MODE].TrousersAtom)
  const setSuitsShoes = useSetAtom(Modes[SUITS_MODE].ShoesAtom)
  const setSuitsCoats = useSetAtom(Modes[SUITS_MODE].CoatsAtom)

  const updateAtom = (formattedProduct, updateMode, isSuitProduct) => {
    const isSuitsMode = updateMode === SUITS_MODE

    let setterFunction
    let subSetterFunction

    const mainProduct = {
      ...formattedProduct,
      parentId: formattedProduct.id,
      isNestedSuit: true,
      layerName: TOP_LAYER,
    }

    const alterationProduct = isSuitProduct && {
      ...formattedProduct,
      parentId: formattedProduct.id,
      isNestedSuit: true,
      layerName: BOTTOM_LAYER,
    }

    const parent = isSuitProduct && {
      ...formattedProduct,
      isNestedSuit: true,
      setProducts: [mainProduct, alterationProduct],
    }

    switch (formattedProduct?.layerName) {
      case TOP_LAYER:
        setterFunction = isSuitProduct ? setSuitsJackets : setSeperatesJackets
        break
      case SUIT_LAYER:
        setterFunction = setSuitsJackets
        subSetterFunction = setSuitsTrousers
        break
      case MID_LAYER:
        setterFunction = isSuitsMode ? setSuitsShirts : setSeperatesShirts
        break
      case BOTTOM_LAYER:
        setterFunction = isSuitsMode ? setSuitsTrousers : setSeperatesTrousers
        break
      case SHOE_LAYER:
        setterFunction = isSuitsMode ? setSuitsShoes : setSeperatesShoes
        break
      case COAT_LAYER:
        setterFunction = isSuitsMode ? setSuitsCoats : setSeperatesCoats
        break
    }

    // update atoms for the model comp
    setterFunction(isSuitProduct ? mainProduct : formattedProduct)

    if (subSetterFunction) {
      // inject data for everything else (thumbnails, link gen., etc.)
      setGlobalProductSet((prev) => [...prev, parent])
      setGlobalProductSet((prev) => [...prev, mainProduct])
      setGlobalProductSet((prev) => [...prev, alterationProduct])

      // update atoms for the model comp
      subSetterFunction(alterationProduct)
    }

    // toggle to the correct mode if the product is a suit or jacket
    setAppMode(updateMode)
  }

  useEffect(() => {
    const fetchProduct = async (result) => {
      if (!result) return

      const response = await fetch(`${API_ENDPOINT}?ean=${result}`)
      const data = await response.json()
      const isError = response.status !== 200

      const formattedProduct = formatScannedProductData({
        product: data,
        PRODUCT_TYPE_IDS,
      })

      const journeyData = getJourney(formattedProduct?.layerName)
      tempArray[journeyData?.index]?.push(formattedProduct)

      await delay(300)
      setScannedProduct({ isError, formattedProduct })
      await delay(2250)
      setScannedProduct(null)

      if (isError) return

      // update the state if the product is a suit, otherwise update whichever state the user is in
      const { category } = formattedProduct

      const isSuitProduct = category === 'Suits'
      const isSeparatesProduct = category === 'Jackets'

      const updateMode =
        isSuitProduct || isSeparatesProduct
          ? isSeparatesProduct
            ? SEPERATES_MODE
            : SUITS_MODE
          : appMode

      updateMode && updateAtom(formattedProduct, updateMode, isSuitProduct)

      if (isSuitProduct) {
        setSuitParams((pre) => {
          const isUnsupportedLayer = [TOP_LAYER, BOTTOM_LAYER].includes(
            formattedProduct.layerName
          )

          if (isUnsupportedLayer) return

          return formatState(
            pre,
            suitParams,
            formattedProduct,
            globalProductSet
          )
        })
      } else if (isSuitsMode) {
        setSeperatesParams((pre) => {
          return formatState(
            pre,
            seperatesParams,
            formattedProduct,
            globalProductSet
          )
        })
      } else {
        setSuitParams((pre) => {
          return formatState(
            pre,
            seperatesParams,
            formattedProduct,
            globalProductSet
          )
        })
      }
    }

    if (result) {
      fetchProduct(result)
      closePortal()
    }
  }, [result])

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{
        duration: 0.1,
      }}
    >
      {isLoading && <Loader />}
      <ScannerContainer id="data-capture-view" />
      <Button
        onClick={() => {
          closePortal()
        }}
      >
        <Icon
          aria-label="Close"
          icon="Cross"
        />
      </Button>
    </Container>
  )
}

export default Scanner
