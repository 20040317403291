export const thumbSize = {
  mobile: 72,
  tablet: 104,
  desktop: 150,
}

export const modelThumbSize = {
  mobile: thumbSize.mobile,
  tablet: thumbSize.tablet,
  desktop: 172,
}

export const categoryThumbSize = {
  mobile: 88,
  tablet: 120,
  desktop: 185,
}

export const interfaceSize = {
  mobile: 135,
  tablet: 180,
  desktop: 400,
}

export const resetButtonSize = {
  mobile: {
    width: 40,
    height: thumbSize.mobile,
  },
  tablet: {
    width: 46,
    height: thumbSize.tablet,
  },
  desktop: {
    width: thumbSize.desktop,
    height: 46,
  },
}
