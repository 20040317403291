import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQueries, thumbSize } from 'styles'

export const Inner = styled(motion.div)`
  width: 100%;
`

export const Container = styled(motion.div)`
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);

  width: 223px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;

  ${mediaQueries.s} {
    bottom: 20px;
  }

  ${mediaQueries.m} {
    padding-bottom: unset;
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;

    max-width: ${thumbSize.desktop + 24}px;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1rem;

  ${mediaQueries.m} {
    text-align: start;
  }
`

export const Material = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  font-weight: 300;
  font-size: 12px;
  width: 90%;

  ${mediaQueries.m} {
    width: max-content;
    max-width: 190px;
  }
`

export const Price = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-align: end;
  font-weight: 300;
  font-size: 14px;

  ${mediaQueries.m} {
    text-align: start;
  }
`

export const Divider = styled.div`
  padding: 0 0.2ch;

  ${mediaQueries.m} {
    display: none;
  }
`

export const MetaDataContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQueries.m} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const MetaData = styled.div`
  display: flex;
  flex-direction: row;

  max-width: ${({ $shortened }) => `${$shortened ? 50 : 70}%`};
  padding-right: 0.1ch;

  ${mediaQueries.m} {
    padding-right: unset;
    flex-direction: column;
  }
`
