import { useContext, useEffect, useMemo, useRef } from 'preact/hooks'
import { useAtom } from 'jotai'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Button from 'components/Button'

import {
  gtmPush,
  translateStringKey,
  extractLocaleString,
  sortWishList,
  getAvoUrl,
} from 'utils'
import { ENDPOINT, CDN_URL } from 'utils/endpoints'

import {
  AppScope,
  InterfaceViewAtom,
  AppModeAtom,
  Modes,
  GlobalProductsContext,
} from 'contexts'

import {
  mediaQueries,
  fadeInAnimation,
  interfaceSize,
  elementFocus,
  colors,
} from 'styles'

import {
  SEPERATES_MODE,
  SUITS_MODE,
  LS_MODEL_SELECTION,
  FALLBACK_MODEL,
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
} from 'data'

import { analytics_shopTheLook } from 'utils'

const Container = styled(motion.div)`
  position: fixed;
  bottom: calc(${interfaceSize.mobile}px + 15px);
  right: 12px;

  transform: ${({ $isModelLayer }) => $isModelLayer && `translateY(-15px)`};
  transition: transform 0.35s ease;

  ${mediaQueries.s} {
    right: 20px;
    bottom: calc(20px + ${interfaceSize.tablet}px);
  }

  ${mediaQueries.m} {
    right: calc(22px + ${interfaceSize.desktop}px);
    bottom: 22px;
  }
`

const StyledButton = styled(Button)`
  height: 34px;
  padding: 6px 15px 8px 15px;
  border-radius: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${elementFocus}

  &:active {
    outline: 1px solid ${colors.secondary};
  }

  ${mediaQueries.s} {
    height: 40px;
    padding: 11px 20px 15px 20px;
  }
`

const StyledSpan = styled(motion.span)`
  font-size: 14px;
`

/**
 * Extracts atoms and uses them
 * @param {String} mode - app mode
 * @param {Array} globalProductSet - global product set
 * @returns {Array} - array of products
 */
const extractAtomsAndUse = (mode, globalProductSet) => {
  const atoms = [
    'JacketsAtom',
    'ShirtsAtom',
    'TrousersAtom',
    'ShoesAtom',
    'CoatsAtom',
  ]
    .map((atom) => Modes[mode][atom])
    .map((atom) => {
      const [value] = useAtom(atom)
      return value
    })

  let products = []

  if (mode === SUITS_MODE) {
    const suitJackets = atoms[0]
    const product = globalProductSet.find((e) => suitJackets.parentId === e.id)
    if (product) products.push(product)

    // Add shirt, shoes, and coat to products
    products.push(atoms[1], atoms[3])

    // add coat to products if it is not a fallback
    if (!atoms[4]?.isFallback) products.push(atoms[4])
  } else if (mode === SEPERATES_MODE) {
    // Add shirt, trousers, shoes, and coat to products
    products.push(atoms[1], atoms[2], atoms[3])

    // Add jacket and coat to products if they are not fallbacks
    if (!atoms[0]?.isFallback) products.push(atoms[0])
    if (!atoms[4]?.isFallback) products.push(atoms[4])
  }

  return products
}

const Submit = ({ url, locales, trackingMetadata }) => {
  const [interfaceView] = useAtom(InterfaceViewAtom)
  const [appMode] = useAtom(AppModeAtom, AppScope)
  const { globalProductSet } = useContext(GlobalProductsContext)

  const wishlistUrl = useRef()
  const buttonRef = useRef()

  const shopLabel = translateStringKey(
    'button-shop-look',
    extractLocaleString(locales)
  )

  const suitProducts = extractAtomsAndUse(SUITS_MODE, globalProductSet)
  const seperatesProducts = extractAtomsAndUse(SEPERATES_MODE, globalProductSet)

  const products = appMode === SUITS_MODE ? suitProducts : seperatesProducts

  useEffect(() => {
    const { locale, siteID } = locales
    const SuitsMode = sortWishList(suitProducts)
    const SeperatesMode = sortWishList(seperatesProducts)

    const newParams = new URLSearchParams({
      ModelLayer:
        window.localStorage.getItem(LS_MODEL_SELECTION) || FALLBACK_MODEL,
      SeperatesMode,
      ...(SuitsMode && { SuitsMode }),
      ViewMode: appMode,
      siteID,
      locale,
    })

    wishlistUrl.current = newParams.toString()
  }, [appMode, locales, seperatesProducts, suitProducts])

  const handleClickShop = async () => {
    if (buttonRef.current && typeof buttonRef.current.blur === 'function') {
      buttonRef.current.blur()
    }

    const getLayerID = (layerName) =>
      products
        .map((product) => product.layerName === layerName && product?.id)
        .filter(Boolean)[0]

    const searchParams = new URLSearchParams({
      ModelLayer:
        window.localStorage.getItem(LS_MODEL_SELECTION) || FALLBACK_MODEL,
      TopLayer: getLayerID(TOP_LAYER),
      MidLayer: getLayerID(MID_LAYER),
      BottomLayer: getLayerID(BOTTOM_LAYER),
      ShoeLayer: getLayerID(SHOE_LAYER),
      CoatLayer: getLayerID(COAT_LAYER),
      siteID: locales.siteID,
      locale: locales.locale,
    })

    // ctc endpoint
    const fetchRes = await fetch(`${ENDPOINT}api/composedUrl?${searchParams}`)
      .then((res) => res.text())
      .then((data) => data)

    // cdn enpoint prefech - don't await this
    fetch(`${CDN_URL}/${fetchRes}`)

    gtmPush({
      event: 'GAevent',
      eventCategory: 'Crackthecode_Interactions',
      eventAction: 'Shop_The_Look_Button',
      eventLabel: ``,
    })

    const { url, locationId } = getAvoUrl(window.location.href)

    analytics_shopTheLook({
      ...trackingMetadata,
      url,
      locationId,
      lookbuilderOption: products.map((product) => product.id).join('|'),
    })
  }

  return (
    <Container
      {...fadeInAnimation}
      $isModelLayer={interfaceView === 'ModelSelection'}
    >
      <StyledButton
        forwardedAs="a"
        href={`${url}?${wishlistUrl.current}&entry=looksummary_lookbuilder`}
        onClick={handleClickShop}
        label={shopLabel}
        ref={buttonRef}
        tabIndex={0}
      >
        <StyledSpan key="shop-look">{shopLabel}</StyledSpan>
      </StyledButton>
    </Container>
  )
}

export default Submit
