import { useLayoutEffect, useState } from 'preact/hooks'
import { AUTHORIZATION_PARAM } from 'data'

function useAuthorizationCheck() {
  const [needsAuthorization, setNeedsAuthorization] = useState(false)

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const needsAuthQueryParam = searchParams.get(AUTHORIZATION_PARAM)
    if (needsAuthQueryParam) {
      setNeedsAuthorization(needsAuthQueryParam === 'true')
    }
  }, [])

  return needsAuthorization
}

export default useAuthorizationCheck
