import styled from 'styled-components'
import { colors, globalRadius, mediaQueries } from 'styles'
import Icon from 'components/Icon'
import { motion } from 'framer-motion'

const ButtonStyle = styled(motion.button)`
  text-align: center;
  width: ${(props) => (props.variant === 'fit' ? 'auto' : '100%')};
  border-radius: ${globalRadius};
  height: 42px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  margin: 0;
  padding: ${(props) => props.variant === 'fit' && '0 40px'};
  transition: 400ms border, 400ms background cubic-bezier(0.4, 0.4, 0, 1);

  &[disabled] {
    pointer-events: none;
    background: #fafafa;
    color: ${colors.grey[300]};
    border: 1px solid ${colors.grey[100]};
    cursor: not-allowed;
  }

  ${mediaQueries.m} {
    height: 46px;
  }
  ${(props) => {
    switch (props.background) {
      case 'dark':
        return `
          background: ${colors.grey[900]};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.black};
            color: ${colors.white};
          }
          `
      case 'light':
        return `
          border: 1px solid ${colors.grey[200]};
          color: ${colors.black};
          &:hover {
            border-color: ${colors.grey[800]};
            color: ${colors.black};
          }
          `
      case 'none':
        return `
          background: none;
          color: ${colors.black};
          width: auto;
          height: auto;
        `
    }
  }};
`

const Button = ({
  type = 'button',
  href,
  label,
  icon,
  size,
  background = 'dark',
  width,
  position,
  children,
  variant,
  disabled,
  ...props
}) => {
  return (
    <ButtonStyle
      href={href}
      type={type}
      background={background}
      position={position}
      aria-label={label}
      variant={variant}
      disabled={disabled}
      {...props}
    >
      {children}
      {icon && <Icon icon={icon} />}
    </ButtonStyle>
  )
}

export default Button
