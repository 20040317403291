import { useLayoutEffect, useState } from 'preact/hooks'

import {
  DATA_SOURCE_PARAM,
  ORIGIN_WEBSTORE,
  AVAILABLE_ORIGINS,
  LOCALE_PARAM_STRING,
} from 'data'

const validateLocaleString = (locale) => {
  return /^[a-z]{2}-[A-Z]{2}$/.test(locale)
}

const useDatasource = () => {
  const [origin, setOrigin] = useState()
  const [localeFromParam, setLocaleFromParam] = useState()

  useLayoutEffect(() => {
    const passedOrigin = new URLSearchParams(window.location.search).get(
      DATA_SOURCE_PARAM
    )

    const passedLocale = new URLSearchParams(window.location.search).get(
      LOCALE_PARAM_STRING
    )

    const isValidLocale = validateLocaleString(passedLocale)

    const isExternalCheck =
      AVAILABLE_ORIGINS.includes(passedOrigin) && isValidLocale

    // maintain this check, don't use a fallback state hook. Falls back to webstore if origin is not available/passed
    setOrigin(isExternalCheck ? passedOrigin : ORIGIN_WEBSTORE)

    // falls back to webstore if locale is not available/passed
    isExternalCheck && setLocaleFromParam(passedLocale)
  }, [])

  return { dataSource: origin, localeFromParam }
}

export default useDatasource
