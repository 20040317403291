import { motion } from 'framer-motion'
import styled from 'styled-components'

import {
  colors,
  elementFocus,
  mediaQueries,
  hideScrollbars,
  categoryThumbSize,
} from 'styles'

export const CategoriesWrapper = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;

  padding: 10px 10px;

  overflow-x: scroll;
  width: 100%;
  height: 100%;

  ${mediaQueries.s} {
    display: flex;
    align-items: center;
  }

  ${mediaQueries.m} {
    padding: 20px;
    align-items: center;
    overflow-x: unset;
    flex-direction: column;
    gap: 10px;

    min-height: 100%;
    top: auto;
  }

  ${hideScrollbars}
`

export const CategoryContainer = styled(motion.button)`
  background-color: ${colors.white};
  border: none;
  padding: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  border-radius: 4px;
  outline: 4px solid transparent;
  ${elementFocus};
  &:focus,
  &:focus-within {
    margin-top: unset;
  }

  ${mediaQueries.s} {
    gap: 11px;
  }

  ${mediaQueries.m} {
    cursor: pointer;

    width: 100%;

    flex-direction: row;
    justify-content: space-between;

    transform-origin: 100% 0%;
    overflow: hidden;
  }
`

export const CategoryTitleContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaQueries.m} {
    max-width: unset;
    width: 50%;
    text-align: center;
    padding-left: 7px;
  }
`

export const CategoryTitle = styled.span`
  font-size: 12px;
  color: ${colors.text.primary};
  text-align: center;

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${categoryThumbSize.mobile}px;
  vertical-align: bottom;

  ${mediaQueries.s} {
    max-width: ${categoryThumbSize.tablet}px;
    font-size: 16px;
  }
`
