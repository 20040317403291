//need to import that way because otherwise it won't work in the api folder

import {
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
  MODEL_LAYER,
  SUIT_LAYER,
  SUITS_MODE,
  SEPERATES_MODE,
  VIEW_MODE,
  MODEL_SKIN,
  LAYER_MAPPING,
  HIDDEN_LAYER,
  TEMP_ARRAY_LENGTH,
  OPTIONAL_LAYERS,
} from '../data'

import translations from '../data/translations'

import { ModelAtom, Modes } from '../contexts'

const SORT_OBJECT = [TOP_LAYER, MID_LAYER, BOTTOM_LAYER, SHOE_LAYER, COAT_LAYER]

export const sortLayers = ({ data }) => {
  SORT_OBJECT.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    }
  }, {})
  return data?.sort(
    (a, b) => SORT_OBJECT[a['category']] - SORT_OBJECT[b['category']]
  )
}

export const mergeCategories = (state, currState) =>
  Object.values(
    []
      .concat(state, currState)
      .reduce(
        (r, c) => (
          (r[c.layerName] = Object.assign(r[c.layerName] || {}, c)), r
        ),
        {}
      )
  )

export const getRealCategory = (product) =>
  product.subCategory ? product.subCategory : product.category

export const setCategoryId = (category) => {
  switch (category) {
    case 'ctc_models':
      return undefined
    case 'Shoes':
      return 1
    case 'Trousers':
      return 28
    case 'Shorts':
      return 28
    default:
      return 25
  }
}

export const getCategory = (category) => {
  switch (category) {
    case 'Knitwear':
      return 'Shirts'
    case 'Coats':
      return 'Jackets'
    case 'Shorts':
      return 'Trousers'
    case 'Bow_Ties':
      return 'Ties'
    case 'Knitted_Ties':
      return 'Ties'
    default:
      return category
  }
}

export const translateStringKey = (category, locale) => {
  if (!category || !locale) return category

  return translations[locale][category] || category
}

export const getJourney = (layerName) => {
  switch (layerName) {
    case TOP_LAYER:
      return { category: 'Jackets', layerName: TOP_LAYER, index: 0 }
    case SUIT_LAYER:
      return { category: 'Suits', layerName: TOP_LAYER, index: 0 }
    case MID_LAYER:
      return { category: 'Shirts', layerName: MID_LAYER, index: 1 }
    case BOTTOM_LAYER:
      return { category: 'Trousers', layerName: BOTTOM_LAYER, index: 2 }
    case SHOE_LAYER:
      return { category: 'Shoes', layerName: SHOE_LAYER, index: 3 }
    case COAT_LAYER:
      return { category: 'Coats', layerName: COAT_LAYER, index: 4 }
    case MODEL_LAYER:
      return { category: 'ModelSelection', layerName: MODEL_LAYER, index: 5 }
    default:
      return { category: 'Hidden', layerName: HIDDEN_LAYER, index: 5 }
  }
}

export const getSingleCategory = (layerName) => {
  switch (layerName) {
    case COAT_LAYER:
      return 'Coat'
    case TOP_LAYER:
      return 'Jacket'
    case MID_LAYER:
      return 'Base'
    case BOTTOM_LAYER:
      return 'Trousers'
    case SHOE_LAYER:
      return 'Shoes'
    case MODEL_LAYER:
      return 'ModelSelection'
    case SUIT_LAYER:
      return 'Suit'
  }
}

export const getCategoryPosition = [
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
]

export const getSubCategory = (category) => {
  switch (category) {
    case 'Knitwear':
      return 'Knitwear'
    case 'Shorts':
      return 'Shorts'
    default:
      return undefined
  }
}

export const getCategoryContext = (category, appMode) => {
  const { JacketsAtom, ShirtsAtom, TrousersAtom, ShoesAtom, CoatsAtom } =
    Modes[appMode]
  switch (category) {
    case TOP_LAYER:
      return JacketsAtom
    case MID_LAYER:
      return ShirtsAtom
    case BOTTOM_LAYER:
      return TrousersAtom
    case SHOE_LAYER:
      return ShoesAtom
    case COAT_LAYER:
      return CoatsAtom
    case MODEL_LAYER:
      return ModelAtom
  }
}

export const getOutfitFromQuery = ({ query, dataset, hasInitialProducts }) => {
  let querySet = Array.from({ length: TEMP_ARRAY_LENGTH }, () => [])

  // find the product in the dataset
  Object.values(query).forEach((e) => {
    const match = productData
      ?.flat()
      .find((a) => a?.id === e && e?.c_onlineProductTypeID)
    if (match) querySet[getJourney(match.layerName).index].push(match)
  })

  // if there is no product in the query, add the default product
  querySet.forEach((e, i) => {
    if (!e.length) {
      e.push(
        hasInitialProducts
          ? dataset.initialProducts[i]
          : dataset.defaultProducts[i]
      )
    }
  })
  return querySet.flat()
}

/**
 * Function to get the query parameters from the url, removes duplicates
 * @param {String} query - window.location.search
 * @returns {Object} - Object with the query parameters
 */
export const getQueryParameters = (query) => {
  const sortedParams = []

  // get the query parameters from the url
  const initial = query
    .replace('?', '')
    .split('&')
    .filter((e) => e)
    .map((e) => ({ [`${e.split('=')[0]}`]: e.split('=')[1] }))
    .reduce((acc, cur) => {
      const key = Object.keys(cur)[0]
      if (!acc[key]) {
        acc[key] = cur[key]
      }
      return acc
    }, {})

  // sort initial object by the order of SORT_OBJECT
  Object.keys(initial).map((e, index) => {
    SORT_OBJECT.indexOf(e)
    sortedParams[SORT_OBJECT.indexOf(e)] = {
      [e]: Object.values(initial)[index],
    }
  })

  // ensure that the object is one level deep
  return sortedParams.reduce((r, o) => {
    Object.keys(o).forEach((k) => {
      r[k] = o[k]
    })

    return r
  }, {})
}

/**
 * Function to get the product category from the producData
 * @param {String} productId - id of the product
 * @param {Object} producData - producData of products
 * @returns {Object} - {category: String, id: String}
 */
export const getProductCategory = (productId, producData) => {
  producData = producData.flat()
  const match = producData.find((e) => e?.id === productId)

  return { category: match?.layerName, id: match?.setReference || match?.id }
}

/**
 *  Function adds, removes, replaces products from query
 * @param {Object} previous - previous query parameters (state)
 * @param {Object} params - current query parameters (state)
 * @param {Object} selectedProduct - selected product
 * @param {Object} data - matrix of products
 * @param {Boolean} shouldRemove - should the product be removed from the array
 * @returns {String} - new query parameters
 */
export const formatState = (
  previous,
  params,
  selectedProduct,
  data,
  shouldRemove = false
) => {
  const currentProductIds = params?.split(',')
  const currentSelectedProduct = getProductCategory(selectedProduct?.id, data)

  const currentProductCategories =
    currentProductIds?.map((id) => getProductCategory(id, data)) || []

  if (shouldRemove) {
    const updatedArray = currentProductCategories.filter(
      (e) => e.category !== currentSelectedProduct.category
    )

    const preFormat = updatedArray.map((e) => e.id)
    return preFormat
  }

  let updatedArray = addObjectToArray(
    currentProductCategories,
    currentSelectedProduct
  )

  if (
    params
      ?.split(',')
      .includes(selectedProduct?.setReference || selectedProduct.id)
  )
    return params
  if (!previous) return [selectedProduct?.setReference || selectedProduct.id]
  return updatedArray
}

/**
 * Function adds a new object to an array of objects, if the object already exists, it replaces it
 * @param {Object} array - array of objects
 * @param {Object} newObject - new object to be added to the array
 * @returns {Object} - array of objects with the new object added
 */
const addObjectToArray = (array, newObject) => {
  // Find the index of the object with the same category, if it exists
  const index = array.findIndex((obj) => obj.category === newObject.category)

  if (index !== -1) {
    // If an object with the same category exists, replace it with the new object
    array[index] = newObject
  } else {
    // Otherwise, add the new object to the array
    array.push(newObject)
  }

  return array.map((e) => e.id)
}

/**
 * Function to extract the outfit from the query
 * @param {String} query - window.location.search
 * @param {Object} productData - products from ocapi dataset
 * @returns {Object} - [{category: String, id: String}, ...]
 */
export const extractOutfitFromQuery = (query, productData, dataset) => {
  const primarySet = dataset.data.flat()
  const suitProducts = Array.from({ length: TEMP_ARRAY_LENGTH }, () => [])
  const seperatesProducts = Array.from({ length: TEMP_ARRAY_LENGTH }, () => [])

  const queryString = query.replace(/^\?/, '')
  const parameterPairs = queryString.split('&')

  let viewMode
  let modelLayer

  // loop through the query parameters
  parameterPairs.map((pair) => {
    const [key, value] = pair.split('=')
    const outfitQueries = [SUITS_MODE, SEPERATES_MODE]

    // non-product queries
    if (!outfitQueries.includes(key)) {
      if (key === VIEW_MODE) viewMode = value
      if (key === MODEL_SKIN) modelLayer = value
      return
    }

    // extract the products from the query
    value.split(',').map((e) => {
      // find the product in the productData (global, flat, but formatted OCAPI data)

      const match = productData
        ?.flat()
        .find(
          (a) =>
            (a?.setReference === e || a?.id === e) &&
            (a?.c_onlineProductTypeID || a?.onlineProductType)
        )

      const isSuitProduct = Boolean(match?.setProducts) && !match?.isNestedSuit
      const isNestedSuit = Boolean(match?.setProducts) && match?.isNestedSuit

      // product in query doesn't exist (anymore), this will be populated later
      if (!match) return

      // suitproducts have a setProducts, extract them. Uses the setProduct's layername to determine the position. Unsupported positions are ignored by getJourney
      if (isSuitProduct) {
        match.setProducts.map((product) => {
          const position = getJourney(product.layerName).index

          suitProducts[position].push({
            ...primarySet.find((e) => e.id === product.id),
            parentId: product.parentId,
          })
        })
      } else if (isNestedSuit) {
        match.setProducts.map((product) => {
          const position = getJourney(product.layerName).index

          suitProducts[position].push({
            ...primarySet.find((e) => e.id === product.id),
            parentId: product.parentId,
            isNestedSuit: true,
            layerName: LAYER_MAPPING[position],
          })
        })
      } else {
        const position = getJourney(match.layerName).index
        // induvidual products from each set are pushed to their respective position in their respective array
        if (key === SUITS_MODE) {
          suitProducts[position].push(match)
        } else {
          seperatesProducts[position].push(match)
        }
      }
    })
  })

  // if the position in the array is empty, populate it with the initial product if not with default products
  suitProducts.map((e, index) => {
    if (e.length < 1) {
      const fallback = dataset?.defaultSuitProducts[index]

      const fallbackProduct = {
        ...fallback,
        isFallback: OPTIONAL_LAYERS.includes(fallback.layerName),
      }

      suitProducts[index].push(fallbackProduct)
    }
  })

  seperatesProducts.map((e, index) => {
    if (e.length < 1) {
      seperatesProducts[index].push(
        dataset?.initialProducts[index] || dataset?.defaultProducts[index]
      )
    }
  })

  // ensure to flatten the arrays for consumption within the app
  return {
    suit: suitProducts.map((e) => e[0]),
    seperates: seperatesProducts.map((e) => e[0]),
    ...(modelLayer && { modelLayer }),
    ...(viewMode && { viewMode }),
  }
}

/**
 * Function to convert a string to camel case
 * @param {String} str - string to be converted to camel case
 * @returns {String} - string in camel case
 */
const toCamelCase = (str) => {
  const words = str.split(/[\s_-]/)

  // Convert the first word to lowercase
  let camelCase = words[0].toLowerCase()

  // Convert the rest of the words to title case and concatenate them
  for (let i = 1; i < words.length; i++) {
    const word = words[i]
    const titleCase = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    camelCase += titleCase
  }

  return camelCase
}

/**
 * Function formats the product data
 * @param {Object} product - product from ocapi dataset
 * @param {Object} PRODUCT_TYPE_IDS - product type object from product matrix
 * @returns {Object} product - formatted product
 */
export const formatProductData = ({ product, PRODUCT_TYPE_IDS }) => {
  return {
    id: product.id,
    name: product.name,
    category: product.primary_category_id,
    alt: product.long_description,
    subCategory: getSubCategory(product.primary_category_id),
    layerName: PRODUCT_TYPE_IDS[product.c_onlineProductTypeIDCode],
    onlineProductType: product.c_onlineProductTypeID,
    onlineProductTypeCode: product.c_onlineProductTypeIDCode,
    enabledForDefaultLook: product.c_enabledForDefaultLook,
    material: product?.c_onlineMaterial || product.c_material,
    price: product.price,
    setReference: product?.setReference,
    accessories: product?.accessories,
  }
}

export const formatStoreProductData = ({ product, PRODUCT_TYPE_IDS }) => {
  return {
    id: product.parentId,
    name: product.title,
    category: product.groups,
    alt: product.description,
    subCategory: getSubCategory(product.primary_category_id),
    layerName: PRODUCT_TYPE_IDS[product.originalProductTypeCode],
    onlineProductType: product.originalProductType,
    onlineProductTypeCode: product.originalProductTypeCode,
    enabledForDefaultLook: false,
    material: product.description,
    price: product.price,
    currencySign: product?.currencySign,
  }
}

export const formatScannedProductData = ({ product, PRODUCT_TYPE_IDS }) => {
  return {
    id: product.id,
    name: product?.description,
    category: product.category,
    alt: product?.description,
    subCategory: getSubCategory(product.category),
    layerName: PRODUCT_TYPE_IDS[product.onlineProductTypeCode],
    onlineProductType: product.onlineProductType,
    onlineProductTypeCode: product.onlineProductTypeCode,
    enabledForDefaultLook: false,
    material: product.fabric,
    price: product?.price || 0,
    isFallback: false,
  }
}

/**
 * Function formats and returns the product data
 * @param {Object} currentProduct - (current) product
 * @param {String} appMode - current app mode
 */
export const getProductDescription = (
  currentProduct,
  appMode,
  globalProductSet
) => {
  const product =
    appMode === SUITS_MODE
      ? globalProductSet.find((e) => currentProduct?.parentId === e.id) ||
        currentProduct
      : currentProduct

  /**
   * @since R23.64
   * @description suit products don't use actual pricing in their parent, wrapping `price` attribute. To account for this, all products in the set, setProducts, are added together to get the total price of the suit.
   *
   * @since R23.65
   * @description nested suits are sold as set; the price of the suit is the price of the set.
   */
  let totalPrice = 0
  const isSuitProduct = Boolean(product?.setProducts) && !product?.isNestedSuit
  if (isSuitProduct) {
    product.setProducts.map((e) => {
      totalPrice += e.price
    })
  }

  return {
    name: product?.name,
    material: product?.material,
    price: isSuitProduct ? totalPrice : product?.price,
    productId: product?.id,
    currencySign: product?.currencySign,
  }
}
