import { HorizontalScrollContext } from 'contexts'
import useMatchMedia from 'hooks/useMatchMedia'
import { breakpoints } from 'styles/mediaQueries'

const InterfaceProvider = ({ children }) => {
  //  everything smaller than a tablet has hortizontal scroll
  const isHorizontalScroll = useMatchMedia(
    `(max-width: ${breakpoints.m - 1}px)`
  )
  return (
    <HorizontalScrollContext.Provider value={isHorizontalScroll}>
      {children}
    </HorizontalScrollContext.Provider>
  )
}

export default InterfaceProvider
