import styled from 'styled-components'
import { motion } from 'framer-motion'

import Loader from 'components/Loader'

import {
  mediaQueries,
  colors,
  categoryThumbSize,
  globalRadius,
  interfaceSize,
} from 'styles'

import { DEFAULT_PRODUCTS_IDS } from 'data'

const Position = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;

  ${mediaQueries.m} {
    flex-direction: row;
  }
`

const ModelBackground = styled.div`
  outline: 1px solid black;
  background: ${colors.grey[100]};
  height: calc(100% - 130px);
  position: relative;

  ${mediaQueries.m} {
    height: 100%;
    width: calc(100vw - ${interfaceSize.desktop}px);
  }
`

const InterfaceBackground = styled.div`
  position: relative;
  height: 140px;
  background: ${colors.white};

  ${mediaQueries.s} {
    padding-bottom: 70px;
    height: ${interfaceSize.tablet}px;
    width: 100%;
  }

  ${mediaQueries.m} {
    width: ${interfaceSize.desktop}px;
    height: 100%;
  }
`

const CategoriesWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  margin: 3px 10px;

  ${mediaQueries.s} {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
  }

  ${mediaQueries.m} {
    gap: 10px;
    top: 10px;

    margin: 0;
    padding: 10px 15px;
    overflow: hidden;

    width: 100%;
    height: 100vh;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const ThumbnailWrapper = styled.button`
  margin: 0 4px;
  width: ${categoryThumbSize.mobile}px;
  height: ${categoryThumbSize.mobile}px;
  display: flex;
  align-items: inherit;
  justify-content: center;
  border-radius: ${globalRadius};
  background: ${colors.grey[100]};
  appearance: none;
  border: none;
  padding: 0;
  cursor: pointer;

  ${mediaQueries.s} {
    justify-content: unset;
    width: ${categoryThumbSize.tablet}px;
    height: ${categoryThumbSize.tablet}px;
  }

  ${mediaQueries.m} {
    width: ${categoryThumbSize.desktop}px;
    height: ${categoryThumbSize.desktop}px;

    flex-shrink: 0;
  }
`

const Placeholder = () => {
  return (
    <Position
      initial={false}
      exit={{ opacity: 0 }}
    >
      <ModelBackground>
        <Loader />
      </ModelBackground>
      <InterfaceBackground>
        <CategoriesWrapper>
          {DEFAULT_PRODUCTS_IDS['INT'].map((el) => (
            <ThumbnailWrapper key={el} />
          ))}
        </CategoriesWrapper>
      </InterfaceBackground>
    </Position>
  )
}

export default Placeholder
