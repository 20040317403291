import styled, { keyframes } from 'styled-components'

const cross = keyframes`
  0% {
    transform: translateX(-150%);
  }

  100% {
    transform: translateX(150%);
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
`

const Background = styled.div`
  background: #efefef;
  border-radius: 1px;
  overflow: hidden;
  height: 2px;
  min-width: 40px;
  max-width: 80px;
  width: 100%;
  position: relative;
`

const Animation = styled.div`
  background: black;
  width: 200%;
  height: 100%;
  position: absolute;
  right: 100%;
  animation: ${cross} 1.5s cubic-bezier(0.4, 0.4, 0, 1) infinite;
`

const Loader = () => {
  return (
    <Wrapper>
      <Background>
        <Animation />
      </Background>
    </Wrapper>
  )
}

export default Loader
