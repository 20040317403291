import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import Icon from 'components/Icon'
import Button from 'components/Button'

import { colors, mediaQueries, elementFocus } from 'styles'

const animateIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.1 },
}

const StyledButton = styled(Button)`
  height: 34px;
  width: 46px;
  border-radius: 44px;

  padding: 10px;
  margin: 0 0 0 0;

  ${elementFocus}

  ${mediaQueries.s} {
    margin: unset;
    height: 40px;
    width: 100%;
  }
`

const StyledIcon = styled(Icon)`
  color: ${colors.white};
`

const StyledSpan = styled(motion.span)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Save = ({ handleClickAccept, translations }) => {
  return (
    <StyledButton
      onMouseDown={(e) => e.preventDefault()}
      onClick={(e) => {
        e.preventDefault()
        handleClickAccept(e)
      }}
      label={translations['button-accept']}
    >
      <AnimatePresence
        initial={false}
        exitBeforeEnter
      >
        <StyledSpan
          {...animateIn}
          key="accept-look"
        >
          <StyledIcon icon="accept" />
        </StyledSpan>
      </AnimatePresence>
    </StyledButton>
  )
}

export default Save
