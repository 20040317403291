import { AnalyticsBrowser } from '@segment/analytics-next'

export let inspectorLoaded = false

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.SEGMENT_WRITE_KEY,
})

export const segmentDestination = {
  make: function () {
    inspectorLoaded = true
    document.dispatchEvent(new Event('avo_inspector_initialized'))
  },
  logEvent: function (eventName, eventProperties) {
    analytics.track(eventName, eventProperties)
  },
  setUserProperties: function (userId, userProperties) {
    analytics.identify(userId, userProperties)
  },
  identify: function (userId) {
    analytics.identify(userId)
  },
  unidentify: function () {
    analytics.identify(null)
  },
  logPage: function (pageName, eventProperties) {
    const properties = Object.assign(eventProperties, { pageName: pageName })

    analytics.page(properties)
  },
  revenue: function (amount, eventProperties) {
    const name = 'Purchase Complete'
    const properties = Object.assign(eventProperties, { revenue: amount })

    analytics.track(name, properties)
  },
  // The following methods are used for group analytics and are not required. Learn more about group analytics [here](/data-design/groups)
  setGroupProperties(groupType, groupId, groupProperties) {
    analytics.group(groupId, groupProperties)
  },
  addCurrentUserToGroup: function (groupType, groupId) {
    analytics.group(groupId)
  },
  logEventWithGroups: function (
    eventName,
    eventProperties,
    groupTypesToGroupIds
  ) {
    // Not supported by the Segment SDK
    {
      eventName
      eventProperties
      groupTypesToGroupIds
    }
  },
}
